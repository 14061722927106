import React, { useState } from "react";
import { Col, Row, Button, Spinner } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Margin, usePDF } from "react-to-pdf";
import HistoryCard from "./HistoryCard";
import { Dropdown, Menu } from "antd";
import CustomAccordian from "./CustomAccordian";

function GenratePdf({
  osData,
  osloading,
  networkData,
  basicInfoData,
  antivirusInfoData,
  hardwareInfoData,
  usbInfoData,
  bluetoothInfoData,
  installedProgrameData,
  serviceProgrameData,
  startUpProgrameData,
  recentEventData,
  linuxCisData,
  establishedConnection,
  highCpuusage,
  ramUsage,
  securityControls,
  accountDetail,
  osPatchInformation,
  sharedDir,
  internalHardDrive,
  logError,
}) {
  const [loading, setLoading] = useState(false);

  const pdfName = basicInfoData[0]?.["Serial Number"];

  const { toPDF, targetRef } = usePDF({
    filename: `${pdfName}.pdf`,
    page: {
      margin: Margin.SMALL,
      format: "letter",
      orientation: "landscape",
    },
  });

  const handleGeneratePDF = async () => {
    try {
      setLoading(true);
      await toPDF();
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const toHumanReadable = (text) => {
    // Replace "cis" with "CIS" (case-insensitive), add a space after "CIS", and then convert camelCase to Human Readable Text
    return text
      .replace(/cis/gi, "CIS ") // Replace "cis" with "CIS " (case-insensitive)
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Insert space before capital letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
  };

  return (
    <div>
      <Button
        variant=""
        className="btn-white d-flex align-items-center gap-2 fs-18"
        onClick={() => handleGeneratePDF()}
        disabled={loading}
      >
        {loading ? (
          <Spinner size="sm" />
        ) : (
          <i className="ri-printer-line fs-18 lh-1"></i>
        )}
        PDF
      </Button>

      <div
        ref={targetRef}
        className="mx-2"
        style={{
          position: "absolute",
          left: "-9999px",
          top: "-9999px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <p className="text-primary fw-bold text-center fs-2 py-2">
          {basicInfoData[0]?.Hostname}
        </p>
        <Row>
          <Col xl={6}>
            <div className="d-flex align-item-center justify-content-between gap-2 m-0 p-0">
              <div className="col-6">
                <HistoryCard headerName={"Basic Information"}>
                  {basicInfoData?.length > 0 ? (
                    basicInfoData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6">
                              {key === "IP Address" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap   ms-auto gap-2">
                                  {value?.map((v, ind) => (
                                    <p key={ind} className="m-0 p-0">
                                      {v}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Basic Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard headerName={"Hardware Information"}>
                  {hardwareInfoData?.length > 0 ? (
                    hardwareInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            {key === "RAM" ? (
                              <Dropdown
                                trigger={["click"]}
                                overlay={
                                  <Menu className="p-2 card-one shadow-md">
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Total RAM
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.totalRAM || "NA"}
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between ">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Used RAM
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.usedRAM || "NA"}
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-10 fw-semibold text-end">
                                        Available RAM
                                      </p>
                                      <p className="p-0 m-0  fs-10 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.availableRAM || "NA"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                      <p className="p-0 m-0 fw-medium fs-11 fw-semibold text-end">
                                        Free RAM
                                      </p>
                                      <p className="p-0 m-0  fs-11 fw-semibold text-end">
                                        {hardwareInfoData[1]?.ramData
                                          ?.freeRAM || "NA"}
                                      </p>
                                    </div>
                                  </Menu>
                                }
                              >
                                <p
                                  className="p-0 m-0 fw-medium fs-11 text-primary  w-50 text-end "
                                  style={{ cursor: "pointer" }}
                                >
                                  {value}
                                </p>
                              </Dropdown>
                            ) : (
                              <div className="p-0 m-0 fw-medium fs-11 text-end">
                                {value}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Hardware Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>

            <div className="d-flex align-item-center justify-content-between gap-2 m-0 p-0">
              <div className="col-6">
                <HistoryCard headerName={"Security Controls"}>
                  {securityControls?.length > 0 ? (
                    securityControls?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11 col-4">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6">
                              {key === "IP Address" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap   ms-auto gap-2">
                                  {value?.map((v, ind) => (
                                    <p key={ind} className="m-0 p-0">
                                      {v}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Basic Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard headerName={"Bluetooth Details"}>
                  {bluetoothInfoData[0]?.length > 0 ? (
                    bluetoothInfoData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            <p className="p-0 m-0 fw-medium fs-11 text-end">
                              {value}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Bluetooth Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>

            <div className="d-flex align-item-center justify-content-between gap-2 m-0 p-0 flex-column">
              <HistoryCard
                headerName={"Startup Programs List"}
                infoButton={() => console.log("myTable")}
              >
                <DataTable
                  columns={[{ name: "Program Name", selector: (v, i) => v }]}
                  data={startUpProgrameData}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Startup program info is not available
                    </p>
                  }
                />
              </HistoryCard>

              <HistoryCard
                headerName={"Internal Hard Drive"}
                infoButton={() => console.log("myTable")}
              >
                <DataTable
                  columns={[
                    {
                      name: "File System",
                      selector: (v, i) => v?.fileSystem,
                      width: "350px",
                    },
                    { name: "Size", selector: (v, i) => v?.size },
                    { name: "Used", selector: (v, i) => v?.used },
                    {
                      name: "Used Percent",
                      selector: (v, i) => v?.usedPercent,
                    },
                    { name: "Available", selector: (v, i) => v?.available },
                    { name: "Mounted", selector: (v, i) => v?.mounted },
                  ]}
                  data={internalHardDrive}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      Established Connection is not available
                    </p>
                  }
                />
              </HistoryCard>
              <HistoryCard
                headerName={"Shared Directory Information"}
                infoButton={() => console.log("myTable")}
              >
                <DataTable
                  columns={[
                    {
                      name: "Name",
                      selector: (v, i) => v?.name,
                      width: "300px",
                    },
                    { name: "Path", selector: (v, i) => v?.path },
                  ]}
                  data={sharedDir}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Shared directory data is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </Col>
          <Col xl={6} className="px-1">
            <div className="d-flex align-item-center justify-content-between gap-2 m-0 p-0">
              <div className="col-6 ">
                <HistoryCard headerName={"OS Information"}>
                  {osData?.length > 0 ? (
                    osData?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            <p
                              className={` m-0 fw-medium text-end fs-11 ${
                                value === true ? "text-success" : "text-dark"
                              }`}
                            >
                              {typeof value === "boolean"
                                ? value.toString()
                                : value}
                            </p>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Os Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>

              <div className="col-6">
                <HistoryCard
                  headerName={"Network Information"}
                  paddingBottom={true}
                >
                  <p className="fs-9 p-0 my-2 text-primary fw-semibold">
                    Current Network
                  </p>

                  {networkData?.length > 0 ? (
                    networkData[0]?.map((item, index) => (
                      <div key={index} className="my-2">
                        {Object.entries(item).map(([key, value], index2) => (
                          <div
                            key={index2}
                            className="d-flex align-items-start py-2 justify-content-between  border-1 border-bottom"
                          >
                            <p className="p-0 fw-medium m-0 fs-11">
                              <strong>{key}</strong>
                            </p>
                            <div className="p-0 m-0 fw-medium fs-11 text-end col-6">
                              {key === "DNS Server" ? (
                                <div className="d-flex align-items-center justify-content-end flex-wrap   ms-auto gap-2">
                                  {value?.map((v, ind) => (
                                    <p key={ind} className="m-0 p-0">
                                      {v}
                                    </p>
                                  ))}
                                </div>
                              ) : (
                                value || "NA"
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    ))
                  ) : (
                    <div className="py-2">
                      <p className="p-0 m-0">Network Info is not available</p>
                    </div>
                  )}
                </HistoryCard>
              </div>
            </div>

            <div className="col-12">
              <HistoryCard
                headerName={"USB History"}
                infoButton={() => console.log("usbHistory")}
              >
                <div className="pb-3">
                  <DataTable
                    fixedHeader={true}
                    columns={[
                      {
                        name: "Date/Time",
                        selector: (v, index) => v?.time,
                        width: "160px",
                      },
                      {
                        name: "Device Name",
                        selector: (v, index) => v?.usbName,
                        width: "140px",
                      },
                      {
                        name: "Type",
                        selector: (v) => v?.interfaceType,
                        width: "60px",
                      },
                      {
                        name: "Manufacturer",
                        selector: (v) => v?.usbManufacturer,
                        width: "120px",
                      },
                      {
                        name: "Serial Number",
                        selector: (v) => v?.usbSerialNumber,
                        width: "200px",
                      },
                      {
                        name: "Vendor ID",
                        selector: (v) => v?.usbVendorId,
                      },
                      {
                        name: "Product ID",
                        selector: (v) => v?.usbProductId,
                      },
                    ]}
                    data={usbInfoData[1]?.usbHistory}
                    // Adjust the number of rows per page as needed

                    noDataComponent={
                      <p className="p-0 my-2  me-auto">
                        USB History is not available
                      </p>
                    }
                  />
                </div>
              </HistoryCard>

              <HistoryCard
                headerName={"Established Connection"}
                infoButton={() => console.log("myTable")}
              >
                <DataTable
                  columns={[
                    {
                      name: "Local Address",
                      selector: (v, i) => v?.localAddress,
                    },
                    {
                      name: "Local Port",
                      selector: (v, i) => v?.localPort,
                    },
                    {
                      name: "Foreign Address",
                      selector: (v, i) => v?.foreignAddress,
                    },
                    {
                      name: "Foreign Port",
                      selector: (v, i) => v?.foreignPort,
                    },
                  ]}
                  data={establishedConnection}
                  noDataComponent={
                    <p className="p-0 my-2 me-auto">
                      Established Connection is not available
                    </p>
                  }
                />
              </HistoryCard>
              <HistoryCard
                headerName={"User Accounts"}
                infoButton={() => console.log("myTable")}
              >
                <DataTable
                  columns={[
                    {
                      name: "S. No.",
                      selector: (_, index) => index + 1,
                      width: "70px",
                    },
                    { name: "User Name", selector: (v) => v?.userName },
                    {
                      name: "Type",
                      selector: (v) =>
                        accountDetail[0]?.adminAccountsAreMultiple?.adminAccountsName?.includes(
                          v?.userName
                        )
                          ? "Admin"
                          : "User",
                    },
                    {
                      name: "Password Age",
                      selector: (v) => v?.passwordAge,
                    },
                  ]}
                  data={accountDetail[0]?.usersPasswordAge}
                  noDataComponent={
                    <p className="p-0 my-2  me-auto">
                      User Account Detail is not available
                    </p>
                  }
                />
              </HistoryCard>
            </div>
          </Col>
          <Col xl={12}>
            <HistoryCard
              headerName={"Os Patch Information"}
              infoButton={() => console.log("myTable")}
            >
              <DataTable
                columns={[
                  {
                    name: "S. No.",
                    selector: (_, index) => index + 1,
                    width: "70px",
                  },
                  {
                    name: "Name",
                    selector: (v, i) => v?.name,
                    width: "300px",
                  },
                  { name: "version", selector: (v, i) => v?.version },
                  { name: "Type", selector: (v, i) => "OS" },
                  {
                    name: "Date Applied",
                    selector: (v, i) => v?.date,
                    width: "280px",
                  },
                ]}
                data={osPatchInformation}
                noDataComponent={
                  <p className="p-0 my-2  me-auto">Os Patch is not available</p>
                }
              />
            </HistoryCard>
          </Col>
        </Row>
        <HistoryCard
          headerName={"CIS Recommendation"}
          infoButton={() => console.log("myTable")}
        >
          {!linuxCisData || Object.keys(linuxCisData).length === 0 ? (
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fs-11 fw-bold">No Data</p>
            </div>
          ) : (
            Object.entries(linuxCisData).map(([category, data], index) => (
              <CustomAccordian
                key={index}
                accordianHeader={toHumanReadable(category)}
                autoOpen={true}
              >
                {category === "cisFilesystemIntegrityInfo" ? (
                  Object.entries(data).map(
                    ([subCategory, subData], subIndex) => (
                      <CustomAccordian
                        key={subIndex}
                        accordianHeader={toHumanReadable(subCategory)}
                        autoOpen={true}
                      >
                        {Object.entries(subData).map(
                          ([key, value], innerIndex) => (
                            <div
                              key={innerIndex}
                              className="d-flex align-items-center py-1 justify-content-between"
                            >
                              <p className="p-0 m-0 fs-11 fw-bold">
                                {toHumanReadable(key)}
                              </p>
                              <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                                {value}
                              </p>
                            </div>
                          )
                        )}
                      </CustomAccordian>
                    )
                  )
                ) : category === "cisFilesystemConfigurationInfo" ? (
                  <CustomAccordian
                    key={index}
                    accordianHeader={toHumanReadable(category)}
                    autoOpen={true}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Mount Name</th>
                          <th>Is Configured</th>
                          <th>Is Disabled</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((partition, subIndex) => (
                          <tr key={subIndex}>
                            <td>{partition.mountName}</td>
                            <td>{partition.isConfigured}</td>
                            <td>{partition.isDisabled}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CustomAccordian>
                ) : category === "cisAuditPartitionsInfo" ? (
                  <CustomAccordian
                    key={index}
                    accordianHeader={toHumanReadable(category)}
                    autoOpen={true}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Mount Point</th>
                          <th>Mounted</th>
                          <th>Options</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((partition, subIndex) => (
                          <tr key={subIndex}>
                            <td>{partition.mountPoint}</td>
                            <td>{partition.isMounted ? "Yes" : "No"}</td>
                            <td>
                              <ul>
                                {Object.entries(partition.mountOptions).map(
                                  ([key, value], index2) => (
                                    <li key={index2}>
                                      {key}: {value.toString()}
                                    </li>
                                  )
                                )}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </CustomAccordian>
                ) : category === "cisConfigureFirewallRulesInfo" ||
                  category === "cisConfigureIptablesSoftwaresInfo" ? (
                  Object.entries(data).map(([subCategory, subData], subIndex) =>
                    typeof subData === "object" ? (
                      <CustomAccordian
                        key={subIndex}
                        accordianHeader={toHumanReadable(subCategory)}
                        autoOpen={true}
                      >
                        {Object.keys(subData).length === 0 ? (
                          <div className="d-flex align-items-center py-1 justify-content-between">
                            <p className="p-0 m-0 fs-11 fw-bold">No Data</p>
                          </div>
                        ) : (
                          Object.entries(subData).map(
                            ([key, value], innerIndex) => (
                              <div
                                key={innerIndex}
                                className="d-flex align-items-center py-1 justify-content-between"
                              >
                                <p className="p-0 m-0 fs-11 fw-bold">
                                  {toHumanReadable(key)}
                                </p>
                                <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                                  {typeof value === "object" &&
                                  Object.keys(value).length === 0
                                    ? "No Data" // Display 'No Data' for empty objects
                                    : value}
                                </p>
                              </div>
                            )
                          )
                        )}
                      </CustomAccordian>
                    ) : (
                      <div
                        key={subIndex}
                        className="d-flex align-items-center py-1 justify-content-between"
                      >
                        <p className="p-0 m-0 fs-11 fw-bold">
                          {toHumanReadable(subCategory)}
                        </p>
                        <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                          {subData}
                        </p>
                      </div>
                    )
                  )
                ) : (
                  Object.entries(data).map(([key, value], innerIndex) => (
                    <div
                      key={innerIndex}
                      className="d-flex align-items-center py-1 justify-content-between"
                    >
                      <p className="p-0 m-0 fs-11 fw-bold">
                        {toHumanReadable(key)}
                      </p>
                      <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                        {
                          typeof value === "object"
                            ? Object.values(value).join(", ") // Join all values if value is an object
                            : value // Render value directly if it's a string or number
                        }
                      </p>
                    </div>
                  ))
                )}
              </CustomAccordian>
            ))
          )}
        </HistoryCard>
      </div>
    </div>
  );
}

export default GenratePdf;
