// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useNavigate } from "react-router-dom";
// import DataTable from "react-data-table-component";
// import { DownOutlined } from "@ant-design/icons";
// import { Dropdown, Menu } from "antd";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// function CustomTableLinux({
//   rowData,
//   routeUrl,
//   currentPage,
//   pageSize,
//   handlePageChange,
//   handlePageSizeChange,
//   totalDocuments,
// }) {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const navigate = useNavigate();
//   const [skin, setSkin] = useState(currentSkin);
//   console.log(rowData, "===============");
//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   const handleMenuClick = (e, record) => {
//     console.log("Click on menu item:", e, "for record:", record);
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };
//   const menu = (record) => {
//     return (
//       <Menu onClick={(e) => handleMenuClick(e, record)}>
//         {record?.ipAddress?.map((v, index) => (
//           <Menu.Item key={index}>{v}</Menu.Item>
//         ))}
//       </Menu>
//     );
//   };

//   const unrep = [
//     {
//         pcId: "PGWKH00WBCB1R5-5CG9215Q3Y-0d91f7f4-4dfc-4e6c-b94e-0102f44bce38",
//         softwarename: "VLC",
//         softwareversion: "3.0.12",
//         totalendpoint: 3,
//         endpoints: [
//             { hostname: "Linux PC 1", s_no: "INA43RWTYXGG" },
//             { hostname: "Linux PC 2", s_no: "INA43RWTYXGH" },
//             { hostname: "Linux PC 3", s_no: "INA43RWTYXGGI" },
//         ]
//     },
// ];
//   const columns = React.useMemo(
//     () => [
//       {
//         name: "S. No.",
//         selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
//         width: "70px",
//       },
//       {
//         name: "Software Name ",
//         selector: (row) => row.softwarename,
//         sortable: true,
//       },

//       {
//         name: "Software Version    ",
//         selector: (row) => (
//           <Tippy content={row.softwareversion}>
//             <div>{row.softwareversion}</div>
//           </Tippy>
//         ),
//         sortable: true,
//       },
//       {
//         name: "Total Endpoint",
//         selector: (row) => row.totalendpoint,
//         // cell: (row) => (
//         //   <Dropdown overlay={menu(row)} trigger={["click"]}>
//         //     <Button className="bg-transparent border-0 p-0 m-0">
//         //       {/* {row.ipAddress[0] || "NA"} <DownOutlined /> */}
//         //     </Button>
//         //   </Dropdown>
//         // ),
//         sortable: true,
//       },

//       {
//         name: "Action",
//         cell: (row) => (
//           <Button
//             className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
//             onClick={() =>
//               navigate(routeUrl, {
//                 state: {
//                   basicInfo: row,
//                   pcInfoId: row?.pc_Id,
//                 },
//               })
//             }
//           >
//             <p className="m-0 p-0 fs-12">View Details</p>
//             <i className="fas fa-long-arrow-alt-right ps-4"></i>
//           </Button>
//         ),
//         ignoreRowClick: true,
//         allowoverflow: false,
//       },
//     ],
//     [currentPage, pageSize, navigate, routeUrl]
//   );

//   return (
//     <DataTable
//       columns={columns}
//       data={unrep || []}
//       pagination
//       paginationServer
//       paginationTotalRows={totalDocuments}
//       paginationDefaultPage={currentPage}
//       paginationPerPage={pageSize}
//       onChangePage={(page) => {
//         console.log("Page", page);
//         handlePageChange(page);
//       }}
//       onChangeRowsPerPage={(newPageSize) => {
//         console.log("Page Size", newPageSize);
//         handlePageSizeChange(newPageSize);
//       }}
//       noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
//     />
//   );
// }

// export default CustomTableLinux;
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

function CustomTableLinux({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);
  
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
    console.log("Click on menu item:", e, "for record:", record);
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.endpoints?.map((endpoint, index) => (
          <Menu.Item key={index}>
            <div>
              <strong>Hostname:</strong> {endpoint.hostname} <br />
              <strong>Serial No:</strong> {endpoint.s_no}
            </div>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const unrep = [
    {
        pcId: "PGWKH00WBCB1R5-5CG9215Q3Y-0d91f7f4-4dfc-4e6c-b94e-0102f44bce38",
        softwarename: "VLC",
        softwareversion: "3.0.12",
        totalendpoint: 3,
        endpoints: [
            { hostname: "Linux PC 1", s_no: "INA43RWTYXGG" },
            { hostname: "Linux PC 2", s_no: "INA43RWTYXGH" },
            { hostname: "Linux PC 3", s_no: "INA43RWTYXGGI" },
        ]
    },
];
  const columns = React.useMemo(
    () => [
      {
        name: "S. No.",
        selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        width: "70px",
      },
      {
        name: "Software Name ",
        selector: (row) => row.softwarename,
        sortable: true,
      },
      {
        name: "Software Version",
        selector: (row) => (
          <Tippy content={row.softwareversion}>
            <div>{row.softwareversion}</div>
          </Tippy>
        ),
        sortable: true,
      },
      {
        name: "Total Endpoint",
        selector: (row) => row.totalendpoint,
        cell: (row) => (
          <Dropdown overlay={menu(row)} trigger={["click"]}>
            <Button className="bg-transparent border-0 p-0 m-0">
              {row.totalendpoint} <DownOutlined />
            </Button>
          </Dropdown>
        ),
        sortable: true,
      },
      {
        name: "Action",
        cell: (row) => (
          <Button
            className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.pc_Id,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">View Details</p>
            <i className="fas fa-long-arrow-alt-right ps-4"></i>
          </Button>
        ),
        ignoreRowClick: true,
        allowoverflow: false,
      },
    ],
    [currentPage, pageSize, navigate, routeUrl]
  );

  return (
    <DataTable
      columns={columns}
      data={unrep || []}
      pagination
      paginationServer
      paginationTotalRows={totalDocuments}
      paginationDefaultPage={currentPage}
      paginationPerPage={pageSize}
      onChangePage={(page) => {
        console.log("Page", page);
        handlePageChange(page);
      }}
      onChangeRowsPerPage={(newPageSize) => {
        console.log("Page Size", newPageSize);
        handlePageSizeChange(newPageSize);
      }}
      noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
    />
  );
}

export default CustomTableLinux;
