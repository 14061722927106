import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import macIcon from "../../assets/img/DashIcon/newMacIcon.png";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";

import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";

import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";

import usbIcon from "../../assets/img/Alerts/usbIcon.png";

import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";

import PageLoader from "../../components/common/Loader/PageLoader";
import useFetchMacSummary from "../../hooks/macHooks/useFetchMacOsSummary";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  MALWARE_DETECTION_ENDPOINT,
  // NAC_NAP,
  // OS_PATCHED,
  BIOS_HEALTH,
  IS_BLUETOOTH_ENABLED,
  CD_DRIVE,
  HIGH_CPU_USAGE,
  HIGH_RAM_USAGE,
  MAC_BLACKLIST,
  MAC_FIREWALL_OFF,
  MAC_OS_NOT_PATCH,
  MULITPLE_ADMIN,
  IS_NAC_DISABLED,
  OLDER_PASSWORD,
  OPEN_NETWORK_CONNECTION,
  OUT_OF_DOMAIN,
  RDP_ENABLED,
  IS_SCANNER_ENABLED,
  SHARED_FOLDER,
  USB_VOILATION,
  ALL_UNKNOWN_SERVICES,
  IS_WIFI_ENABLED,
  TOTAL_MAC_LIST,
  SAFE_MAC_CLIENTS,
  UNRESPONSIVE_MAC_CLIENTS,
  UNSAFE_MAC_CLIENTS,
} from "../../api/endPoints/macAlertEndPoint";
import CustomToaster from "../../components/common/CustomToaster";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { showToaster } from "../../feature/slices/toastSlice";

export default function MacosDetail() {
  const dispatch = useDispatch();

  const {
    loading,
    macSummary,
    macAlertSummary,
    handleReq = "",
    totalDashboard
  } = useFetchMacSummary();

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  const seriesEight = [
    {
      type: "column",
      data: [
        [0, 2],
        [1, 3],
        [2, 5],
        [3, 7],
        [4, 12],
        [5, 17],
        [6, 10],
        [7, 14],
        [8, 15],
        [9, 12],
        [10, 8],
      ],
    },
    {
      type: "column",
      data: [
        [0, 12],
        [1, 7],
        [2, 4],
        [3, 5],
        [4, 8],
        [5, 10],
        [6, 4],
        [7, 7],
        [8, 11],
        [9, 9],
        [10, 5],
      ],
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (handleReq === "MAC_USB_TRIGGER") {
    dispatch(
      showToaster({
        title: "USB",
        message: "USB VIOLATION",
        color: "warning",
      })
    );
  }

  if (handleReq === "MAC_INSTALLATION_TRIGGER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "New Device Register",
        color: "success",
      })
    );
  }

  if (handleReq === "NO_DEVICE_REGISTER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "No Device Register",
        color: "danger",
      })
    );
  }

  console.log(handleReq, "REQUESTHANDLEREQ");

  var TotalLinuxEndpoint = (macSummary?.totalClients / totalDashboard) * 100;

  // Total number of values
var totalMaccCount = macSummary?.totalSafeClients + macSummary?.totalUnsafeClients + macSummary?.totalUnresponsivelients;

// Percentage calculations
var mac_Safe_Per = (macSummary?.totalSafeClients / macSummary?.totalClients) * 100;
var mac_UnSafe_Per = (macSummary?.totalUnsafeClients / macSummary?.totalClients) * 100;
var mac_UnResponsive_Per = (macSummary?.totalUnresponsivelients / macSummary?.totalClients) * 100;

TotalLinuxEndpoint = Math.round(TotalLinuxEndpoint);
mac_Safe_Per = Math.round(mac_Safe_Per);
mac_UnSafe_Per = Math.round(mac_UnSafe_Per);
mac_UnResponsive_Per = Math.round(mac_UnResponsive_Per);

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="/macOs-dash">
              <img src={macIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Mac DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>
            

            {/* <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button> */}
          </div>
        </div>
        {!loading ? (
          <>
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Mac Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={macSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={TOTAL_MAC_LIST}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={0}
                  riskAt={TotalLinuxEndpoint}
                  footerTitle={"endpoints are Mac"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Safe Mac Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={macSummary?.totalSafeClients || 0}
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={SAFE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={1}
                  riskAt={mac_Safe_Per ? mac_Safe_Per :'0'}
                  footerTitle={"endpoints are secure"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unsafe Mac Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={macSummary?.totalUnsafeClients || 0}
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={UNSAFE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={2}
                  riskAt={mac_UnSafe_Per ? mac_UnSafe_Per : "0"}
                  footerTitle={"endpoints at risk"}
                  
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Mac Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={macSummary?.totalUnresponsivelients || 0}
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={UNRESPONSIVE_MAC_CLIENTS}
                  routeUrl={"/macOs-serverity"}
                  cardIndex={3}
                  riskAt={mac_UnResponsive_Per ? mac_UnResponsive_Per :'0'}
                  footerTitle={"endpoints Unresponsive"}
                />
              </Col>
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlueIcon}
                          alertTitle={"AV Not Installed"}
                          alertNumber={macAlertSummary?.avNotInstalled}
                          apiUrl={ANTIVIRUS_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avRedIcon}
                          alertTitle={"AV Malfunction"}
                          alertNumber={macAlertSummary?.avMalfunction}
                          apiUrl={ANTI_VIRUS_MALFUNCTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlackIcon}
                          alertTitle={"AV Not Updated"}
                          alertNumber={macAlertSummary?.avNotUpdated}
                          apiUrl={ANTI_VIRUS_NOT_UPDATED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Not Installed"}
                          alertNumber={macAlertSummary?.edrNotInstalled}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Malfunction"}
                          alertNumber={macAlertSummary?.edrMalfunction}
                          apiUrl={EDR_MALFUNCTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"OS Not Patched"}
                          alertNumber={macAlertSummary?.osNotPatched}
                          apiUrl={MAC_OS_NOT_PATCH}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={napIcon}
                          alertTitle={"NAP/NAC Disabled"}
                          alertNumber={macAlertSummary?.napnacDisabled}
                          apiUrl={IS_NAC_DISABLED}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={malwareIcon}
                          alertTitle={"Malware detected"}
                          alertNumber={macAlertSummary?.malawareDetected}
                          apiUrl={MALWARE_DETECTION_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={fireIcon}
                          alertTitle={"Firewall OFF"}
                          alertNumber={macAlertSummary?.firwallOff}
                          apiUrl={MAC_FIREWALL_OFF}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={usbIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={macAlertSummary?.usbViolation}
                          apiUrl={USB_VOILATION}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={closeAlertIcon}
                          alertTitle={"Blacklisted Software"}
                          alertNumber={macAlertSummary?.blacklistedSoftware}
                          apiUrl={MAC_BLACKLIST}
                          routeUrl={"/macOs-serverity"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="3">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[35, 20, 20, 15]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>1.8B</h1>
                        <p>86.24%</p>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Unknown Services"}
                          alertNumber={macAlertSummary?.unknownServices}
                          apiUrl={ALL_UNKNOWN_SERVICES}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Old Admin Passwords"}
                          alertNumber={macAlertSummary?.oldAdminPasswords}
                          apiUrl={OLDER_PASSWORD}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Multiple Admins"}
                          alertNumber={macAlertSummary?.mulitpleAdmins}
                          apiUrl={MULITPLE_ADMIN}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"UAC Disabled"}
                          alertNumber={0}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={macAlertSummary?.sharedFolder}
                          apiUrl={SHARED_FOLDER}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={macAlertSummary?.blueToothCount}
                          apiUrl={IS_BLUETOOTH_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Wi-Fi Enabled"}
                          alertNumber={macAlertSummary?.wifiPcCount}
                          apiUrl={IS_WIFI_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Out of Domain"}
                          alertNumber={macAlertSummary?.outOfDomain}
                          apiUrl={OUT_OF_DOMAIN}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"RDP Enabled"}
                          alertNumber={macAlertSummary?.rdpEnabled}
                          apiUrl={RDP_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"CD/DVD Drive Enabled"}
                          alertNumber={macAlertSummary?.cdDriveEnabled}
                          apiUrl={CD_DRIVE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"BIOS Battery Health"}
                          alertNumber={macAlertSummary?.biosBatteryHealth}
                          apiUrl={BIOS_HEALTH}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Scanner Installed"}
                          alertNumber={macAlertSummary?.scannerInstalled}
                          apiUrl={IS_SCANNER_ENABLED}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"High RAM Usage"}
                          alertNumber={macAlertSummary?.highRamUsage}
                          apiUrl={HIGH_RAM_USAGE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"High CPU Usage"}
                          alertNumber={macAlertSummary?.highCpuUsage}
                          apiUrl={HIGH_CPU_USAGE}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                      {/* <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"OS Formatted"}
                          alertNumber={0}
                          apiUrl={OS_PATCHED}
                          routeUrl={"/macOs-serverity"}
                          controlApi={false}
                        />
                      </Col> */}

                      {/* <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Hardware Changed"}
                          alertNumber={macAlertSummary?.napnacDisabled}
                          apiUrl={NAC_NAP}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col> */}
                      <Col xl="3">
                        <Alerts
                          alertIcon={macIcon}
                          alertTitle={"Open Network Connection"}
                          alertNumber={macAlertSummary?.openNetworkConnection}
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/macOs-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Footer />
            {/* <CustomToaster /> */}
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
