import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import { Link, useLocation } from "react-router-dom";
import CustomTableLinux from "../../components/common/Table/CustomTableLinux";
import axios from "axios";
import PageLoader from "../../components/common/Loader/PageLoader";
import { useSelector } from "react-redux";
import { saveAs } from "file-saver";

function LinuxServerityDataUnrecognised() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [skin, setSkin] = useState(currentSkin);

  const [columnData, setColumnData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterData, setFilterData] = useState([]);

  const { user } = useSelector((state) => state.authSlice);

  const [formData, setFormData] = useState({
    hostname: "",
    serialNumber: "",
    ipAddress: "",
    clientScore: "",
  });

  const handleInputChange = (e, labelKey) => {
    const { value, name } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [labelKey]: value,
    }));
  };

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalDocuments, setTotalDocuments] = useState(0);

  const handlePageChange = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handlePageSizeChange = (pageSize) => {
    setPageSize(pageSize);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId;

    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_LINUX_URL}/${state?.callUrl}/${user?.data?.user?.userId}?page=${pageNumber}&pageSize=${pageSize}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        setTotalDocuments(response?.data?.pagination?.totalDocuments);
        if (isMounted) {
          console.log(response, "IN THE API RESPONSE");

          if (response?.data?.data.length > 0) {
            console.log(response?.data?.data, "LOGRESPONSE");
            const calculateData = response?.data?.data?.map((v) => {
              return {
                hostname: v?.hostname || "NA",
                serialNumber: v?.serialNumber || "NA",
                ipAddress: v?.ipAddress?.split(" ") || [],
                clientScore: v?.auditScore,
                updatedAt:v?.updatedAt,
                action: "yourActionValue",
                pc_Id: v?.pcId || "NA",
              };
            });
            setColumnData(calculateData);
          } else {
            setColumnData([]);
          }
        }
      } catch (error) {
        if (isMounted) {
          if (error.response) {
            if (error.response.status === 401) {
              console.log("Unauthorized error occurred");
            } else if (error.response.status === 504) {
              console.log("Gateway Timeout error occurred");
            }
          } else {
            console.error("An error occurred:", error.message);
          }
          setError(error);
          console.log(error, "IN THE ERROR");
        }
      } finally {
        if (isMounted) {
          setLoading(false);
          timeoutId = setTimeout(fetchData, 50000); // Adjusted interval to 50 seconds
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId);
    };
  }, [user, state, pageNumber, pageSize]);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = () => {
    if (columnData && columnData.length > 0) {
      const csvData = [
        ["Hostname", "Serial Number", "IP Address", "Client Score", "Sync Time"],
        ...columnData.map((item) => [
          item.hostname,
          item.serialNumber,
          item.ipAddress,
          item.clientScore,
          item.updatedAt,
        ]),
      ];

      const csvString = csvData.map((row) => row.join(",")).join("\n");

      const blob = new Blob([csvString], {
        type: "text/csv;charset=utf-8",
      });

      saveAs(blob, `${state?.titleState}_Report.csv`);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="/linux-dash">
              <img src={linuxIcon} alt="Linux-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Linux DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
            variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={exportToCSV}
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button>
          </div>
        </div>

        <div className="card-one my-4 p-4">
          <Row>
            <Col xl={4}>
              <p className="fs-20 text-dark fw-bolder p-0 m-0">
                {state?.titleState || "Unrecognised Software"}
              </p>
              <p className="text-danger p-0 m-0 fw-semibold">
                {totalDocuments || 0} Clients
              </p>
            </Col>

            <Col xl={12} className="mt-2">
              {!loading ? (
                <CustomTableLinux
                  rowData={columnData}
                  columnData={columnData}
                  routeUrl={"/linux-client-Details"}
                  currentPage={pageNumber}
                  pageSize={pageSize}
                  totalDocuments={totalDocuments}
                  handlePageChange={handlePageChange}
                  handlePageSizeChange={handlePageSizeChange}
                />
              ) : (
                <PageLoader />
              )}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default LinuxServerityDataUnrecognised;
