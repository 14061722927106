export const LINUX_OS_NOT_PATCH = "backendinfo/os-not-patched";

export const LINUX_NIC = "hardwareInfo/is-nic-disabled";

export const LINUX_NOT_ACTIVATED = "pcinfo/all-not-activated-linux";

export const LINUX_FIREWALL_OFF = "networkinfo/firewall-off";

export const LINUX_BLACKLIST = "backendinfo/all-blacklisted-programs";

export const TOTAL_LINUX_CLIENT = "pcinfo/linux-pc-list";

export const TOTAL_SAFE_LINUX = "backendinfo/safe-linux-clients";

export const TOTAL_UNSAFE_LINUX = "backendinfo/unsafe-linux-clients";

export const TOTAL_UNRESPONSIVE_LINUX = "pcinfo/all-unresponsive-linux";

export const TOTAL_USB_VOILATION =
  "usbhistoryinfo/get-pc-with-unwhitelisted-usb";

export const MULTIPLE_PASSWORD = "accountinfo/is-multiple-admin";

export const OLD_PASSWORD = "accountinfo/is-admin-pwd-greater-than-threshold";

export const RDP_ENABLED = "osinfo/is-rdp-enabled";

export const SHARED_FOLDER = "osinfo/is-shared-directories";

export const UNKNOWN_SERVICE = "backendinfo/all-unknown-services";

export const WIFI_ENABLED = "networkinfo/is-wifi-enabled";

export const BLUETOOTH_ENABLED = "networkinfo/is-bluetooth-enabled";

export const OPEN_NETWORK_CONNECTION = "networkinfo/is-open-network-connection";

export const NAC_DISABLED = "networkinfo/is-nac-disabled";

export const OUT_OF_DOMAIN = "pcinfo/get-out-of-domain-linux";

export const CD_DRIVE_ENABLED = "hardwareInfo/is-cd-drive-enabled";

export const SCANNER_INSTALLED = "hardwareInfo/is-scanner-Installed";

export const IS_HIGH_CPU_USAGE = "hardwareInfo/is-high-cpu-usage";

export const IS_HIGH_RAM_USAGE = "hardwareInfo/is-high-ram-usage";

export const BIOS_BATTERY = "hardwareInfo/bios-battery-serviceable";
