import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";

const useFetchLinuxSummary = () => {
  const [loading, setLoading] = useState(true);
  const [linuxSummary, setLinuxSummary] = useState({
    totalClients: 0,
    totalSafeClients: 0,
    totalUnsafeClients: 0,
    totalUnresponsivelients: 0,
  });
  const [linuxAlertSummary, setLinuxAlertSummary] = useState({
    avNotInstalled: 0,
    avMalfunction: 0,
    avNotUpdated: 0,
    edrNotInstalled: 0,
    edrMalfunction: 0,
    osNotPatched: 0,
    napnacDisabled: 0,
    windowsNotActivated: 0,
    malawareDetected: 0,
    firwallOff: 0,
    usbViolation: 0,
    blacklistedSoftware: 0,
    unknownServices: 0,
    oldAdminPasswords: 0,
    cdDriveEnabled: 0,
    mulitpleAdmins: 0,
    sharedFolder: 0,
    outOfDomain: 0,
    rdpEnabled: 0,
    biosBatteryHealth: 0,
    scannerInstalled: 0,
    highRamUsage: 0,
    highCpuUsage: 0,
    wifiPcCount: 0,
    blueToothCount: 0,
    openNetworkConnection: 0,
  });

  const { user } = useSelector((state) => state.authSlice);
  const [totalDashboard, setTotalDashboard] = useState(0)

  useEffect(() => {
    let isMounted = true; // Flag to track component mount status
    let timerId;

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        );

        if (!isMounted) return; // Prevent state updates if unmounted

        const jsonData = response.data;
        setTotalDashboard(jsonData?.totalDevicesCount);
        const {
          SafelinuxClients,
          UnsafelinuxClients,
          getTotalPCCount,
          getInactiveLinuxCount,
          getUnresponsiveLinuxCount,
          firewallOffPCCount,
          getAntivirusNotInstalledCount,
          getAntivirusMalfunctionCount,
          AntivirusNotUpdatedCount,
          getEDRNotInstalledCount,
          getEDRMalfunctionCount,
          getNotPatchedOSCount,
          getNacDisabledPCCount,
          MalwareDetectionCount,
          getPCsCountWithUnwhitelistedUSB,
          blacklistedProgramsCount,
          getunknownServicesPCCount,
          adminPasswordAgeGreaterThanThresholdCount,
          multipleAdminCount,
          getSharedDirectoriesDetectedCount,
          wifiConnectedPCCount,
          getBluetoothEnabledCount,
          getOutOfDomainLinuxCount,
          getRdpEnableCount,
          getCdDriveEnabledCount,
          getBiosBatteryServiceablePcCount,
          getScannerInstalledCount,
          getHighRamUsagePcCount,
          getOpenNetworkConnectionPCCount,
          getHighCpuUsagePcCount,
        } = jsonData.linux;

        setLinuxSummary({
          totalClients: getTotalPCCount,
          totalSafeClients: SafelinuxClients,
          totalUnsafeClients: UnsafelinuxClients,
          totalUnresponsivelients: getUnresponsiveLinuxCount,
        });

        setLinuxAlertSummary({
          avNotInstalled: getAntivirusNotInstalledCount,
          avMalfunction: getAntivirusMalfunctionCount,
          avNotUpdated: AntivirusNotUpdatedCount,
          edrNotInstalled: getEDRNotInstalledCount,
          edrMalfunction: getEDRMalfunctionCount,
          osNotPatched: getNotPatchedOSCount,
          napnacDisabled: getNacDisabledPCCount,
          windowsNotActivated: getInactiveLinuxCount,
          malawareDetected: MalwareDetectionCount,
          firwallOff: firewallOffPCCount,
          usbViolation: getPCsCountWithUnwhitelistedUSB,
          blacklistedSoftware: blacklistedProgramsCount,
          unknownServices: getunknownServicesPCCount,
          oldAdminPasswords: adminPasswordAgeGreaterThanThresholdCount,
          mulitpleAdmins: multipleAdminCount,
          sharedFolder: getSharedDirectoriesDetectedCount,
          outOfDomain: getOutOfDomainLinuxCount,
          rdpEnabled: getRdpEnableCount,
          cdDriveEnabled: getCdDriveEnabledCount,
          biosBatteryHealth: getBiosBatteryServiceablePcCount,
          scannerInstalled: getScannerInstalledCount,
          highRamUsage: getHighRamUsagePcCount,
          highCpuUsage: getHighCpuUsagePcCount,
          wifiPcCount: wifiConnectedPCCount,
          blueToothCount: getBluetoothEnabledCount,
          openNetworkConnection: getOpenNetworkConnectionPCCount,
        });

        setLoading(false);

        // Schedule the next fetch only if the component is still mounted
        if (isMounted) {
          timerId = setTimeout(fetchData, 50000); // Polling interval set to 50 seconds
        }
      } catch (error) {
        if (isMounted) {
          console.error("Error fetching:", error);
          setLoading(false);

          // Retry fetch after a delay in case of error
          timerId = setTimeout(fetchData, 1000); // Retry interval set to 1 second
        }
      }
    };

    fetchData(); // Initial fetch

    return () => {
      isMounted = false; // Cleanup on unmount to prevent setting state on unmounted component
      clearTimeout(timerId); // Cleanup timeout on unmount
    };
  }, [user?.data?.user?.userId, user?.data?.accessToken]);

  return { loading, linuxSummary, linuxAlertSummary,totalDashboard };
};

export default useFetchLinuxSummary;
