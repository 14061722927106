import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation, useNavigate,NavLink } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LicenseView from "./LicenseView";
import "bootstrap/dist/js/bootstrap.bundle.min"; // Ensure Bootstrap JS is included

function License() {
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [deviceCount, setDeviceCount] = useState("");
  const [orgLicence, setOrgLicence] = useState(null);
  const [lincense, setLincenses] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state,location } = useLocation();
  const [skin, setSkin] = useState(currentSkin);
  const [id, setId] = useState("");

  console.log(lincense, "lincenselist");

  const handleDateChangeStart = (e) => {
    const date = e.target.value;
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    setStartDate(formattedDate);
  };

  const handleDateChangeEnd = (e) => {
    const date = e.target.value;
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    setEndDate(formattedDate);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
   
    const id = url.pathname.split("/")[2]; // Adjust index based on the actual path
    setId(id);
  }, []);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const exportToCSV = (rows, filename) => {
    console.log(rows, "dataaaaaaaaaaaaa");
    const csvContent = [
      ["Date", "Quantity", "Validity"], // Header row
      ...rows.map((row) => [
        row.createdAt,
        row.count,
        row.validFrom,
        row.validTo,
      ]), // Row data
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_NOTIFY_URL}/${id}/license-quantities`)
      .then((response) => response.json())
      .then((data) => {
        setOrgLicence(data?.licenseCounts);
        setLincenses([data?.data]);
      })
      .catch((error) => {
        console.error("Error fetching organization details:", error);
      });
  }, [id]);

  // const handleFormSubmit = async (e) => {
  //   e.preventDefault();
  //   const requestData = {
  //     organizationId: id,
  //     deviceCount: parseInt(deviceCount),
  //     validFrom: startDate.toLocaleDateString("en-GB"),
  //     validTo: endDate.toLocaleDateString("en-GB"),
  //   };

  //   try {
  //     const response = await fetch(`${process.env.REACT_APP_NOTIFY_URL}/add-device-count/generate-lic-key`, {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(requestData),
  //     });

  //     if (response.ok) {
  //       const data = await response.json();
  //       console.log("License generated successfully:", data);
  //       setSuccessMessage("License generated successfully!");
  //       setTimeout(() => {
  //         setSuccessMessage("");
  //         const modal = window.bootstrap.Modal.getInstance(document.getElementById("exampleModal"));
  //         modal.hide();
  //       }, 2000);
  //     } else {
  //       console.error("Error generating license:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error generating license:", error);
  //   }
  // };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // Validation logic
    if (!deviceCount || !startDate || !endDate) {
      console.error("Please fill in all fields.");
      return;
    }

    const requestData = {
      organizationId: id,
      deviceCount: parseInt(deviceCount),
      validFrom: startDate,
      validTo: endDate,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_NOTIFY_URL}/add-device-count/generate-lic-key`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("License generated successfully:", data);
        setSuccessMessage("License generated successfully!");
        setTimeout(() => {
          setSuccessMessage("");
          document.getElementById("closeModalButton").click();
        window.location.reload();
  

          // Refresh data or update state to reflect new license
          // Example: fetch updated data or update state
        }, 2000);
      } else {
        console.error("Error generating license:", response.statusText);
      }
    } catch (error) {
      console.error("Error generating license:", error);
    }
  };

  const columns = [
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: false,
    },
    {
      name: "Quantity",
      selector: (row) => row.count,
      sortable: true,
    },
    {
      name: "Valid From",
      selector: (row) => row.validFrom,
      sortable: true,
    },
    {
      name: "Valid Upto",
      selector: (row) => row.validTo,
      //  (
      //   <>
      //     <span className="badge bg-success">{row.validFrom}</span>
      //     <span className="badge bg-danger">{row.validTo}</span>
      //   </>
      // ),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div style={{ display: "flex", gap: "10px", marginLeft: "-10px" }}>
          <button
            type="button"
            className="btn btn-dark rounded-pill"
            style={{ width: "100px", height: "34px" }}
            data-bs-toggle="modal"
            data-bs-target="#licenseView"
            // onClick={() =>
            //  navigate(`/org-detail/${id}/licenses/${row.createdAt}/${row.validFrom}to${row.validTo}`)
            //  }

            onClick={() => {
              const formatDate = (date) => date.replace(/\//g, "-");
              navigate(
                `/org-detail/${id}/licenses/${formatDate(
                  row.createdAt
                )}/${formatDate(row.validFrom)}to${formatDate(row.validTo)}`
              );
            }}
          >
            Details <i className="fa-solid fa-arrow-right"></i>
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      style: {
        backgroundColor: "#f0f0f0",
        padding: "10px",
        border: "none",
      },
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc", // Adjust this value as needed
      },
    },
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
       {/* <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Admin
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/organizations" activeClassName="active">
              Organizations
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
              {id}
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to={`/org-detail/${id}/licenses`} activeClassName="active">
            licenses
            </NavLink>
          </li>
        </ol> */}
        <ol className="breadcrumb fs-sm mb-1">
      <li className="breadcrumb-item">
        <NavLink exact to="/" activeClassName="active">
          Admin
        </NavLink>
      </li>
      <li className="breadcrumb-item">
        <NavLink exact to="/organizations" activeClassName="active">
          Organizations
        </NavLink>
      </li>
      <li className="breadcrumb-item">
        <NavLink
          to={`/org-detail/${id}`}
          isActive={() => location.pathname === `/org-detail/${id}`}
          activeClassName=""
        >
          {id}
        </NavLink>
      </li>
      <li className="breadcrumb-item">
        <NavLink
          to={`/org-detail/${id}/licenses`}
          isActive={() => location.pathname === `/org-detail/${id}/licenses`}
          activeClassName="active"
        >
          Licenses
        </NavLink>
      </li>
    </ol>
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">License Details</p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-primary text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{
                backgroundColor: "#03A9FC",
                borderRadius: "10px",
                border: "none",
              }}
            >
              <i className="fa-solid fa-plus me-2" style={{ color: "white" }} />
              Generate License
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(lincense[0], "license-data.csv")}
            >
              Export All
            </button>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content p-1">
              <div className="modal-header">
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
                <h1
                  className="modal-title fs-5 mx-auto SubTitleCustomStyle"
                  id="exampleModalLabel"
                >
                  Generate License
                </h1>
              </div>
              <div className="modal-body">
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label
                      htmlFor="deviceCount"
                      className="form-label"
                      style={{ color: "black" }}
                    >
                      Quantity
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Enter number of licenses"
                      id="deviceCount"
                      value={deviceCount}
                      onChange={(e) => setDeviceCount(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="validity"
                      className="form-label"
                      style={{ color: "black" }}
                    >
                      Choose validity
                    </label>
                    <div className="row">
                      <div className="col-sm">
                        <div style={{ display: "flex", gap: "10px" }}>
                          {/* <DatePicker
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            placeholderText="From"
                            className="form-control"
                          /> */}

                          <input
                            type="date"
                            className="form-control"
                            value={startDate?.split("-").reverse().join("-")} // Reformat back to yyyy-mm-dd for the input field
                            onChange={handleDateChangeStart}
                            required
                          />

                          <input
                            type="date"
                            className="form-control"
                            value={endDate?.split("-").reverse().join("-")} // Reformat back to yyyy-mm-dd for the input field
                            onChange={handleDateChangeEnd}
                            required
                          />
            
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <button
                      type="submit"
                      className="form-control"
                      style={{ backgroundColor: "#03A9FC" }}
                    >
                      Generate
                    </button>
                  </div>
                  <div className="mb-3">
                    <button
                      type="button"
                      className="form-control"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      id="closeModalButton"
                      style={{ border: "none" }}
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center mb-3">
          <div className="row">
            <div className="col">
              <div className="card m-2 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bolder">Total Licenses</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    {orgLicence?.total}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card m-2 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bolder">Used Licenses</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    {orgLicence?.used}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card m-2 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bolder">Unused Licenses</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    {orgLicence?.unused}
                  </h6>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="card m-2 shadow">
                <div className="card-body">
                  <h5 className="card-title fw-bolder">Expired Licenses</h5>
                  <h6 className="card-subtitle mb-2 text-body-secondary">
                    {orgLicence?.expired}
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card rounded cardStyle">
          <DataTable
            columns={columns}
            data={lincense[0]}
            pagination
            customStyles={customStyles}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default License;
