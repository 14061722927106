import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../assets/img/Excellogo.png";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

function AllEndPoint() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasT, setShowOffcanvasT] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });
  
  const { user } = useSelector((state) => state.authSlice);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.devices;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const columns = [
    visibleColumns.hostname && {
      name: "Hostname",
      selector: (row) => (
        <Tippy content={row.hostname}>
          <div>{row.hostname}</div>
        </Tippy>
      ),
      sortable: true,
    },
    visibleColumns.serialNumber &&  {
      name: "Serial Number",
      selector: (row) => row.serialNumber,
      sortable: true,
    },
    visibleColumns.ipAddress &&  {
      name: "IP Address",
      selector: (row) => (
        <Tippy content={row.ipAddress || "NA"}>
          <div>{row.ipAddress || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
    },
    visibleColumns.auditScore && {
      name: "Client Score",
      selector: (row) => row.auditScore || "NA",
      sortable: true,
    },
    visibleColumns.updatedAt && {
      name: "Sync Time",
      selector: (row) => formatTimestamp(row.updatedAt) || "NA",
      sortable: true,
    },
    visibleColumns.departmentName && {
      name: "Associated With",
      selector: (row) =>
      (
        <Tippy content={ row.department || "NA"}>
          <div>{row.department || "NA"}</div>
        </Tippy>
      ),
      sortable: true,
      width:'280px'
    },
    {
      name: "Details",
      cell: (row) => (
      
        <Button onClick={() => handleActionClick(row)} className="bg-dark btn  fs-22 text-white">
          Action
        </Button>
      ),
      sortable: false,
    },
  ];
  const exportToPDF = (data) => {
    const doc = new jsPDF();
    doc.text("Exported Data", 14, 16);

    const tableColumn = [
      "Hostname",
      "Serial Number",
      "IP Address",
      "Client Score",
      "Sync Time",
      "Associated With",
    ];
    const tableRows = data.map((item) => [
      item.hostname,
      item.serialNumber,
      item.ipAddress || "NA",
      item.auditScore || "NA",
      formatTimestamp(item.updatedAt) || "NA",
      item.department || "NA",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("data.pdf");
  };

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        Hostname: item.hostname,
        SerialNumber: item.serialNumber,
        IPAddress: item.ipAddress || "NA",
        ClientScore: item.auditScore || "NA",
        SyncTime: formatTimestamp(item.updatedAt) || "NA",
        AssociatedWith: item.department || "NA",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleActionClick = (row) => {
    console.log("Action clicked for row:", row);
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };
  const handleTableClick = () => {
    setShowOffcanvasT(true);
  };
  const handleCheckboxChange = (columnName) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };
  
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const deviceIds = selectedRows.map((row) => row.pcId);

    // Construct the data object based on which fields are selected
    const data = { deviceIds };

    if (selectedDepartment) {
      data.departmentId = selectedDepartment.value;
    }
    if (selectedSection) {
      data.sectionId = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSectionId = selectedSubsection.value;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Close Offcanvas and update DataTable data
      setShowOffcanvas(false);
      fetchData();
      setSelectedRows([]);
      setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error(error);

      console.error("Error submitting form:", error);
      // Handle error response
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-danger text-white"
              onClick={() => exportToPDF(columnData)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToExcel(columnData)}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; Export
            </button>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-info text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              Manage label
            </button>
            <button
              type="button"
              className="btn btn-info text-white"
              onClick={handleControlClick}
              disabled={selectedRows.length === 0}
            >
              Control
            </button>
          </div>

          <button
            type="button"
            className="btn btn-info text-white"
            onClick={handleTableClick}
          >
            <i className="fa-solid fa-eye"></i> Table View
          </button>
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={toggleClearRows} // Add this line
              selectableRowsHighlight
              highlightOnHover
              paginationPerPage={10} // Number of rows per page
              paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
            />
          )}
        </div>

        {/* Offcanvas for displaying selected rows */}
        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Selected Devices:{" "}
              <span className="badge rounded-pill text-bg-secondary text-white">
                {selectedRows.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row) => (
                <>
                  <li key={row.pcId}>{row.hostname}</li>
                  {/* <li key={row.pcId}>{row.pcId}</li> */}
                </>
              ))}
            </ul>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="departments" className="form-label">
                  Departments
                </label>
                <Select
                  options={departments.map((dept) => ({
                    label: dept.departmentName,
                    value: dept._id,
                  }))}
                  onChange={handleDepartmentChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sections" className="form-label">
                  Sections
                </label>
                <Select
                  options={sections.map((section) => ({
                    label: section.sectionName,
                    value: section._id,
                  }))}
                  onChange={handleSectionChange}
                  isDisabled={!selectedDepartment}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subsections" className="form-label">
                  Subsections
                </label>
                <Select
                  options={subsections.map((subSection) => ({
                    label: subSection.subSectionName,
                    value: subSection._id,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedSubsection(selectedOption)
                  }
                  isDisabled={!selectedSection}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        {/* Offcanvas for displaying table view rows */}
        {/* <Offcanvas
          show={showOffcanvasT}
          onHide={() => setShowOffcanvasT(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Table Columns</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Offcanvas.Title className="fs-6">Visible Columns</Offcanvas.Title>
            <ul class="list-group">
              <li class="list-group-item">
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value="checked"
                  id="firstCheckboxStretched"
                  checked
                />
                <label
                  class="form-check-label stretched-link"
                  for="firstCheckboxStretched"
                >
                  First checkbox
                </label>
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value="checked"
                  id="secondCheckboxStretched"
                  checked
                />
                <label
                  class="form-check-label stretched-link"
                  for="secondCheckboxStretched"
                >
                  Second checkbox
                </label>
              </li>
              <li class="list-group-item">
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value="checked"
                  id="thirdCheckboxStretched"
                  checked
                />
                <label
                  class="form-check-label stretched-link"
                  for="thirdCheckboxStretched"
                >
                  Third checkbox
                </label>
              </li>
            </ul>

            <hr />
            <Offcanvas.Title className="fs-6">Available Columns</Offcanvas.Title>
            <ul class="list-group">
              <li class="list-group-item">
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value="checked"
                  id="firstCheckboxStretched"
                  
                />
                <label
                  class="form-check-label stretched-link"
                  for="firstCheckboxStretched"
                >
                  First checkbox
                </label>
              </li>
             
        
            </ul>
          </Offcanvas.Body>
        </Offcanvas> */}
        <Offcanvas
  show={showOffcanvasT}
  onHide={() => setShowOffcanvasT(false)}
  placement="end"
>
  <Offcanvas.Header closeButton>
    <Offcanvas.Title>Table Columns</Offcanvas.Title>
  </Offcanvas.Header>
  <Offcanvas.Body>
    <Offcanvas.Title className="fs-6">Visible Columns</Offcanvas.Title>
    <ul className="list-group">
      {Object.keys(visibleColumns).map((column) => (
        <li className="list-group-item" key={column}>
          <input
            className="form-check-input me-1"
            type="checkbox"
            id={`checkbox-${column}`}
            checked={visibleColumns[column]}
            onChange={() => handleCheckboxChange(column)}
          />
          <label
            className="form-check-label stretched-link"
            htmlFor={`checkbox-${column}`}
          >
            {column.replace(/([A-Z])/g, ' $1').trim()} {/* Formatting column names */}
          </label>
        </li>
      ))}
    </ul>
  </Offcanvas.Body>
</Offcanvas>

      </div>
    </React.Fragment>
  );
}

export default AllEndPoint;
