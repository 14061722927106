// import React, { useEffect, useState } from "react";
// import HistoryCard from "../../components/common/HistoryCard";
// import Header from "../../layouts/Header";
// import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
// import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
// import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
// import { Button, Col, Row } from "react-bootstrap";
// import meterIcon from "../../assets/img/meter.png";
// import ReactPaginate from "react-paginate";

// import {
//   BasicClientData,
//   hardwareInformation,
// } from "../../components/common/detailData/deviceData";
// import CustomButton from "../../components/common/CustomButton";
// import CustomModal from "../../components/CustomModal";
// import { Link, useLocation } from "react-router-dom";
// import CustomAccordian from "../../components/common/CustomAccordian";
// import useFetchOsInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchOsInfo";
// import useFetchNetworkInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchNetworkInfo";
// import PageLoader from "../../components/common/Loader/PageLoader";
// import useFetchOsLinux from "../../hooks/linuxhooks/useFetchOsLinux";
// import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
// import useFetchOsAndroid from "../../hooks/AndroidHooks/useFetchOsAndroid";

// function ChromeClientsDetail() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [handleUsbModal, setHandleUsbModal] = useState(false);
//   const [handleNICModal, setHandleNICModal] = useState(false);
//   const [handlePortModal, setHandlePortModal] = useState(false);
//   const [handleAntiVirusModal, setHandleAntiVirusModal] = useState(false);
//   const [handleUsbHistoryModal, setHandleUsbHistoryModal] = useState(false);
//   const [handleUsbPortModal, setHandleUsbPortModal] = useState(false);

//   const [appPermissions, setAppPermissions] = useState([]);
//   const [showPermission, setShowPermission] = useState(false);

//   const [handleBlueToothInfoModal, setHandleBlueToothInfoModal] =
//     useState(false);

//   const location = useLocation();
//   const { pcInfoId, basicInfo } = location?.state;

//   console.log(location?.state, "LOCATIONasdasdasdsadasdsadDATALINUX");

//   const {
//     osData,
//     osloading,
//     networkData,
//     basicInfoData,
//     antivirusInfoData,
//     hardwareInfoData,
//     osPatchUpdateInfoData,
//     usbInfoData,
//     bluetoothInfoData,
//     installedProgrameData,
//     serviceProgrameData,
//     startUpProgrameData,
//     recentEventData,
//     cisData,
//     threatsData,
//     othersData,
//   } = useFetchOsAndroid(pcInfoId);
//   // const { networkData, networkloading } = useFetchNetworkInfo(
//   //   pcInfoId,
//   //   basicInfo?.hostname
//   // );

//   // console.log(basicInfo?.pc_Id, "BASIC INFO LOCATION");

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   console.log(basicInfoData, "serviceProgrameData");

//   const handleAppPermission = (permissionData) => {
//     if (permissionData === "[]") {
//       setAppPermissions([]);
//     } else {
//       const convertedArray = permissionData.slice(1, -1).split(",");
//       setAppPermissions(convertedArray);
//     }

//     setShowPermission(true);
//   };

//   const toHumanReadable = (text) => {
//     // Convert camelCase to Human Readable Text
//     return text
//       .replace(/([A-Z])/g, " $1")
//       .replace(/^./, (str) => str.toUpperCase());
//   };



//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 5;

//   const handlePageClick = (data) => {
//     setCurrentPage(data.selected);
//   };

//   const offset = currentPage * itemsPerPage;
//   const currentPageData = installedProgrameData.slice(
//     offset,
//     offset + itemsPerPage
//   );
//   const pageCount = Math.ceil(installedProgrameData.length / itemsPerPage);
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <div className="d-flex align-items-center justify-content-center gap-4">
//             <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//               <Link to="#">
//                 <img
//                   src={chromeIcon}
//                   alt="Window-logo"
//                   width={55}
//                   height={55}
//                 />
//               </Link>
//               <p className="mx-2 fs-18 m-0 p-0">|</p>
//               <p className="fs-18 fw-bolder p-0 m-0 ">Chrome Client Details</p>
//             </span>
//             <span>
//               <i className="fas fa-arrow-right"></i>
//             </span>
//             <p className="p-0 m-0 fs-16 fw-semibold text-warning">
//               {basicInfo?.pc_Id || "Client Detail"}
//             </p>
//           </div>
//           <div className="d-flex align-items-center justify-content-center gap-2">
//             <span>
//               <img src={meterIcon} alt="Meter" />
//             </span>
//             <span>
//               <p className="fs-9 fw-bold p-0 m-0 text-secondary">
//                 Client Score
//               </p>
//               <p className="fs-24 text-primary fw-bold">86%</p>
//             </span>
//           </div>
//         </div>
//         {osloading ? (
//           <PageLoader />
//         ) : (
//           <Row className="">
//             <Col xl={6} className="h-25 p-1 ">
//               <HistoryCard headerName={"Basic Information"}>
//                 {basicInfoData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value || "NA"}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>

//               <HistoryCard headerName={"OS Information"}>
//                 {osData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p
//                           className={` m-0 fw-medium text-end fs-11 ${
//                             value === true ? "text-success" : "text-dark"
//                           }`}
//                         >
//                           {typeof value === "boolean"
//                             ? value.toString()
//                             : value}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>

//               <HistoryCard headerName={"USB Information"}>
//                 {usbInfoData[0]?.length > 0 ? (
//                   usbInfoData[0]?.map((item, index) => (
//                     <div key={index} className="my-2">
//                       {Object.entries(item).map(([key, value]) => (
//                         <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                           <p key={key} className="p-0 fw-medium m-0 fs-11">
//                             <strong>{key}:</strong>
//                           </p>
//                           <p className="p-0 m-0 fw-medium fs-11 text-end">
//                             {value}
//                           </p>
//                         </div>
//                       ))}
//                       <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
//                         <CustomButton
//                           buttonTitle={"USB Port Details"}
//                           handleClick={() => setHandleUsbPortModal(true)}
//                         />
//                         <CustomButton
//                           buttonTitle={"USB History"}
//                           handleClick={() => setHandleUsbHistoryModal(true)}
//                         />
//                       </div>
//                     </div>
//                   ))
//                 ) : (
//                   <div className="py-2">
//                     <p className="p-0 m-0">USB Info is not available</p>
//                   </div>
//                 )}
//               </HistoryCard>
//             </Col>
//             <Col xl={6} className="h-25 p-1 ">
//               <HistoryCard headerName={"Hardware Information"}>
//                 {hardwareInfoData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>

//               <HistoryCard headerName={"Network Information"}>
//                 <p className="fs-9 p-0 my-2 text-primary fw-semibold">
//                   Current Network
//                 </p>

//                 {networkData[0]?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value}
//                         </p>
//                       </div>
//                     ))}
//                     <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
//                       <CustomButton
//                         buttonTitle={"Veiw Open Ports"}
//                         handleClick={() => setHandlePortModal(true)}
//                       />
//                       <CustomButton
//                         buttonTitle={"View NIC Cards"}
//                         handleClick={() => setHandleNICModal(true)}
//                       />
//                     </div>
//                   </div>
//                 ))}
//               </HistoryCard>
//             </Col>
//             <Col xl={12} className="h-25 p-1  ">
//               <Row>
//                 <Col xl={6}>
//                   <HistoryCard
//                     headerName={"CIS Recommendation"}
//                     infoButton={() => console.log("myTable")}
//                   >
//                     {cisData?.map((v) => (
//                       <CustomAccordian
//                         accordianHeader={toHumanReadable(v?.title)}
//                       >
//                         <div className="d-flex align-items-center justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Recommendation :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.recommendation}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Time :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.timestamp}
//                           </p>
//                         </div>
//                       </CustomAccordian>
//                     ))}
//                   </HistoryCard>
//                 </Col>

//                 <Col xl={6}>
//                   <HistoryCard
//                     headerName={"Threats"}
//                     infoButton={() => console.log("myTable")}
//                   >
//                     {threatsData?.map((v) => (
//                       <CustomAccordian accordianHeader={v?.title}>
//                         <div className="d-flex align-items-center justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Alert Type :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.alertType || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Threat Type :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.threatType || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Threat Action :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.threatAction || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Threat Recommendation :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.recommendation || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Time:</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.timestamp || "NA"}
//                           </p>
//                         </div>
//                       </CustomAccordian>
//                     ))}
//                   </HistoryCard>
//                 </Col>
//               </Row>

//               <HistoryCard
//                 headerName={"Installed Apps"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">App Name</th>
//                         <th scope="col">Version</th>
//                         <th scope="col">source</th>
//                         <th scope="col">Permission</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                          {currentPageData.map((v, index) => (
//                             <tr key={index}>
//                               <td className="fs-10">{offset + index + 1}</td>
//                               <td className="fs-10" style={{ width: "20px" }}>
//                                 {v?.title}
//                               </td>
//                               <td className="fs-10">{v?.version}</td>
//                               <td className="fs-10">{v?.installationSource}</td>
//                               <td className="fs-10">
//                                 <Button
//                                   className="bt btn-dark fs-10 mx-auto"
//                                   onClick={() =>
//                                     handleAppPermission(
//                                       v?.permissions,
//                                       v?.title,
//                                       v?.packageName,
//                                       v?.version,
//                                       v?.installationSource,
//                                       v?.isSystemApp
//                                     )
//                                   }
//                                 >
//                                   Show
//                                 </Button>
//                               </td>
//                             </tr>
//                           ))}
//                     </tbody>
//                   </table>
//                 </div>
//                 <ReactPaginate
//                       previousLabel={"Previous"}
//                       nextLabel={"Next"}
//                       breakLabel={"..."}
//                       pageCount={pageCount}
//                       marginPagesDisplayed={2}
//                       pageRangeDisplayed={3}
//                       onPageChange={handlePageClick}
//                       containerClassName={"pagination"}
//                       activeClassName={"active"}
//                     />
//               </HistoryCard>

//               <HistoryCard
//                 headerName={"Others Activity"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">Activity Type</th>
//                         <th scope="col">Activity Value</th>
//                         <th scope="col">Activity Status </th>
//                         <th scope="col">Time</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {othersData?.map((v, index) => (
//                         <tr>
//                           <td className="fs-10">{index + 1}</td>
//                           <td className="fs-10">{v?.type}</td>
//                           <td className="fs-10">{v?.value}</td>
//                           <td className="fs-10">{v?.status + ""}</td>
//                           <td className="fs-10">{v?.timestamp}</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </HistoryCard>

//               {/* <HistoryCard
//                 headerName={"Recent Events Triggered"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">Event Type</th>
//                         <th scope="col">Timestamp</th>
//                         <th scope="col">SnapShot</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {recentEventData?.map((v, index) => (
//                         <tr key={index}>
//                           <td className="fs-10">{index + 1}</td>
//                           <td className="fs-10">{v?.eventTriggerType}</td>
//                           <td className="fs-10">{v?.serverTimestamp}</td>
//                           <td className="fs-10">NA</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </HistoryCard> */}
//             </Col>
//           </Row>
//         )}
//       </div>
//       {/* APP PERMISON MODAL */}{" "}
//       <CustomModal
//         modelTitle={"App Permission"}
//         show={showPermission}
//         handleClose={() => setShowPermission(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Permisson {appPermissions?.length}
//         </p>

//         <div className="table-responsive p-0 m-0  my-2">
//           <table className="table m-0 p-0" id="historyTable">
//             <thead>
//               <tr>
//                 <th scope="col">S.no</th>
//                 <th scope="col">Name</th>
//               </tr>
//             </thead>
//             <tbody>
//               {appPermissions?.map((v, index) => (
//                 <tr>
//                   <td className="fs-10">{index + 1}</td>
//                   <td className="fs-10">{v}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </CustomModal>
//       {/* APP PERMISSION MODAL END */}
//       {/* PORT MODAL */}
//       <CustomModal
//         modelTitle={"Open Ports"}
//         show={handlePortModal}
//         handleClose={() => setHandlePortModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Open Ports {networkData[1]?.totalOpenPorts?.length}
//         </p>
//         {networkData[1]?.totalOpenPorts?.map((v, index) => (
//           <div
//             className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
//             key={index}
//           >
//             <p className="p-0 m-0">#{index + 1}</p>
//             <p className="p-0 m-0">{v}</p>
//           </div>
//         ))}
//       </CustomModal>
//       {/* PORT MODAL END */}
//       {/* NIC MODAL */}
//       <CustomModal
//         modelTitle={"NIC Details"}
//         show={handleNICModal}
//         handleClose={() => setHandleNICModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total NIC {networkData[1]?.totalNICCards?.length}
//         </p>
//         {networkData[1]?.totalNICCards?.map((v, index) => (
//           <div className="shadow p-2 rounded-md my-2" key={index}>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold fs-11">Name</p>
//               <p className="p-0 m-0 py-2 fs-10">{v?.description}</p>
//             </div>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold fs-11">Product</p>
//               <p className="p-0 m-0 fs-10">{v?.product}</p>
//             </div>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold">Vendor</p>
//               <p className="p-0 m-0 fs-10">{v?.vendor}</p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* NIC MODAL END */}
//       {/* ANTIVIRUS MODAL */}
//       <CustomModal
//         modelTitle={"NIC Details"}
//         show={handleAntiVirusModal}
//         handleClose={() => setHandleAntiVirusModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Av Service {antivirusInfoData[1]?.length}
//         </p>
//         {antivirusInfoData[1]?.map((v, index) => (
//           <div className="my-3 shadow p-1 rounded-md" key={index}>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.service}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Status</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.status}
//               </p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* ANTIVUIRS */}
//       {/* USB HISORY */}
//       <CustomModal
//         modelTitle={"USB Port Details"}
//         show={handleUsbPortModal}
//         handleClose={() => setHandleUsbPortModal(false)}
//       >
//         <p className="p-4 text-center">No Data Found</p>
//       </CustomModal>
//       <CustomModal
//         modelTitle={"USB History"}
//         show={handleUsbHistoryModal}
//         handleClose={() => setHandleUsbHistoryModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Result {usbInfoData[1]?.length}
//         </p>
//         {usbInfoData[1]?.map((v, index) => (
//           <div className="my-3 shadow p-1 rounded-md" key={index}>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.friendlyName}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
//                 productID
//               </p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.productID}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
//                 vendorID
//               </p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.vendorID}
//               </p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* USB HISTORY END */}
//       {/* BLUETOOTH HISTORY */}
//       <CustomModal
//         modelTitle={"Bluetooth Devices"}
//         show={handleBlueToothInfoModal}
//         handleClose={() => setHandleBlueToothInfoModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Open Ports {bluetoothInfoData[1]?.blueToothPariedDevice?.length}
//         </p>
//         {bluetoothInfoData[1]?.blueToothPariedDevice?.map((v, index) => (
//           <div className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md">
//             <p className="p-0 m-0 fs-11 fw-bold">#{index}</p>
//             <p className="p-0 m-0 fs-11">{v?.name}</p>
//           </div>
//         ))}
//       </CustomModal>
//       {/* BLUETOOTH HISTORYENd */}
//     </React.Fragment>
//   );
// }

// export default ChromeClientsDetail;



// import React, { useEffect, useState } from "react";
// import HistoryCard from "../../components/common/HistoryCard";
// import Header from "../../layouts/Header";
// import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
// import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
// import { Button, Col, Row } from "react-bootstrap";
// import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
// import ReactPaginate from "react-paginate";
// import {
//   BasicClientData,
//   hardwareInformation,
// } from "../../components/common/detailData/deviceData";
// import CustomButton from "../../components/common/CustomButton";
// import CustomModal from "../../components/CustomModal";
// import { Link, useLocation } from "react-router-dom";
// import CustomAccordian from "../../components/common/CustomAccordian";
// import useFetchOsInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchOsInfo";
// import useFetchNetworkInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchNetworkInfo";
// import PageLoader from "../../components/common/Loader/PageLoader";
// import useFetchOsLinux from "../../hooks/linuxhooks/useFetchOsLinux";
// import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
// import useFetchOsAndroid from "../../hooks/AndroidHooks/useFetchOsAndroid";
// import ReactSpeedometer from "react-d3-speedometer/slim";
// import Moment from 'react-moment';
// function ChromeClientsDetail() {
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [handleUsbModal, setHandleUsbModal] = useState(false);
//   const [handleNICModal, setHandleNICModal] = useState(false);
//   const [handlePortModal, setHandlePortModal] = useState(false);
//   const [handleAntiVirusModal, setHandleAntiVirusModal] = useState(false);
//   const [handleUsbHistoryModal, setHandleUsbHistoryModal] = useState(false);
//   const [handleUsbPortModal, setHandleUsbPortModal] = useState(false);

//   const [appPermissions, setAppPermissions] = useState([]);
//   const [showPermission, setShowPermission] = useState(false);

//   const [handleBlueToothInfoModal, setHandleBlueToothInfoModal] =
//     useState(false);

//   const [permissionAppsDetailsTitle, setPermissionAppsDetailsTitle] =
//     useState();
//   const [permissionAppsDetailsVersion, setPermissionAppsDetailsVersion] =
//     useState();
//   const [permissionAppsDetailsPackage, setPermissionAppsDetailsPackage] =
//     useState();
//   const [permissionAppsDetailsSystem, setPermissionAppsDetailsSystem] =
//     useState();
//   const [permissionAppsDetailsSource, setPermissionAppsDetailsSource] =
//     useState();

//   console.log(permissionAppsDetailsTitle, "permissionAppsDetailsTitle");
//   const location = useLocation();
//   const { pcInfoId, basicInfo } = location?.state;

//   console.log(pcInfoId, "LOCATIONasdasdasdsadasdsadDATALINUX");

//   const {
//     osData,
//     osloading,
//     networkData,
//     basicInfoData,
//     antivirusInfoData,
//     hardwareInfoData,
//     usbInfoData,
//     bluetoothInfoData,
//     installedProgrameData,
//     cisData,
//     threatsData,
//     othersData,
//     recentEventData,
//     locationInfo,
//     usbHistory

//   } = useFetchOsAndroid(pcInfoId);
//   // const { networkData, networkloading } = useFetchNetworkInfo(
//   //   pcInfoId,
//   //   basicInfo?.hostname
//   // );
//   console.log(othersData,'othersData without R')
// // var other_data = othersData.slice().reverse(); // reversing here othersData
// //console.log(other_data,'other_data with reverse' )

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   console.log(basicInfoData, "serviceProgrameData");

//   const handleAppPermission = (
//     permissionData,
//     title,
//     packageName,
//     version,
//     installationSource,
//     isSystemApp
//   ) => {
//     setPermissionAppsDetailsTitle(title);
//     setPermissionAppsDetailsVersion(version);
//     setPermissionAppsDetailsPackage(packageName);
//     setPermissionAppsDetailsSystem(isSystemApp);
//     setPermissionAppsDetailsSource(installationSource);
//     if (permissionData === "[]") {
//       setAppPermissions([]);
//     } else {
//       const convertedArray = permissionData.slice(1, -1).split(",");
//       setAppPermissions(convertedArray);
//     }

//     setShowPermission(true);
//   };

//   const [currentPage, setCurrentPage] = useState(0);
//   const itemsPerPage = 5;

//   const handlePageClick = (data) => {
//     setCurrentPage(data.selected);
//   };

//   const offset = currentPage * itemsPerPage;
//   const currentPageData = installedProgrameData.slice(
//     offset,
//     offset + itemsPerPage
//   );
//   const pageCount = Math.ceil(installedProgrameData.length / itemsPerPage);


//   const formatValue = (value) => {
//     if (Array.isArray(value)) {
//         return value.join(', ');
//     } else if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
//         return value.slice(1, -1).replace(/"/g, '');
//     }
//     return value;
// };



// const formatTimestamp = (timestamp) => {
//   const date = new Date(timestamp);
//   const day = String(date.getDate()).padStart(2, '0');
//   const month = String(date.getMonth() + 1).padStart(2, '0');
//   const year = date.getFullYear();
//   const hours = String(date.getHours()).padStart(2, '0');
//   const minutes = String(date.getMinutes()).padStart(2, '0');
//   const seconds = String(date.getSeconds()).padStart(2, '0');
//   return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// };
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div className="main main-app p-3 p-lg-4">
//         <div className="d-md-flex align-items-center justify-content-between mb-4">
//           <div className="d-flex align-items-center justify-content-center gap-4">
//             <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
//               <Link to="#">
//                 <img
//                   src={chromeIcon}
//                   alt="Window-logo"
//                   width={55}
//                   height={55}
//                 />
//               </Link>

//               <p className="mx-2 fs-18 m-0 p-0">|</p>
//               <p className="fs-18 fw-bolder p-0 m-0 ">Chrome Client Details</p>
//             </span>
//             <span>
//               <i className="fas fa-arrow-right"></i>
//             </span>
//             <p className="p-0 m-0 fs-16 fw-semibold text-warning">
//               {pcInfoId || "Client Detail"}
              
//             </p>
//           </div>
//           <div className="d-flex align-items-center justify-content-center gap-2">
           
//           <span>
//               {/* <img src={meterIcon} alt="Meter" /> */}
//               <ReactSpeedometer
//                 width={150}
//                 height={102}
//                 needleHeightRatio={0.6}
//                 value={600} 
//                 currentValueText="Client Score"
//                 customSegmentLabels={[
//                   {
                    
//                     position: "INSIDE",
//                     color: "#555",
//                   },
//                   {
                 
//                     position: "INSIDE",
//                     color: "#555",
//                   },
//                   {
                    
//                     position: "INSIDE",
//                     color: "#555",
//                     fontSize: "19px",
//                   },
//                   {
                   
//                     position: "INSIDE",
//                     color: "#555",
//                   },
//                   {
                  
//                     position: "INSIDE",
//                     color: "#555",
//                   },
//                 ]}
//                 ringWidth={4}
//                 needleTransitionDuration={3333}
//                 needleTransition="easeElastic"
//                 needleColor={"#90f2ff"}
//                 textColor={"#d8dee9"}
//               />
//             </span>
//           </div>
//         </div>
//         {osloading ? (
//           <PageLoader />
//         ) : (
//           <Row className="">
//             <Col xl={3} className="h-25 p-1 ">
//               <HistoryCard headerName={"Basic Information"}>
//                 {basicInfoData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value || "NA"}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>

//               {/* <HistoryCard headerName={"USB Information"}>
//                 {usbInfoData[0]?.length > 0 ? (
//                   usbInfoData[0]?.map((item, index) => (
//                     <div key={index} className="my-2">
//                       {Object.entries(item).map(([key, value]) => (
//                         <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                           <p key={key} className="p-0 fw-medium m-0 fs-11">
//                             <strong>{key}:</strong>
//                           </p>
//                           <p className="p-0 m-0 fw-medium fs-11 text-end">
//                             {value}
//                           </p>
//                         </div>
//                       ))}
//                       <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
//                         <CustomButton
//                           buttonTitle={"USB Port Details"}
//                           handleClick={() => setHandleUsbPortModal(true)}
//                         />
//                         <CustomButton
//                           buttonTitle={"USB History"}
//                           handleClick={() => setHandleUsbHistoryModal(true)}
//                         />
//                       </div>
//                     </div>
//                   ))
//                 ) : (
//                   <div className="py-2">
//                     <p className="p-0 m-0">USB Info is not available</p>
//                   </div>
//                 )}
//               </HistoryCard> */}
//             </Col>
//             <Col xl={3} className="h-25 p-1 ">
//               <HistoryCard headerName={"Hardware Information"}>
//                 {hardwareInfoData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>
//             </Col>
//             <Col xl={3} className="h-25 p-1">
//               <HistoryCard headerName={"OS Information"}>
//                 {osData?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p
//                           className={` m-0 fw-medium text-end fs-11 ${
//                             value === true ? "text-success" : "text-dark"
//                           }`}
//                         >
//                           {typeof value === "boolean"
//                             ? value.toString()
//                             : value}
//                         </p>
//                       </div>
//                     ))}
//                   </div>
//                 ))}
//               </HistoryCard>
//             </Col>
//             <Col xl={3} className="h-25 p-1">
//               <HistoryCard headerName={"Network Information"}>
//                 <p className="fs-9 p-0 my-2 text-primary fw-semibold">
//                   Current Network
//                 </p>

//                 {networkData[0]?.map((item, index) => (
//                   <div key={index} className="my-2">
//                     {Object.entries(item).map(([key, value]) => (
//                       <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
//                         <p key={key} className="p-0 fw-medium m-0 fs-11">
//                           <strong>{key}:</strong>
//                         </p>
//                         <p className="p-0 m-0 fw-medium fs-11 text-end">
//                           {value}
//                         </p>
//                       </div>
//                     ))}
//                     {/* <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
//                       <CustomButton
//                         buttonTitle={"Veiw Open Ports"}
//                         handleClick={() => setHandlePortModal(true)}
//                       />
//                       <CustomButton
//                         buttonTitle={"View NIC Cards"}
//                         handleClick={() => setHandleNICModal(true)}
//                       />
//                     </div> */}
//                   </div>
//                 ))}
//               </HistoryCard>
//             </Col>
//             <Col xl={12} className="h-25 p-1  ">
//               <Row>
//                 <Col xl={6}>
//                   <HistoryCard
//                     headerName={"Installed Apps"}
//                     infoButton={() => console.log("myTable")}
//                   >
//                     <div className="table-responsive p-0 m-0 my-2">
//                       <table className="table m-0 p-0" id="historyTable">
//                         <thead>
//                           <tr>
//                             <th scope="col" className="fs-11">
//                               S.no
//                             </th>
//                             <th scope="col">App Name</th>
//                             <th scope="col">Version</th>
//                             <th scope="col">Source</th>
//                             <th scope="col">Info</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {currentPageData.map((v, index) => (
//                             <tr key={index}>
//                               <td className="fs-10">{offset + index + 1}</td>
//                               <td className="fs-10" style={{ width: "20px" }}>
//                                 {v?.title}
//                               </td>
//                               <td className="fs-10">{v?.version}</td>
//                               <td className="fs-10">{v?.installationSource}</td>
//                               <td className="fs-10">
//                                 <Button
//                                   className="bt btn-dark fs-10 mx-auto"
//                                   onClick={() =>
//                                     handleAppPermission(
//                                       v?.permissions,
//                                       v?.title,
//                                       v?.packageName,
//                                       v?.version,
//                                       v?.installationSource,
//                                       v?.isSystemApp
//                                     )
//                                   }
//                                 >
//                                   More
//                                 </Button>
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                     <ReactPaginate
//                       previousLabel={"Previous"}
//                       nextLabel={"Next"}
//                       breakLabel={"..."}
//                       pageCount={pageCount}
//                       marginPagesDisplayed={2}
//                       pageRangeDisplayed={3}
//                       onPageChange={handlePageClick}
//                       containerClassName={"pagination"}
//                       activeClassName={"active"}
//                     />
//                   </HistoryCard>
//                 </Col>

//                 <Col xl={6}>
//                   <HistoryCard headerName={"Others Activity"}>
//                     <div className="table-responsive p-0 m-0  my-2">
//                       <table className="table m-0 p-0" id="historyTable">
//                         <thead>
//                           <tr>
//                             <th scope="col" className="fs-11">
//                               S.no
//                             </th>
//                             <th scope="col">Activity Type</th>
//                             <th scope="col">Activity Value</th>
//                             <th scope="col">Activity Status </th>
//                             <th scope="col">Time</th>
//                           </tr>
//                         </thead>
//                         <tbody>
//                           {othersData?.map((v, index) => (
//                             <tr>
//                               <td className="fs-10">{index + 1}</td>
//                               <td className="fs-10">{v?.type}</td>
//                               <td className="fs-10">{formatValue(v?.value)}</td>
//                               <td className="fs-10">{v?.status + ""}</td>
//                               <td className="fs-10">{formatTimestamp(v?.timestamp)}</td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                     </div>
//                   </HistoryCard>

//                 </Col>
             
//                 <Col xl={6}>
//                 <HistoryCard
//                 headerName={"Recent Events Triggered"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">Event Type</th>
//                         <th scope="col">Timestamp</th>
//                         <th scope="col">SnapShot</th>
//                       </tr>
//                     </thead>
//                     <tbody>
//                       {recentEventData?.map((v, index) => (
//                         <tr key={index}>
//                           <td className="fs-10">{index + 1}</td>
//                           <td className="fs-10">{v?.eventTriggerType}</td>
//                           <td className="fs-10">{formatTimestamp(v?.serverTimestamp)}</td>
//                           <td className="fs-10">NA</td>
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </HistoryCard>
//                 </Col>

              

//                 <Col xl={6}>
//                 <HistoryCard
//                 headerName={"Location information"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">timestamp</th>
//                         <th scope="col">latitude</th>
//                         <th scope="col">longitude</th>
//                         <th scope="col">altitude</th>

//                       </tr>
//                     </thead>
//                     <tbody>
//                       {locationInfo?.map((v, index) => (
//                         <tr key={index}>
//                           <td className="fs-10">{index + 1}</td>
//                           <td className="fs-10">{formatTimestamp(v?.timestamp)}</td>
//                           <td className="fs-10">{v?.latitude}</td>
//                           <td className="fs-10">{v?.longitude}</td>
//                           <td className="fs-10">{v?.altitude}</td>
                          
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </HistoryCard>
//                 </Col>

//                 <Col xl={6}>
//                   <HistoryCard
//                     headerName={"CIS Recommendation"}
//                     infoButton={() => console.log("myTable")}
//                   >
//                     {cisData?.map((v) => (
//                       <CustomAccordian accordianHeader={v?.title}>
//                         <div className="d-flex align-items-center justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Recommendation :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.recommendation}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Time :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.timestamp}
//                           </p>
//                         </div>
//                       </CustomAccordian>
//                     ))}
//                   </HistoryCard>
//                 </Col>

//                 <Col xl={6}>
//                   <HistoryCard
//                     headerName={"Threats"}
//                     infoButton={() => console.log("myTable")}
//                   >
//                     {threatsData?.map((v) => (
//                       <CustomAccordian accordianHeader={v?.title}>
//                         <div className="d-flex align-items-center justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Alert Type :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.alertType || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Threat Type :</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.threatType || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Threat Action :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.threatAction || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">
//                             Threat Recommendation :
//                           </p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.recommendation || "NA"}
//                           </p>
//                         </div>
//                         <div className="d-flex align-items-center my-2 justify-content-between">
//                           <p className="p-0 m-0 fs-11 fw-bold">Time:</p>
//                           <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
//                             {v?.timestamp || "NA"}
//                           </p>
//                         </div>
//                       </CustomAccordian>
//                     ))}
//                   </HistoryCard>
//                 </Col>
//               </Row>

            
//                 <HistoryCard
//                 headerName={"USB History"}
//                 infoButton={() => console.log("myTable")}
//               >
//                 <div className="table-responsive p-0 m-0  my-2">
//                   <table className="table m-0 p-0" id="historyTable">
//                     <thead>
//                       <tr>
//                         <th scope="col" className="fs-11">
//                           S.no
//                         </th>
//                         <th scope="col">Product Name</th>
                       
//                         <th scope="col">Manufacturer</th>
//                         <th scope="col">Vendor ID</th>
//                         <th scope="col">Product ID</th>
//                         {/* <th scope="col">Whitelist Status</th> */}
//                         <th scope="col">Timestamp</th>


//                       </tr>
//                     </thead>
//                     <tbody>
//                       {usbHistory[0]?.usbHistory?.map((v, index) => (
//                         <tr key={index}>
//                           <td className="fs-10">{index + 1}</td>
//                           <td className="fs-10">{v?.productName}</td>
//                           <td className="fs-10">{v?.manufacturer}</td>
//                           <td className="fs-10">{v?.vendorId}</td>
//                           <td className="fs-10">{v?.productId}</td>
//                           <td className="fs-10">{formatTimestamp(v?.timestamp)}</td>
                          
                        
//                         </tr>
//                       ))}
//                     </tbody>
//                   </table>
//                 </div>
//               </HistoryCard>
//                 </Col>
        
//           </Row>
//         )}
//       </div>
//       {/* APP PERMISON MODAL */}{" "}
//       <CustomModal
//         modelTitle={"Application Details"}
//         show={showPermission}
//         handleClose={() => setShowPermission(false)}
//       >
//         <ul class="list-group list-group-flush">
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Name</div>
//             </div>
//             <span class="badge text-black">{permissionAppsDetailsTitle}</span>
//           </li>
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Version</div>
//             </div>
//             <span class="badge text-black">{permissionAppsDetailsVersion}</span>
//           </li>
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Package name</div>
//             </div>
//             <span class="badge text-black">{permissionAppsDetailsPackage}</span>
//           </li>
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Is System App</div>
//             </div>
//             <span class="badge text-black">
//               {permissionAppsDetailsSystem
//                 ? permissionAppsDetailsSystem
//                 : "N/A"}
//             </span>
//           </li>
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Installation Source</div>
//             </div>
//             <span class="badge text-black">{permissionAppsDetailsSource}</span>
//           </li>
//           <li class="list-group-item d-flex justify-content-between align-items-start">
//             <div class="ms-2 me-auto">
//               <div class="fw-bold">Permisson List</div>
//               {/* Total Permisson */}
//             </div>
//             <span class="badge text-bg-primary rounded-pill">
//               {appPermissions?.length}
//             </span>
//           </li>

//           {/* <span className="text-end p-0 m-0 fs-11 text-primary-dark ">
//             Total Permisson {appPermissions?.length}
//           </span> */}
//         </ul>

//         <div className="table-responsive p-3 m-0  my-2">
//           <table className="table m-0 p-0" id="historyTable">
//             <thead>
//               <tr>
//                 <th scope="col">S.no</th>
//                 <th scope="col">Name</th>
//               </tr>
//             </thead>
//             <tbody>
//               {appPermissions?.map((v, index) => (
//                 <tr>
//                   <td className="fs-10">{index + 1}</td>
//                   <td className="fs-10">{v}</td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </CustomModal>
//       {/* APP PERMISSION MODAL END */}
//       {/* PORT MODAL */}
//       <CustomModal
//         modelTitle={"Open Ports"}
//         show={handlePortModal}
//         handleClose={() => setHandlePortModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Open Ports {networkData[1]?.totalOpenPorts?.length}
//         </p>
//         {networkData[1]?.totalOpenPorts?.map((v, index) => (
//           <div
//             className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
//             key={index}
//           >
//             <p className="p-0 m-0">#{index + 1}</p>
//             <p className="p-0 m-0">{v}</p>
//           </div>
//         ))}
//       </CustomModal>
//       {/* PORT MODAL END */}
//       {/* NIC MODAL */}
//       <CustomModal
//         modelTitle={"NIC Details"}
//         show={handleNICModal}
//         handleClose={() => setHandleNICModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total NIC {networkData[1]?.totalNICCards?.length}
//         </p>
//         {networkData[1]?.totalNICCards?.map((v, index) => (
//           <div className="shadow p-2 rounded-md my-2" key={index}>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold fs-11">Name</p>
//               <p className="p-0 m-0 py-2 fs-10">{v?.description}</p>
//             </div>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold fs-11">Product</p>
//               <p className="p-0 m-0 fs-10">{v?.product}</p>
//             </div>
//             <div className="d-flex align-items-center justify-content-between">
//               <p className="p-0 m-0 fw-bold">Vendor</p>
//               <p className="p-0 m-0 fs-10">{v?.vendor}</p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* NIC MODAL END */}
//       {/* ANTIVIRUS MODAL */}
//       <CustomModal
//         modelTitle={"NIC Details"}
//         show={handleAntiVirusModal}
//         handleClose={() => setHandleAntiVirusModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Av Service {antivirusInfoData[1]?.length}
//         </p>
//         {antivirusInfoData[1]?.map((v, index) => (
//           <div className="my-3 shadow p-1 rounded-md" key={index}>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.service}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Status</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.status}
//               </p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* ANTIVUIRS */}
//       {/* USB HISORY */}
//       <CustomModal
//         modelTitle={"USB Port Details"}
//         show={handleUsbPortModal}
//         handleClose={() => setHandleUsbPortModal(false)}
//       >
//         <p className="p-4 text-center">No Data Found</p>
//       </CustomModal>
//       <CustomModal
//         modelTitle={"USB History"}
//         show={handleUsbHistoryModal}
//         handleClose={() => setHandleUsbHistoryModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Result {usbInfoData[1]?.length}
//         </p>
//         {usbInfoData[1]?.map((v, index) => (
//           <div className="my-3 shadow p-1 rounded-md" key={index}>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.friendlyName}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
//                 productID
//               </p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.productID}
//               </p>
//             </div>
//             <div className="d-flex align-items-center p-1 justify-content-between">
//               <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
//                 vendorID
//               </p>
//               <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
//                 {v?.vendorID}
//               </p>
//             </div>
//           </div>
//         ))}
//       </CustomModal>
//       {/* USB HISTORY END */}
//       {/* BLUETOOTH HISTORY */}
//       <CustomModal
//         modelTitle={"Bluetooth Devices"}
//         show={handleBlueToothInfoModal}
//         handleClose={() => setHandleBlueToothInfoModal(false)}
//       >
//         <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
//           Total Open Ports {bluetoothInfoData[1]?.blueToothPariedDevice?.length}
//         </p>
//         {bluetoothInfoData[1]?.blueToothPariedDevice?.map((v, index) => (
//           <div className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md">
//             <p className="p-0 m-0 fs-11 fw-bold">#{index}</p>
//             <p className="p-0 m-0 fs-11">{v?.name}</p>
//           </div>
//         ))}
//       </CustomModal>
//       {/* BLUETOOTH HISTORYENd */}
//     </React.Fragment>
//   );
// }

// export default ChromeClientsDetail;


import React, { useEffect, useState } from "react";
import HistoryCard from "../../components/common/HistoryCard";
import Header from "../../layouts/Header";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import androidIcon from "../../assets/img/DashIcon/newAndroidIcon.png";
import { Button, Col, Row } from "react-bootstrap";
import meterIcon from "../../assets/img/meter.png";
import DataTable from "react-data-table-component";
import chromeIcon from "../../assets/img/DashIcon/newChromeIcon.png";
import ReactPaginate from "react-paginate";
import {
  BasicClientData,
  hardwareInformation,
} from "../../components/common/detailData/deviceData";
import CustomButton from "../../components/common/CustomButton";
import CustomModal from "../../components/CustomModal";
import { Link, useLocation } from "react-router-dom";
import CustomAccordian from "../../components/common/CustomAccordian";
import useFetchOsInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchOsInfo";
import useFetchNetworkInfo from "../../hooks/windowsHooks/windowClientsHooks/useFetchNetworkInfo";
import PageLoader from "../../components/common/Loader/PageLoader";
import useFetchOsLinux from "../../hooks/linuxhooks/useFetchOsLinux";
import useFetchAndroidSummary from "../../hooks/AndroidHooks/useFetchAndroidSummary";
import useFetchOsAndroid from "../../hooks/AndroidHooks/useFetchOsAndroid";
import ReactSpeedometer from "react-d3-speedometer/slim";
import Moment from "react-moment";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
function AndroidClientsDetail() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [handleUsbModal, setHandleUsbModal] = useState(false);
  const [handleNICModal, setHandleNICModal] = useState(false);
  const [handlePortModal, setHandlePortModal] = useState(false);
  const [handleAntiVirusModal, setHandleAntiVirusModal] = useState(false);
  const [handleUsbHistoryModal, setHandleUsbHistoryModal] = useState(false);
  const [handleUsbPortModal, setHandleUsbPortModal] = useState(false);

  const [appPermissions, setAppPermissions] = useState([]);
  const [showPermission, setShowPermission] = useState(false);

  const [handleBlueToothInfoModal, setHandleBlueToothInfoModal] =
    useState(false);

  const [permissionAppsDetailsTitle, setPermissionAppsDetailsTitle] =
    useState();
  const [permissionAppsDetailsVersion, setPermissionAppsDetailsVersion] =
    useState();
  const [permissionAppsDetailsPackage, setPermissionAppsDetailsPackage] =
    useState();
  const [permissionAppsDetailsSystem, setPermissionAppsDetailsSystem] =
    useState();
  const [permissionAppsDetailsSource, setPermissionAppsDetailsSource] =
    useState();

  console.log(permissionAppsDetailsTitle, "permissionAppsDetailsTitle");
  const location = useLocation();
  const { pcInfoId, basicInfo } = location?.state;

  console.log(pcInfoId, "LOCATIONasdasdasdsadasdsadDATALINUX");

  const {
    osData,
    osloading,
    networkData,
    basicInfoData,
    antivirusInfoData,
    hardwareInfoData,
    usbInfoData,
    bluetoothInfoData,
    installedProgrameData,
    cisData,
    threatsData,
    othersData,
    recentEventData,
    locationInfo,
    usbHistory,
    securityControls,
    auditScore
  } = useFetchOsAndroid(pcInfoId);
  // const { networkData, networkloading } = useFetchNetworkInfo(
  //   pcInfoId,
  //   basicInfo?.hostname
  // );
  console.log(securityControls, "securityControls .........");
  // var other_data = othersData.slice().reverse(); // reversing here othersData
  //console.log(other_data,'other_data with reverse' )

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  console.log(basicInfoData, "serviceProgrameData");

  const handleAppPermission = (
    permissionData,
    title,
    packageName,
    version,
    installationSource,
    isSystemApp,
    row
  ) => {
    setPermissionAppsDetailsTitle(title);
    setPermissionAppsDetailsVersion(version);
    setPermissionAppsDetailsPackage(packageName);
    setPermissionAppsDetailsSystem(isSystemApp);
    setPermissionAppsDetailsSource(installationSource);
    if (permissionData === "[]") {
      setAppPermissions([]);
    } else {
      const convertedArray = permissionData.slice(1, -1).split(",");
      setAppPermissions(convertedArray);
    }

    setShowPermission(true);
  };

  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 5;

  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const offset = currentPage * itemsPerPage;
  const currentPageData = installedProgrameData.slice(
    offset,
    offset + itemsPerPage
  );
  const pageCount = Math.ceil(installedProgrameData.length / itemsPerPage);

  const formatValue = (value) => {
    if (Array.isArray(value)) {
      return value.join(", ");
    } else if (
      typeof value === "string" &&
      value.startsWith("[") &&
      value.endsWith("]")
    ) {
      return value.slice(1, -1).replace(/"/g, "");
    }
    return value;
  };

  const [handleOthersActivityPage, setHandleOthersActivityPage] = useState(1);
  const [handleOthersActivityRowPage, setHandleOthersActivityRowPage] =
    useState(10);

  const [handleInstalledAppsPage, setHandleInstalledAppsPage] = useState(10);
  const [handleInstalledAppsRowPage, setHandleInstalledAppsRowPage] = useState(10);

  const [handleEventsTriggeredPage, setHandleEventsTriggeredPage] = useState(10);
  const [handleEventsTriggeredRowPage, setHandleEventsTriggeredRowPage] = useState(10);

  const [handleLocationinformationPage, setHandleLocationinformationPage] = useState(10);
  const [handleLocationinformationRowPage, setHandleLocationinformationRowPage] = useState(10);

  const [handleUsbHistroyPage, setHandleUsbHistroyPage] = useState(10);
  const [handleUsbHistroyRowPage, setHandleUsbHistroyRowPage] = useState(10);
  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold', // Make header text bold
      },
    },
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-1">
          <div className="d-flex align-items-center justify-content-center gap-4">
            <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
              <Link to="#">
                <img
                  src={chromeIcon}
                  alt="Window-logo"
                  width={55}
                  height={55}
                />
              </Link>
              <p className="mx-2 fs-18 m-0 p-0">|</p>
              <p className="fs-18 fw-bolder p-0 m-0 ">Chrome Client Details</p>
            </span>
            <span>
              <i className="fas fa-arrow-right"></i>
            </span>
            <p className="p-0 m-0 fs-16 fw-semibold text-warning">
              {pcInfoId || "Client Detail"}
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-center gap-2">
            <span>
              {/* <img src={meterIcon} alt="Meter" /> */}
              <ReactSpeedometer
                width={150}
                height={102}
                needleHeightRatio={0.6}
                value={auditScore}
                currentValueText="Client Score"
                customSegmentLabels={[
                  {
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    position: "INSIDE",
                    color: "#555",
                    fontSize: "19px",
                  },
                  {
                    position: "INSIDE",
                    color: "#555",
                  },
                  {
                    position: "INSIDE",
                    color: "#555",
                  },
                ]}
                ringWidth={4}
                needleTransitionDuration={3333}
                needleTransition="easeElastic"
                needleColor={"#90f2ff"}
                textColor={"#d8dee9"}
              />
            </span>
          </div>
        </div>
        {osloading ? (
          <PageLoader />
        ) : (
          <Row className="">
            <Col xl={3} className="h-25 p-1 ">
              <HistoryCard headerName={"Basic Information"}>
                {basicInfoData?.map((item, index) => (
                  <div key={index} className="my-2">
                    {Object.entries(item).map(([key, value]) => (
                      <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                        <p key={key} className="p-0 fw-medium m-0 fs-11">
                          <strong>{key}:</strong>
                        </p>
                        <p className="p-0 m-0 fw-medium fs-11 text-end">
                          {value || "NA"}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </HistoryCard>

              {/* <HistoryCard headerName={"USB Information"}>
                {usbInfoData[0]?.length > 0 ? (
                  usbInfoData[0]?.map((item, index) => (
                    <div key={index} className="my-2">
                      {Object.entries(item).map(([key, value]) => (
                        <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                          <p key={key} className="p-0 fw-medium m-0 fs-11">
                            <strong>{key}:</strong>
                          </p>
                          <p className="p-0 m-0 fw-medium fs-11 text-end">
                            {value}
                          </p>
                        </div>
                      ))}
                      <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
                        <CustomButton
                          buttonTitle={"USB Port Details"}
                          handleClick={() => setHandleUsbPortModal(true)}
                        />
                        <CustomButton
                          buttonTitle={"USB History"}
                          handleClick={() => setHandleUsbHistoryModal(true)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="py-2">
                    <p className="p-0 m-0">USB Info is not available</p>
                  </div>
                )}
              </HistoryCard> */}
            </Col>
            <Col xl={3} className="h-25 p-1 ">
              <HistoryCard headerName={"Hardware Information"}>
                {hardwareInfoData?.map((item, index) => (
                  <div key={index} className="my-2">
                    {Object.entries(item).map(([key, value]) => (
                      <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                        <p key={key} className="p-0 fw-medium m-0 fs-11">
                          <strong>{key}:</strong>
                        </p>
                        <p className="p-0 m-0 fw-medium fs-11 text-end">
                          {value}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </HistoryCard>
            </Col>
            <Col xl={3} className="h-25 p-1">
              <HistoryCard headerName={"OS Information"}>
                {osData?.map((item, index) => (
                  <div key={index} className="my-2">
                    {Object.entries(item).map(([key, value]) => (
                      <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                        <p key={key} className="p-0 fw-medium m-0 fs-11">
                          <strong>{key}:</strong>
                        </p>
                        <p
                          className={` m-0 fw-medium text-end fs-11 text-capitalize ${
                            value === true ? "text-success" : "text-dark"
                          }`}
                        >
                          {typeof value === "boolean"
                            ? value.toString()
                            : value}
                        </p>
                      </div>
                    ))}
                  </div>
                ))}
              </HistoryCard>
            </Col>
            
            <Col xl={3} className="h-25 p-1">
              <HistoryCard headerName={"Network Information"}>
                <p className="fs-9 p-0 my-2 text-primary fw-semibold">
                  Current Network
                </p>

                {networkData[0]?.map((item, index) => (
                  <div key={index} className="my-2">
                    {Object.entries(item).map(([key, value]) => (
                      <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                        <p key={key} className="p-0 fw-medium m-0 fs-11">
                          <strong>{key}:</strong>
                        </p>
                        <p className="p-0 m-0 fw-medium fs-11 text-end">
                          {value}
                        </p>
                      </div>
                    ))}
                    {/* <div className="d-flex align-items-center gap-2 justify-content-between mt-3">
                      <CustomButton
                        buttonTitle={"Veiw Open Ports"}
                        handleClick={() => setHandlePortModal(true)}
                      />
                      <CustomButton
                        buttonTitle={"View NIC Cards"}
                        handleClick={() => setHandleNICModal(true)}
                      />
                    </div> */}
                  </div>
                ))}
              </HistoryCard>
            </Col>
            <Col xl={3} className="h-25 p-1">
              <HistoryCard headerName={"Security Controls"}>
                {securityControls?.map((item, index) => (
                  <div key={index} className="my-2">
                    {Object.entries(item).map(([key, value]) => (
                      
                      <div className="d-flex align-items-center py-2 justify-content-between  border-1 border-bottom">
                        <p key={key} className="p-0 fw-medium m-0 fs-11">
                          <strong>{key}:</strong>
                        </p>
                        <p
                          className={` m-0 fw-medium text-end fs-11 text-capitalize ${ value === true ? "text-success" : "" ||  value === false ? "text-danger" : "" }`}
                        >
                          {typeof value == "boolean"
                            ? value.toString()
                            : value}
                        </p>


                      </div>
                    ))}
                  </div>
                ))}
              </HistoryCard>
            </Col>
            <Col xl={9}>
            <HistoryCard
                headerName={"USB History"}
                infoButton={() => console.log("myTable")}
              >
                {/* <div className="table-responsive p-0 m-0  my-2">
                  <table className="table m-0 p-0" id="historyTable">
                    <thead>
                      <tr>
                        <th scope="col" className="fs-11">
                          S.no
                        </th>
                        <th scope="col">Product Name</th>

                        <th scope="col">Manufacturer</th>
                        <th scope="col">Vendor ID</th>
                        <th scope="col">Product ID</th>
                        <th scope="col">Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {usbHistory[0]?.usbHistory?.map((v, index) => (
                        <tr key={index}>
                          <td className="fs-10">{index + 1}</td>
                          <td className="fs-10">{v?.productName}</td>
                          <td className="fs-10">{v?.manufacturer}</td>
                          <td className="fs-10">{v?.vendorId}</td>
                          <td className="fs-10">{v?.productId}</td>
                          <td className="fs-10">
                            {formatTimestamp(v?.timestamp)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div> */}

                <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (handleUsbHistroyPage - 1) *
                            handleUsbHistroyRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                       
                        {
                          name: "Product Name",
                          selector: (v) => v?.productName,
                        },
                        { name: "Manufacturer", selector: (v) => v?.manufacturer },
                        {
                          name: "Vendor ID",selector: (v) => v?.vendorId 
                        },
                        {
                          name: "Product ID",
                          selector: (v) =>  v?.productId
                        },
                        {
                          name: "Timestamp",
                          selector: (v) =>  formatTimestamp(v?.timestamp)
                        },
                     
                      ]}
                      data={usbHistory[0]?.usbHistory}
                      pagination
                      paginationPerPage={handleUsbHistroyRowPage} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setHandleUsbHistroyPage(page);
                      }}
                      onChangeRowsPerPage={(page) =>
                        setHandleUsbHistroyRowPage(page)
                      }
                      customStyles={customStyles}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          USB History info is not available
                        </p>
                      }
                    />
              </HistoryCard>
            </Col>
            <Col xl={12} className="h-25 p-1  ">
              <Row>
                {/* <Col xl={6}>
                  <HistoryCard
                    headerName={"Installed Apps"}
                    infoButton={() => console.log("myTable")}
                  >
                    <div className="table-responsive p-0 m-0 my-2">
                      <table className="table m-0 p-0" id="historyTable">
                        <thead>
                          <tr>
                            <th scope="col" className="fs-11">
                              S.no
                            </th>
                            <th scope="col">App Name</th>
                            <th scope="col">Version</th>
                            <th scope="col">Source</th>
                            <th scope="col">Info</th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPageData.map((v, index) => (
                            <tr key={index}>
                              <td className="fs-10">{offset + index + 1}</td>
                              <td className="fs-10" style={{ width: "20px" }}>
                                {v?.title}
                              </td>
                              <td className="fs-10">{v?.version}</td>
                              <td className="fs-10">{v?.installationSource}</td>
                              <td className="fs-10">
                                <Button
                                  className="bt btn-dark fs-10 mx-auto"
                                  onClick={() =>
                                    handleAppPermission(
                                      v?.permissions,
                                      v?.title,
                                      v?.packageName,
                                      v?.version,
                                      v?.installationSource,
                                      v?.isSystemApp
                                    )
                                  }
                                >
                                  More
                                </Button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <ReactPaginate
                      previousLabel={"Previous"}
                      nextLabel={"Next"}
                      breakLabel={"..."}
                      pageCount={pageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={3}
                      onPageChange={handlePageClick}
                      containerClassName={"pagination"}
                      activeClassName={"active"}
                    />
                  </HistoryCard>
                </Col> */}
                <Col xl={6}>
                  <HistoryCard
                    headerName={"Installed Apps"}
                    infoButton={() => console.log("myTable")}
                  >
                    <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (handleInstalledAppsPage - 1) *
                              handleInstalledAppsRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                        { name: "App Name", selector: (v) => 
                        <Tippy content={v?.title}>
                              <div>{v?.title}</div>
                            </Tippy>
                         },
                        {
                          name: "Version",
                          selector: (v) => 
                          <Tippy content={v?.version}>
                              <div>{v?.version}</div>
                            </Tippy>
                          ,
                        },
                        {
                          name: "Source",
                          selector: (v) => v?.installationSource,
                        },
                        {
                          name: "Info",
                          cell: (row) => (
                            <Button
                              className="bt btn-dark fs-10 mx-auto"
                              onClick={() => handleAppPermission(
                                row?.permissions,
                                      row?.title,
                                      row?.packageName,
                                      row?.version,
                                      row?.installationSource,
                                      row?.isSystemApp
                              )}
                            >
                              More
                            </Button>
                          ),
                        },
                      ]}
                      data={installedProgrameData}
                      pagination
                      paginationPerPage={handleInstalledAppsRowPage} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setHandleInstalledAppsPage(page);
                      }}
                      onChangeRowsPerPage={(page) =>
                        setHandleInstalledAppsRowPage(page)
                      }
                      customStyles={customStyles}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          Installed Apps info is not available
                        </p>
                      }
                    />
                  </HistoryCard>
                </Col>
                <Col xl={6}>
                  <HistoryCard
                    headerName={"Others Activity"}
                    infoButton={() => console.log("myTable")}
                  >
                    <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (handleOthersActivityPage - 1) *
                              handleOthersActivityRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                        { name: "Activity Type", selector: (v) => 

                        <Tippy content={v?.type }>
                              <div>{v?.type}</div>
                            </Tippy>

                        },
                        {
                          name: "Activity Value",
                          selector: (v) => 

                          <Tippy content={formatValue(v?.value) }>
                              <div>{formatValue(v?.value)}</div>
                            </Tippy>
                        },
                        {
                          name: "Activity Status",
                          selector: (v) => v?.status + "",
                        },
                        {
                          name: "Time",
                          selector: (v) => 

                          <Tippy content={formatTimestamp(v?.timestamp) }>
                              <div>{formatTimestamp(v?.timestamp)}</div>
                            </Tippy>
                          ,
                        },
                      ]}
                      data={othersData}
                      pagination
                      paginationPerPage={handleOthersActivityRowPage} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setHandleOthersActivityPage(page);
                      }}
                      onChangeRowsPerPage={(page) =>
                        setHandleOthersActivityRowPage(page)
                      }
                      customStyles={customStyles}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          Activity  info is not available
                        </p>
                      }
                    />
                  </HistoryCard>
                </Col>

                <Col xl={6}>
                  <HistoryCard
                    headerName={"Recent Events Triggered"}
                    infoButton={() => console.log("myTable")}
                  >
                    <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (handleEventsTriggeredPage - 1) *
                            handleEventsTriggeredRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                        { name: "Event Type", selector: (v) => 
                        
                        
                        <Tippy content={v?.eventTriggerType }>
                              <div>{v?.eventTriggerType}</div>
                            </Tippy>
                             },
                        {
                          name: "Timestamp",
                          selector: (v) => 

                          <Tippy content={formatTimestamp(v?.serverTimestamp) }>
                              <div>{formatTimestamp(v?.serverTimestamp)}</div>
                            </Tippy>
                        },
                        {
                          name: "SnapShot",
                          selector: (v) =>  'NA',
                        },
                     
                      ]}
                      data={recentEventData[0]}
                      pagination
                      paginationPerPage={handleEventsTriggeredRowPage} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setHandleEventsTriggeredPage(page);
                      }}
                      onChangeRowsPerPage={(page) =>
                        setHandleEventsTriggeredRowPage(page)
                      }
                      customStyles={customStyles}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                         Recent Events Triggered info is not available
                        </p>
                      }
                    />
                  </HistoryCard>
                </Col>

                <Col xl={6}>
                  <HistoryCard
                    headerName={"Location information"}
                    infoButton={() => console.log("myTable")}
                  >
                    <DataTable
                      columns={[
                        {
                          name: "S. No.",
                          selector: (_, index) =>
                            (handleLocationinformationPage - 1) *
                            handleLocationinformationRowPage +
                            index +
                            1,
                          width: "70px",
                        },
                       
                        {
                          name: "Timestamp",
                          selector: (v) => 

                          <Tippy content={formatTimestamp(v?.timestamp) }>
                              <div>{formatTimestamp(v?.timestamp)}</div>
                            </Tippy>
                        },
                        { name: "latitude", selector: (v) => v?.longitude },
                        {
                          name: "longitude",selector: (v) => v?.latitude 
                        },
                        {
                          name: "altitude",
                          selector: (v) =>  

                          <Tippy content={v?.altitude }>
                              <div>{v?.altitude}</div>
                            </Tippy>
                        },
                     
                      ]}
                      data={locationInfo}
                      pagination
                      paginationPerPage={handleLocationinformationRowPage} // Adjust the number of rows per page as needed
                      onChangePage={(page) => {
                        console.log(page);
                        setHandleLocationinformationPage(page);
                      }}
                      onChangeRowsPerPage={(page) =>
                        setHandleLocationinformationRowPage(page)
                      }
                      customStyles={customStyles}
                      noDataComponent={
                        <p className="p-0 my-2  me-auto">
                          Service program info is not available
                        </p>
                      }
                    />
                  </HistoryCard>
                </Col>

                <Col xl={6}>
                  <HistoryCard
                    headerName={"CIS Recommendation"}
                    infoButton={() => console.log("myTable")}
                  >
                    {cisData?.map((v) => (
                      <CustomAccordian accordianHeader={v?.title}>
                        
                        <div className="d-flex align-items-center my-2 justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold ">Is Configured :</p>
                          <p 
                              className={` m-0 fw-medium text-end fs-11  fw-bold text-capitalize ${
                                v?.isConfigured === true ? "text-success" : "text-danger"
                          }`}
                          >
                          {v?.isConfigured !== undefined ? v.isConfigured.toString() : "N/A"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">
                            Recommendation :
                          </p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.recommendation}
                          </p>
                        </div>
                      </CustomAccordian>
                    ))}
                  </HistoryCard>
                </Col>

                <Col xl={6}>
                  <HistoryCard
                    headerName={"Threats"}
                    infoButton={() => console.log("myTable")}
                  >
                    {threatsData?.map((v) => (
                      <CustomAccordian accordianHeader={v?.title}>
                        <div className="d-flex align-items-center justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">Alert Type :</p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.alertType || "NA"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center my-2 justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">Threat Type :</p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.threatType || "NA"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center my-2 justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">
                            Threat Action :
                          </p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.threatAction || "NA"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center my-2 justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">
                            Threat Recommendation :
                          </p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.recommendation || "NA"}
                          </p>
                        </div>
                        <div className="d-flex align-items-center my-2 justify-content-between">
                          <p className="p-0 m-0 fs-11 fw-bold">Time:</p>
                          <p className="p-0 m-0 fs-11 fw-semibold text-secondary">
                            {v?.timestamp || "NA"}
                          </p>
                        </div>
                      </CustomAccordian>
                    ))}
                  </HistoryCard>
                </Col>
              </Row>

            
            </Col>
          </Row>
        )}
      </div>
      {/* APP PERMISON MODAL */}{" "}
      <CustomModal
        modelTitle={"Application Details"}
        show={showPermission}
        handleClose={() => setShowPermission(false)}
      >
        <ul class="list-group list-group-flush">
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Name</div>
            </div>
            <span class="badge text-black">{permissionAppsDetailsTitle}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Version</div>
            </div>
            <span class="badge text-black">{permissionAppsDetailsVersion}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Package name</div>
            </div>
            <span class="badge text-black">{permissionAppsDetailsPackage}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Is System App</div>
            </div>
            <span class="badge text-black">
              {permissionAppsDetailsSystem
                ? permissionAppsDetailsSystem
                : "N/A"}
            </span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Installation Source</div>
            </div>
            <span class="badge text-black">{permissionAppsDetailsSource}</span>
          </li>
          <li class="list-group-item d-flex justify-content-between align-items-start">
            <div class="ms-2 me-auto">
              <div class="fw-bold">Permisson List</div>
              {/* Total Permisson */}
            </div>
            <span class="badge text-bg-primary rounded-pill">
              {appPermissions?.length}
            </span>
          </li>

          {/* <span className="text-end p-0 m-0 fs-11 text-primary-dark ">
            Total Permisson {appPermissions?.length}
          </span> */}
        </ul>

        <div className="table-responsive p-3 m-0  my-2">
          <table className="table m-0 p-0" id="historyTable">
            <thead>
              <tr>
                <th scope="col">S.no</th>
                <th scope="col">Name</th>
              </tr>
            </thead>
            <tbody>
              {appPermissions?.map((v, index) => (
                <tr>
                  <td className="fs-10">{index + 1}</td>
                  <td className="fs-10">{v}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </CustomModal>
      {/* APP PERMISSION MODAL END */}
      {/* PORT MODAL */}
      <CustomModal
        modelTitle={"Open Ports"}
        show={handlePortModal}
        handleClose={() => setHandlePortModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Open Ports {networkData[1]?.totalOpenPorts?.length}
        </p>
        {networkData[1]?.totalOpenPorts?.map((v, index) => (
          <div
            className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md"
            key={index}
          >
            <p className="p-0 m-0">#{index + 1}</p>
            <p className="p-0 m-0">{v}</p>
          </div>
        ))}
      </CustomModal>
      {/* PORT MODAL END */}
      {/* NIC MODAL */}
      <CustomModal
        modelTitle={"NIC Details"}
        show={handleNICModal}
        handleClose={() => setHandleNICModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total NIC {networkData[1]?.totalNICCards?.length}
        </p>
        {networkData[1]?.totalNICCards?.map((v, index) => (
          <div className="shadow p-2 rounded-md my-2" key={index}>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-11">Name</p>
              <p className="p-0 m-0 py-2 fs-10">{v?.description}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold fs-11">Product</p>
              <p className="p-0 m-0 fs-10">{v?.product}</p>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <p className="p-0 m-0 fw-bold">Vendor</p>
              <p className="p-0 m-0 fs-10">{v?.vendor}</p>
            </div>
          </div>
        ))}
      </CustomModal>
      {/* NIC MODAL END */}
      {/* ANTIVIRUS MODAL */}
      <CustomModal
        modelTitle={"NIC Details"}
        show={handleAntiVirusModal}
        handleClose={() => setHandleAntiVirusModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Av Service {antivirusInfoData[1]?.length}
        </p>
        {antivirusInfoData[1]?.map((v, index) => (
          <div className="my-3 shadow p-1 rounded-md" key={index}>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.service}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Status</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.status}
              </p>
            </div>
          </div>
        ))}
      </CustomModal>
      {/* ANTIVUIRS */}
      {/* USB HISORY */}
      <CustomModal
        modelTitle={"USB Port Details"}
        show={handleUsbPortModal}
        handleClose={() => setHandleUsbPortModal(false)}
      >
        <p className="p-4 text-center">No Data Found</p>
      </CustomModal>
      <CustomModal
        modelTitle={"USB History"}
        show={handleUsbHistoryModal}
        handleClose={() => setHandleUsbHistoryModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Result {usbInfoData[1]?.length}
        </p>
        {usbInfoData[1]?.map((v, index) => (
          <div className="my-3 shadow p-1 rounded-md" key={index}>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">Name</p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.friendlyName}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
                productID
              </p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.productID}
              </p>
            </div>
            <div className="d-flex align-items-center p-1 justify-content-between">
              <p className="p-0 m-0 fs-11 text-primary-dark fw-bold">
                vendorID
              </p>
              <p className="p-0 m-0 fs11 fs-11 text-primary-dark ">
                {v?.vendorID}
              </p>
            </div>
          </div>
        ))}
      </CustomModal>
      {/* USB HISTORY END */}
      {/* BLUETOOTH HISTORY */}
      <CustomModal
        modelTitle={"Bluetooth Devices"}
        show={handleBlueToothInfoModal}
        handleClose={() => setHandleBlueToothInfoModal(false)}
      >
        <p className="text-end p-0 m-0 fs-11 text-primary-dark ">
          Total Open Ports {bluetoothInfoData[1]?.blueToothPariedDevice?.length}
        </p>
        {bluetoothInfoData[1]?.blueToothPariedDevice?.map((v, index) => (
          <div className="d-flex align-items-center  my-3 justify-content-between shadow p-2 rounded-md">
            <p className="p-0 m-0 fs-11 fw-bold">#{index}</p>
            <p className="p-0 m-0 fs-11">{v?.name}</p>
          </div>
        ))}
      </CustomModal>
      {/* BLUETOOTH HISTORYENd */}
    </React.Fragment>
  );
}

export default AndroidClientsDetail;


