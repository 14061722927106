import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../assets/img/Excellogo.png";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional
import Switch from "react-switch";
function Users() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasT, setShowOffcanvasT] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [formType, setFormType] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });

  const { user } = useSelector((state) => state.authSlice);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.data;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const handleStatusChange = async (userId, currentStatus) => {
    console.log("Current status:", currentStatus);

    // Convert the current status to a boolean
    const newStatus = !currentStatus;
    console.log("New status:", newStatus);

    try {
      const data = {
        isActive: newStatus,
        user_id: userId,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log(response.data, "rrrrrrrrrr");
      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Update the status locally without refetching the entire data
        setColumnData((prevData) => {
          if (!prevData) {
            console.error("Previous data is undefined");
            return [];
          }

          return prevData.map((user) =>
            user.id === userId ? { ...user, status: newStatus } : user
          );
        });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width:'250px'
    },
    {
      name: "Status",
      selector: (row) => (row.status ? "Active" : "Inactive"),
      sortable: true,
      cell: (row) => (
        <span
          style={getStatusStyle(
            row.status === true || row.status === "true" ? "Active" : "Inactive"
          )}
        >
          {row.status === true || row.status === "true" ? "Active" : "Inactive"}
        </span>
      ),
      width:'110px'
    },

    {
      name: "Added",
      selector: (row) => formatTimestamp(row.createdAt) || "NA",
      sortable: true,
    },
    {
      name: "Associated With",
      sortable: true,
      selector: (row) => (
        <Tippy content={row.departmentInfo || "NA"}>
          <div>{row.departmentInfo || "NA"}</div>
        </Tippy>
      ),
      width: "280px",
    },
    {
      name: "Role",
      selector: (row) => row.roleName,
      sortable: true,
      width:'150px'
     },
    {
      name: "Action",
      cell: (row) => (
        <Switch
          onChange={() =>
            handleStatusChange(
              row.id,
              row.status === "true" || row.status === true
            )
          }
          checked={row.status === "true" || row.status === true} // Convert status to boolean
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00e676"
          offColor="#ff1744"
        />
      ),
      sortable: false,
    },
    {
      name: "Delete",
      cell: (row) => (
        <button
          onClick={() => handleActionClick(row)}
          className="btn btn-outline-danger bg-btn-danger"
          variant="primary"
        >
          <i className="fa-solid fa-trash"></i>
        </button>
      ),
      sortable: false,
    },
  
  ];

  const exportToPDF = (data) => {
    const doc = new jsPDF();
    doc.text("Exported Data", 14, 16);

    const tableColumn = [
      "Name",
      "Email",
      "Status",
      "Sync Time",
      "Associated With",
    ];
    const tableRows = data.map((item) => [
      item.firstName,
      item.email,
      item.status || "NA",
      formatTimestamp(item.createdAt) || "NA",
      item.departmentInfo || "NA",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("data.pdf");
  };

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        Name: item.firstName,
        Email: item.email,
        Status: item.status || "NA",
        SyncTime: formatTimestamp(item.createdAt) || "NA",
        AssociatedWith: item.departmentInfo || "NA",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };
  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleActionClick = async (row) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/delete/by/${user?.data?.user?.organizationId}/${row.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          data: { userId: row.id },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      console.error("Error deleting user:", error);
      // Handle error response
    }
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };
  const handleTableClick = () => {
    setShowOffcanvasT(true);
  };
  const handleCheckboxChange = (columnName) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    if (department) {
      const section = department.sections.find(
        (sec) => sec._id === selectedOption.value
      );
      if (section) {
        setSubsections(section.subSections);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment && !selectedSection && !selectedSubsection) {
      alert(
        "Please select at least one option (department, section, or subsection)."
      );
      return;
    }

    const user_id = selectedRows.map((row) => row.id);

    // Construct the data object based on which fields are selected
    const data = { user_id };

    if (selectedDepartment) {
      data.department = selectedDepartment.value;
    }
    if (selectedSection) {
      data.section = selectedSection.value;
    }
    if (selectedSubsection) {
      data.subSection = selectedSubsection.value;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/manage-users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Close Offcanvas and update DataTable data
      setShowOffcanvas(false);
      fetchData();
      setSelectedRows([]);
      setToggleClearRows(!toggleClearRows); // Toggle clear rows
    } catch (error) {
      toast.error(error);

      console.error("Error submitting form:", error);
      // Handle error response
    }
  };
  const handleAddUser = async () => {
   

    if (!newUserName || !newUserEmail) {
      alert("Please enter a name and email.");
      return;
    }

    const data = {
      fname: newUserName,
      email: newUserEmail,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setNewUserName("");
      setNewUserEmail("");

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error?.response?.data?.message);
      // Handle error response
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
    }
    console.log('Add users:', { newUserName, newUserEmail });
  };

  const handleAddOrganization = async () => {

    if (!newUserName || !newUserEmail) {
      alert("Please enter a name and email.");
      return;
    }

    const data = {
      fname: newUserName,
      email: newUserEmail,
      organization: user?.data?.user?.organization,
      organizationId: user?.data?.user?.organizationId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/add-organization-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setNewUserName("");
      setNewUserEmail("");

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error);
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Handle error response
    }
    console.log('Add Organization:', { newUserName, newUserEmail });
  };

  const handleAddSuper = async () => {

    if (!newUserName || !newUserEmail) {
      alert("Please enter a name and email.");
      return;
    }

    const data = {
      fname: newUserName,
      email: newUserEmail,
      organization: user?.data?.user?.organization,
      organizationId: user?.data?.user?.organizationId,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/add-super-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setNewUserName("");
      setNewUserEmail("");

      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      // console.error("Error adding user:", error);
      toast.error(error?.response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Handle error response
    }
    console.log('Add Super:', { newUserName, newUserEmail });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (formType === 'user') {
      handleAddUser();
    } else if (formType === 'organization') {
      handleAddOrganization();
    } else if (formType === 'super') {
      handleAddSuper();
    }
    document.getElementById('closeModalButton').click(); // Close the modal after submission
  };


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Manage Users</p>
          </span>
          <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-danger text-white"
              onClick={() => exportToPDF(columnData)}
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToExcel(columnData)}
            >
              <img src={Excellogo} height={20} width={15} />
              &nbsp; Export
            </button>
          
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div class="col-xl">
            <p class="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
            <p class="text-danger p-0 m-0 fw-semibold">
              {columnData.length} Users
            </p>
          </div>
          <button
            type="button"
            className="btn btn-info text-white"
            onClick={handleControlClick}
            disabled={selectedRows.length === 0}
          >
            Control
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setFormType("user")}
          >
            <i class="fa-solid fa-user-plus"></i> User
          </button>
          &nbsp; &nbsp;
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
            onClick={() => setFormType("organization")}
          >
            <i class="fa-solid fa-user-plus"></i> Org Admin
          </button>
          &nbsp; &nbsp;
          <button
              type="button"
              className="btn btn-dark text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              onClick={() => setFormType("super")}
            >
              <i class="fa-solid fa-user-plus"></i> Super Admin
            </button>
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              clearSelectedRows={toggleClearRows} // Add this line
              selectableRowsHighlight
              highlightOnHover
              paginationPerPage={10} // Number of rows per page
              paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
            />
          )}
        </div>

        {/* Offcanvas for displaying selected rows */}
        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Selected Devices:{" "}
              <span className="badge rounded-pill text-bg-secondary text-white">
                {selectedRows.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row) => (
                <>
                  <li key={row.id}>{row.firstName}</li>
                </>
              ))}
            </ul>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="departments" className="form-label">
                  Departments
                </label>
                <Select
                  options={departments.map((dept) => ({
                    label: dept.departmentName,
                    value: dept._id,
                  }))}
                  onChange={handleDepartmentChange}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sections" className="form-label">
                  Sections
                </label>
                <Select
                  options={sections.map((section) => ({
                    label: section.sectionName,
                    value: section._id,
                  }))}
                  onChange={handleSectionChange}
                  isDisabled={!selectedDepartment}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subsections" className="form-label">
                  Subsections
                </label>
                <Select
                  options={subsections.map((subSection) => ({
                    label: subSection.subSectionName,
                    value: subSection._id,
                  }))}
                  onChange={(selectedOption) =>
                    setSelectedSubsection(selectedOption)
                  }
                  isDisabled={!selectedSection}
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Add User
                </h1>
                <button
                  id="closeModalButton"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form onSubmit={handleFormSubmit}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      aria-describedby="nameHelp"
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail1" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                    <div id="emailHelp" className="form-text">
                      We'll never share your email with anyone else.
                    </div>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>


      </div>
    </React.Fragment>
  );
}

export default Users;
