// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { Button } from "antd";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Excellogo from "../../../assets/img/Excellogo.png";
// import Select from "react-select";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import * as XLSX from "xlsx";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// import { useNavigate, NavLink } from "react-router-dom";

// function UserList() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [visibleColumns, setVisibleColumns] = useState({
//     hostname: true,
//     serialNumber: true,
//     ipAddress: true,
//     auditScore: true,
//     updatedAt: true,
//     departmentName: true,
//   });
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log(response, 'response');
//       const devices = response.data.data;
//       setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       selector: (row) => (row.status ? "Active" : "Inactive"),
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },

//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy
//           content={
//             row.departmentInfo ||
//             "NA"
//           }
//         >
//           <div>
//             {row.departmentInfo ||
//               "NA"}
//           </div>
//         </Tippy>
//       ),
//       width:'280px'
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <button  className="btn btn-outline-danger bg-btn-sucess" variant="primary">
//         Active
//       </button>
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (!newUserName || !newUserEmail) {
//       alert("Please enter a name and email.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-super-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log("API response:", response.data.message);
//       // Handle success response
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");

//       document.getElementById("closeModalButton").click(); // Close the modal
//         // Update DataTable data
//         fetchData();
//     } catch (error) {
//       toast.error(error);
//       console.error("Error adding user:", error);
//       // Handle error response
//     }
//   };
//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//        <ol className="breadcrumb fs-sm mb-1">
//           <li className="breadcrumb-item">
//             <NavLink exact to="/" activeClassName="active">
//               Admin
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to="/organizations" activeClassName="active">
//               Organizations
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
//               {id}
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}/users`} activeClassName="active">
//             Users
//             </NavLink>
//           </li>
//         </ol>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div class="col-xl">
//             <p class="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p class="text-danger p-0 m-0 fw-semibold">
//               {columnData.length} Users
//             </p>
//           </div>

//           &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//             <i class="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               selectableRows
//               onSelectedRowsChange={handleSelectedRowsChange}
//             //   clearSelectedRows={toggleClearRows} // Add this line
//               selectableRowsHighlight
//               highlightOnHover
//               paginationPerPage={10} // Number of rows per page
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         <div
//           class="modal fade"
//           id="exampleModal"
//           tabindex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div class="modal-dialog">
//             <div class="modal-content">
//               <div class="modal-header">
//                 <h1 class="modal-title fs-5" id="exampleModalLabel">
//                   Add User
//                 </h1>
//                 <button
//                 id="closeModalButton"
//                   type="button"
//                   class="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div class="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div class="mb-3">
//                     <label for="exampleInputName" class="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="exampleInputName"
//                       aria-describedby="nameHelp"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                     />
//                   </div>
//                   <div class="mb-3">
//                     <label for="exampleInputEmail1" class="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       class="form-control"
//                       id="exampleInputEmail1"
//                       aria-describedby="emailHelp"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                     />
//                     <div id="emailHelp" class="form-text">
//                       We'll never share your email with anyone else.
//                     </div>
//                   </div>

//                   <button type="submit" class="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//               {/* <div class="modal-footer">
//                 <button
//                   type="button"
//                   class="btn btn-secondary"
//                   data-bs-dismiss="modal"
//                   id="closeModalButton"
//                 >
//                   Close
//                 </button>
//                 <button type="button" class="btn btn-primary">
//                   Save changes
//                 </button>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default UserList;
// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { Button } from "antd";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Excellogo from "../../../assets/img/Excellogo.png";
// import Select from "react-select";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import * as XLSX from "xlsx";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// import { useNavigate, NavLink } from "react-router-dom";

// function UserList() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [visibleColumns, setVisibleColumns] = useState({
//     hostname: true,
//     serialNumber: true,
//     ipAddress: true,
//     auditScore: true,
//     updatedAt: true,
//     departmentName: true,
//   });
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log(response, 'response');
//       const devices = response.data.data;
//       setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const handleStatusChange = async (userId, isActive) => {

//     console.log(userId,isActive,'checking statusdwqaSDFA')
//     try {
//       const data = {
//         isActive: !isActive,
//         user_id: userId,
//         organizationId: id,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         toast.success("Status updated successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         fetchData(); // Refresh the data after successful update
//       } else {
//         toast.error("Failed to update status");
//       }
//     } catch (error) {
//       toast.error("Error updating status");
//       console.error("Error updating status:", error);
//     }
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//     //   selector: (row) => (row.status ? "Active" : "Inactive"),
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.departmentInfo || "NA"}>
//           <div>{row.departmentInfo || "NA"}</div>
//         </Tippy>
//       ),
//       width: '280px'
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <button
//           className="btn btn-outline-danger bg-btn-sucess"
//           onClick={() => handleStatusChange(row.id, row.status)}
//         >
//           {row.status ? "Deactivate" : "Activate"}
//         </button>
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (!newUserName || !newUserEmail) {
//       alert("Please enter a name and email.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log("API response:", response.data.message);
//       // Handle success response
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");

//       document.getElementById("closeModalButton").click(); // Close the modal
//       // Update DataTable data
//       fetchData();
//     } catch (error) {
//       toast.error(error);
//       console.error("Error adding user:", error);
//       // Handle error response
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <ol className="breadcrumb fs-sm mb-1">
//           <li className="breadcrumb-item">
//             <NavLink exact to="/" activeClassName="active">
//               Admin
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to="/organizations" activeClassName="active">
//               Organizations
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
//               {id}
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}/users`} activeClassName="active">
//               Users
//             </NavLink>
//           </li>
//         </ol>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div className="col-xl">
//             <p className="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p className="text-danger p-0 m-0 fw-semibold">
//               {columnData.length} Users
//             </p>
//           </div>

//           &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//             <i className="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               selectableRows
//               onSelectedRowsChange={handleSelectedRowsChange}
//               selectableRowsHighlight
//               highlightOnHover
//               paginationPerPage={10} // Number of rows per page
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         <div
//           className="modal fade"
//           id="exampleModal"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">
//                   Add User
//                 </h1>
//                 <button
//                   id="closeModalButton"
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputName" className="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputName"
//                       aria-describedby="nameHelp"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputEmail1" className="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="exampleInputEmail1"
//                       aria-describedby="emailHelp"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                     />
//                     <div id="emailHelp" className="form-text">
//                       We'll never share your email with anyone else.
//                     </div>
//                   </div>

//                   <button type="submit" className="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         <ToastContainer />
//       </div>
//     </React.Fragment>
//   );
// }

// export default UserList;

// import React, { useState, useEffect } from "react";
// import { useLocation, NavLink,useNavigate } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";

// function UserList() {
// //   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       const devices = response.data.data;
//       setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const handleStatusChange = async (userId, isActive) => {
//     try {
//       const data = {
//         isActive: !isActive,
//         user_id: userId,
//         organizationId: id,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         toast.success("Status updated successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         fetchData(); // Refresh the data after successful update
//       } else {
//         toast.error("Failed to update status");
//       }
//     } catch (error) {
//       toast.error("Error updating status");
//       console.error("Error updating status:", error);
//     }
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.departmentInfo || "NA"}>
//           <div>{row.departmentInfo || "NA"}</div>
//         </Tippy>
//       ),
//       width: "280px",
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <button
//           className={`btn ${row.status ? "btn-outline-danger" : "btn-outline-success"} bg-btn-${row.status ? "success" : "danger"}`}
//           onClick={() => handleStatusChange(row.id, row.status)}
//         >
//           {row.status ? "Deactivate" : "Activate"}
//         </button>
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (!newUserName || !newUserEmail) {
//       alert("Please enter a name and email.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");
//       document.getElementById("closeModalButton").click(); // Close the modal
//       // Update DataTable data
//       fetchData();
//     } catch (error) {
//       toast.error("Error adding user");
//       console.error("Error adding user:", error);
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <ol className="breadcrumb fs-sm mb-1">
//           <li className="breadcrumb-item">
//             <NavLink exact to="/" activeClassName="active">
//               Admin
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to="/organizations" activeClassName="active">
//               Organizations
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
//               {id}
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}/users`} activeClassName="active">
//               Users
//             </NavLink>
//           </li>
//         </ol>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div className="col-xl">
//             <p className="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p className="text-danger p-0 m-0 fw-semibold">
//               {columnData.length} Users
//             </p>
//           </div>

//           &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//             <i className="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               selectableRows
//               onSelectedRowsChange={handleSelectedRowsChange}
//               selectableRowsHighlight
//               highlightOnHover
//               paginationPerPage={10} // Number of rows per page
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         <div
//           className="modal fade"
//           id="exampleModal"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">
//                   Add User
//                 </h1>
//                 <button
//                   id="closeModalButton"
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputName" className="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputName"
//                       aria-describedby="nameHelp"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputEmail1" className="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="exampleInputEmail1"
//                       aria-describedby="emailHelp"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                     />
//                     <div id="emailHelp" className="form-text">
//                       We'll never share your email with anyone else.
//                     </div>
//                   </div>

//                   <button type="submit" className="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         <ToastContainer />
//       </div>
//     </React.Fragment>
//   );
// }

// export default UserList;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation, NavLink } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";
// import Switch from "react-switch";

// function UserList() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       const devices = response.data.data;
//       setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const handleStatusChange = async (userId, isActive) => {
//     try {
//       const data = {
//         isActive: !isActive,
//         user_id: userId,
//         organizationId: id,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         toast.success("Status updated successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         fetchData(); // Refresh the data after successful update
//       } else {
//         toast.error("Failed to update status");
//       }
//     } catch (error) {
//       toast.error("Error updating status");
//       console.error("Error updating status:", error);
//     }
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.departmentInfo || "NA"}>
//           <div>{row.departmentInfo || "NA"}</div>
//         </Tippy>
//       ),
//       width: "280px",
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <Switch
//           onChange={() => handleStatusChange(row.id, row.status)}
//           checked={row.status}
//           uncheckedIcon={false}
//           checkedIcon={false}
//           onColor="#00e676"
//           offColor="#ff1744"
//         />
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (!newUserName || !newUserEmail) {
//       alert("Please enter a name and email.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");
//       document.getElementById("closeModalButton").click(); // Close the modal
//       // Update DataTable data
//       fetchData();
//     } catch (error) {
//       toast.error("Error adding user");
//       console.error("Error adding user:", error);
//     }
//   };
// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation, NavLink } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";
// import Switch from "react-switch";

// function UserList() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [newUserOrganization, setNewUserOrganization] = useState("");
//   const [newUserOrganizationId, setNewUserOrganizationId] = useState("");
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       const devices = response.data.data;
//       setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const handleStatusChange = async (userId, isActive) => {
//     try {
//       const data = {
//         isActive: !isActive,
//         user_id: userId,
//         organizationId: id,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         toast.success("Status updated successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         fetchData(); // Refresh the data after successful update
//       } else {
//         toast.error("Failed to update status");
//       }
//     } catch (error) {
//       toast.error("Error updating status");
//       console.error("Error updating status:", error);
//     }
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.departmentInfo || "NA"}>
//           <div>{row.departmentInfo || "NA"}</div>
//         </Tippy>
//       ),
//       width: "280px",
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <Switch
//         //   onChange={() => handleStatusChange(row.id, row.status)}
//           checked={row.status}
//           uncheckedIcon={false}
//           checkedIcon={false}
//           onColor="#00e676"
//           offColor="#ff1744"
//         />
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (
//       !newUserName ||
//       !newUserEmail ||
//       !newUserOrganization
//     ) {
//       alert("Please enter all the required fields.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//       organization: newUserOrganization,
//       organizationId: id,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-super-admin/66add92658924eefc6f312b9`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");
//       setNewUserOrganization("");
//       setNewUserOrganizationId("");
//       document.getElementById("closeModalButton").click(); // Close the modal
//       // Update DataTable data
//       fetchData();
//     } catch (error) {
//       toast.error("Error adding user");
//       console.error("Error adding user:", error);
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <ol className="breadcrumb fs-sm mb-1">
//           <li className="breadcrumb-item">
//             <NavLink exact to="/" activeClassName="active">
//               Admin
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to="/organizations" activeClassName="active">
//               Organizations
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
//               {id}
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink
//               exact
//               to={`/org-detail/${id}/users`}
//               activeClassName="active"
//             >
//               Users
//             </NavLink>
//           </li>
//         </ol>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div className="col-xl">
//             <p className="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p className="text-danger p-0 m-0 fw-semibold">
//               {columnData.length} Users
//             </p>
//           </div>
//           &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//             <i className="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               onSelectedRowsChange={handleSelectedRowsChange}
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         <div
//           className="modal fade"
//           id="exampleModal"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">
//                   Add Super Admin
//                 </h1>
//                 <button
//                   id="closeModalButton"
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputName" className="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputName"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputEmail" className="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="exampleInputEmail"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="exampleInputOrganization"
//                       className="form-label"
//                     >
//                       Organization
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputOrganization"
//                       value={newUserOrganization}
//                       onChange={(e) => setNewUserOrganization(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <button type="submit" className="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         <ToastContainer />
//       </div>
//     </React.Fragment>
//   );
// }

// export default UserList;

// import React, { useState, useEffect } from "react";
// import { useNavigate, useLocation, NavLink } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css";
// import Switch from "react-switch";

// function UserList() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");
//   const [newUserOrganization, setNewUserOrganization] = useState("");
//   const [newUserOrganizationId, setNewUserOrganizationId] = useState("");
//   const [id, setId] = useState("");

//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");
//     const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
//     setId(idFromUrl);
//   }, []);

//   useEffect(() => {
//     if (id && user) {
//       fetchData();
//     }
//   }, [id, user]);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       const users = response.data.data;
//       setColumnData(users);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const handleStatusChange = async (userId, isActive) => {
//     try {
//       const data = {
//         isActive: !isActive,
//         user_id: userId,
//         organizationId: id,
//       };

//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );

//       if (response.data.success) {
//         toast.success("Status updated successfully", {
//           position: "top-center",
//           autoClose: 2000,
//           hideProgressBar: true,
//           closeOnClick: true,
//           pauseOnHover: true,
//           draggable: true,
//           progress: undefined,
//           theme: "light",
//         });
//         fetchData(); // Refresh the data after successful update
//       } else {
//         toast.error("Failed to update status");
//       }
//     } catch (error) {
//       toast.error("Error updating status");
//       console.error("Error updating status:", error);
//     }
//   };

//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
//           {row.status ? "Active" : "Inactive"}
//         </span>
//       ),
//     },
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.departmentInfo || "NA"}>
//           <div>{row.departmentInfo || "NA"}</div>
//         </Tippy>
//       ),
//       width: "280px",
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <Switch
//           onChange={() => handleStatusChange(row.id, row.status)}
//           checked={row.status}
//           uncheckedIcon={false}
//           checkedIcon={false}
//           onColor="#00e676"
//           offColor="#ff1744"
//         />
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (
//       !newUserName ||
//       !newUserEmail ||
//       !newUserOrganization
//     ) {
//       alert("Please enter all the required fields.");
//       return;
//     }

//     const data = {
//       fname: newUserName,
//       email: newUserEmail,
//       organization: newUserOrganization,
//       organizationId: id,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-super-admin/66add92658924eefc6f312b9`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");
//       setNewUserOrganization("");
//       setNewUserOrganizationId("");
//       document.getElementById("closeModalButton").click(); // Close the modal
//       // Update DataTable data
//       fetchData();
//     } catch (error) {
//       toast.error("Error adding user");
//       console.error("Error adding user:", error);
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <ol className="breadcrumb fs-sm mb-1">
//           <li className="breadcrumb-item">
//             <NavLink exact to="/" activeClassName="active">
//               Admin
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to="/organizations" activeClassName="active">
//               Organizations
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
//               {id}
//             </NavLink>
//           </li>
//           <li className="breadcrumb-item">
//             <NavLink
//               exact
//               to={`/org-detail/${id}/users`}
//               activeClassName="active"
//             >
//               Users
//             </NavLink>
//           </li>
//         </ol>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div className="col-xl">
//             <p className="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p className="text-danger p-0 m-0 fw-semibold">
//               {columnData.length} Users
//             </p>
//           </div>
//           &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//             <i className="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               onSelectedRowsChange={handleSelectedRowsChange}
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         <div
//           className="modal fade"
//           id="exampleModal"
//           tabIndex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div className="modal-dialog">
//             <div className="modal-content">
//               <div className="modal-header">
//                 <h1 className="modal-title fs-5" id="exampleModalLabel">
//                   Add Super Admin
//                 </h1>
//                 <button
//                   id="closeModalButton"
//                   type="button"
//                   className="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div className="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputName" className="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputName"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label htmlFor="exampleInputEmail" className="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       className="form-control"
//                       id="exampleInputEmail"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <div className="mb-3">
//                     <label
//                       htmlFor="exampleInputOrganization"
//                       className="form-label"
//                     >
//                       Organization
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="exampleInputOrganization"
//                       value={newUserOrganization}
//                       onChange={(e) => setNewUserOrganization(e.target.value)}
//                       required
//                     />
//                   </div>
//                   <button type="submit" className="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//         <ToastContainer />
//       </div>
//     </React.Fragment>
//   );
// }

// export default UserList;

import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import Switch from "react-switch";

function UserList() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);

  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserOrganization, setNewUserOrganization] = useState("");
  const [newUserOrganizationId, setNewUserOrganizationId] = useState("");
  const [id, setId] = useState("");

  const { user } = useSelector((state) => state.authSlice);

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");
    const idFromUrl = pathParts.length > 2 ? pathParts[2] : "";
    setId(idFromUrl);
  }, []);

  useEffect(() => {
    if (id && user) {
      fetchData();
    }
  }, [id, user]);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.userId}?organizationId=${id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      const users = response.data.data;
      // const users = response.data.data.map((user) => ({
      //   ...user,
      //   status: Boolean(user.status), // Ensure status is a boolean
      // }));
      setColumnData(users);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const handleStatusChange = async (userId, currentStatus) => {
    console.log("Current status:", currentStatus);

    // Convert the current status to a boolean
    const newStatus = !currentStatus;
    console.log("New status:", newStatus);

    try {
      const data = {
        isActive: newStatus,
        user_id: userId,
        organizationId: id,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/update-user-status/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log(response.data, "rrrrrrrrrr");
      if (response.data.status === true) {
        toast.success(response?.data?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        // Update the status locally without refetching the entire data
        setColumnData((prevData) => {
          if (!prevData) {
            console.error("Previous data is undefined");
            return [];
          }

          return prevData.map((user) =>
            user.id === userId ? { ...user, status: newStatus } : user
          );
        });
      } else {
        toast.error("Failed to update status");
      }
    } catch (error) {
      toast.error("Error updating status");
      console.error("Error updating status:", error);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      sortable: true,
      cell: (row) => (
        <span
          style={getStatusStyle(row.status === true ||  row.status === "true" ? "Active" : "Inactive")}
        >
          {row.status === true ||  row.status === "true" ? "Active" : "Inactive"}
        </span>
      ),
    },
    {
      name: "Added",
      selector: (row) => formatTimestamp(row.createdAt) || "NA",
      sortable: true,
    },
    {
      name: "Associated With",
      sortable: true,
      selector: (row) => (
        <Tippy content={row.departmentInfo || "NA"}>
          <div>{row.departmentInfo || "NA"}</div>
        </Tippy>
      ),
      width: "280px",
    },
    {
      name: "Action",
      cell: (row) => (
        <Switch
          onChange={() =>
            handleStatusChange(
              row.id,
              row.status === "true" || row.status === true
            )
          }
          checked={row.status === "true" || row.status === true} // Convert status to boolean
          uncheckedIcon={false}
          checkedIcon={false}
          onColor="#00e676"
          offColor="#ff1744"
        />
      ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    if (!newUserName || !newUserEmail || !newUserOrganization) {
      alert("Please enter all the required fields.");
      return;
    }

    const data = {
      fname: newUserName,
      email: newUserEmail,
      organization: newUserOrganization,
      organizationId: id,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/add-super-admin/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setNewUserName("");
      setNewUserEmail("");
      setNewUserOrganization("");
      setNewUserOrganizationId("");
      document.getElementById("closeModalButton").click(); // Close the modal
      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error("Error adding user");
      console.error("Error adding user:", error);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Admin
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/organizations" activeClassName="active">
              Organizations
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
              {id}
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink
              exact
              to={`/org-detail/${id}/users`}
              activeClassName="active"
            >
              Users
            </NavLink>
          </li>
        </ol>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div className="d-flex">
            <NavLink
              exact
              to={`/org-detail/${id}`}
              className="text-decoration-none me-3"
            >
              <i className="fa-solid fa-arrow-left-long"></i>
            </NavLink>
            <h5 className="main-title">Super Admins</h5>
          </div>
          <button
            type="button"
            className="btn btn-dark text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <i className="fa-solid fa-user-plus"></i> User
          </button>
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              customStyles={customStyles}
              onSelectedRowsChange={handleSelectedRowsChange}
              paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
            />
          )}
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">
                  Add Super Admin
                </h1>
                <button
                  id="closeModalButton"
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleAddUser}>
                  <div className="mb-3">
                    <label htmlFor="exampleInputName" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputName"
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="exampleInputEmail" className="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail"
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label
                      htmlFor="exampleInputOrganization"
                      className="form-label"
                    >
                      Organization
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="exampleInputOrganization"
                      value={newUserOrganization}
                      onChange={(e) => setNewUserOrganization(e.target.value)}
                      required
                    />
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
    </React.Fragment>
  );
}

export default UserList;
