import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import Footer from "../../layouts/Footer";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { dp3 } from "../../data/DashboardData";
import { useSelector } from "react-redux";


import axios from "axios";

function Maindashboard() {
  const { user } = useSelector((state) => state.authSlice);
console.log(user.data,'________')
    const dataPolar = {
        datasets: [
          {
            data: [50, 25, 60, 80, 20],
            backgroundColor: [
              "#506fd9",
              "#85b6ff",
              "#d3dbf6",
              "#6e7985",
              "#dbdde1",
            ],
          },
        ],
      };
    
      const optionPolar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
          legend: {
            display: true,
          },
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      };
    
      const seriesOne = [
        {
          name: "series1",
          data: dp3,
        },
        {
          name: "series2",
          data: dp3,
        },
      ];
    
      const optionOne = {
        chart: {
          parentHeightOffset: 0,
          type: "area",
          toolbar: { show: false },
          stacked: true,
        },
        colors: ["#4f6fd9", "#506fd9"],
        grid: {
          borderColor: "rgba(72,94,144, 0.08)",
          padding: { top: -20 },
          yaxis: {
            lines: { show: false },
          },
        },
        stroke: {
          curve: "straight",
          width: [2, 0],
        },
        xaxis: {
          type: "numeric",
          tickAmount: 13,
          axisBorder: { show: false },
          labels: {
            style: {
              colors: "#6e7985",
              fontSize: "11px",
            },
          },
        },
        yaxis: {
          min: 0,
          max: 100,
          show: false,
        },
        fill: {
          type: "gradient",
          gradient: {
            opacityFrom: 0.5,
            opacityTo: 0,
          },
        },
        dataLabels: { enabled: false },
        legend: { show: false },
        tooltip: { enabled: false },
      };
    
      const seriesEight = [
        {
          type: "column",
          data: [
            [0, 2],
            [1, 3],
            [2, 5],
            [3, 7],
            [4, 12],
            [5, 17],
            [6, 10],
            [7, 14],
            [8, 15],
            [9, 12],
            [10, 8],
          ],
        },
        {
          type: "column",
          data: [
            [0, 12],
            [1, 7],
            [2, 4],
            [3, 5],
            [4, 8],
            [5, 10],
            [6, 4],
            [7, 7],
            [8, 11],
            [9, 9],
            [10, 5],
          ],
        },
      ];
    
      const optionEight = {
        chart: {
          parentHeightOffset: 0,
          stacked: true,
          type: "line",
          toolbar: { show: false },
        },
        grid: {
          borderColor: "rgba(72,94,144, 0.07)",
          padding: {
            top: -20,
            left: 5,
            bottom: -15,
          },
        },
        states: {
          hover: {
            filter: { type: "none" },
          },
          active: {
            filter: { type: "none" },
          },
        },
        colors: ["#506fd9", "#e5e9f2"],
        plotOptions: {
          bar: {
            columnWidth: "40%",
            endingShape: "rounded",
          },
        },
        stroke: {
          curve: "straight",
          lineCap: "square",
          width: 0,
        },
        yaxis: {
          min: 0,
          max: 30,
          tickAmount: 5,
        },
        xaxis: {
          labels: {
            style: {
              colors: "#6e7985",
              fontSize: "10px",
              fontWeight: "500",
            },
          },
        },
        tooltip: { enabled: false },
        legend: { show: false },
      };
    
      const optionDonut = {
        chart: {
          parentHeightOffset: 0,
        },
        colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
        dataLabels: { enabled: true },
        grid: {
          padding: {
            top: 0,
            bottom: 0,
          },
        },
        
        legend: { show: false },
      };
    
    
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [licenseDate, setLicenseDate] = useState("");
  const [id, setId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [newValidToDate, setNewValidToDate] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleDateChange = (e) => {
    const date = e.target.value;
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    setNewValidToDate(formattedDate);
  };
  console.log(newValidToDate, "newValidToDate");
  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");

    if (pathParts.length >= 6) {
      const id = pathParts[2];
      const licenseDate = pathParts[4];
      const validtofrom = pathParts[5];
      console.log(id, licenseDate, validtofrom, "rrrrrrrrrrrrrr");
      const [from, to] = validtofrom.split("to");
      setId(id);
      setLicenseDate(licenseDate);
      setValidFrom(from);
      setValidTo(to);
    } else {
      console.error("URL does not match the expected format.");
    }
  }, [location]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (id && validFrom && validTo) {
  //       try {
  //         const response = await axios.get(
  //           `${process.env.REACT_APP_NOTIFY_URL}/${id}/license-details?validFrom=${validFrom}&validTo=${validTo}`
  //         );
  //         console.log([response?.data?.licenses], "ttttttttttttttttttt");
  //         setColumnData([response?.data?.licenses]);
  //         setLoading(false);
  //       } catch (error) {
  //         console.error("Error fetching data: ", error);
  //         setLoading(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [id, validFrom, validTo]);

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const exportRowToCSV = (rowData, filename) => {
    const csvContent = [
      ["Date", "Quantity", "Validity", "Device ID", "Status"],
      [
        rowData.date,
        rowData.quantity,
        rowData.validity,
        rowData.deviceid,
        rowData.status,
      ],
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportToCSV = (rows, filename) => {
    console.log(rows, "eeeeeeee");
    const csvContent = [
      ["License No", "Valid From", "Valid Upto", "Device ID", "Status"],
      ...rows.map((row) => [
        row.licKey,
        row.validFrom,
        row.validTo,
        row.pcIdAssociated,
        row.lkeyStatus,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      name: "License No",
      selector: (row) => row.licKey,
      sortable: false,
      width: "320px",
    },
    {
      name: "Valid From",
      selector: (row) => row.validFrom,
      sortable: true,
    },
    {
      name: "Valid Upto",
      selector: (row) => row.validTo,
      sortable: true,
    },
    {
      name: "Device ID",
      selector: (row) => (row.pcIdAssociated ? row.pcIdAssociated : "NA"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.lkeyStatus ? row?.lkeyStatus : "NA"),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    console.log(selectedRows, "selectedRows");
    setSelectedRows(selectedRows);
  };

  const handleUpdateClick = async () => {
    const licenseKeys = selectedRows.map((row) => row.licKey);
    const newValidTo = newValidToDate; // You might want to format this date to match the required format "09-09-2026"
    const requestBody = {
      licenseKeys,
      newValidTo,
      organizationId: id,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_NOTIFY_URL}/extend-license-expiry`,
        requestBody
      );
      console.log("Response:", response.status == true);
      setSuccessMessage("License generated successfully!");
      setTimeout(() => {
        setSuccessMessage("");
        setSelectedRows([]);

        // Refresh data or update state to reflect new license
        // Example: fetch updated data or update state
      }, 2000);
    } catch (error) {
      console.error("Error updating license expiry: ", error);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div class="card rounded-4 shadow  p-3  bg-body-tertiary">
          <div class="card-body">
            <h5 class="card-title text-center fw-bolder">Basic Details</h5>
            <hr />

            <div class="container text-center">
              <div class="row">
                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom shadow p-3">
                      <h5 class="card-title">ID</h5>
                      <p class="card-text">{user?.data?.user?.organizationId}</p>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom shadow p-3">
                      <h5 class="card-title">Admin Name</h5>
                      <p class="card-text">{user?.data?.user?.firstName} {user?.data?.user?.lastName}</p>
                    </div>
                  </div>
                </div>



                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom shadow p-3">
                      <h5 class="card-title">Org. Name</h5>
                      <p class="card-text">{user?.data?.user?.organization}</p>
                    </div>
                  </div>
                </div>
               

                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom shadow p-3">
                      <h5 class="card-title">Admin Email</h5>
                      <p class="card-text">{user?.data?.user?.email}</p>
                    </div>
                  </div>
                </div> 

                {/* <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom">
                      <h5 class="card-title">Status</h5>
                      <p class="card-text text-success">{user?.data?.user?.email}</p>
                    </div>
                  </div>
                </div>  */}
{/* 
                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom">
                      <h5 class="card-title">Type</h5>
                      <p class="card-text ">Piad</p>
                    </div>
                  </div>
                </div>  */}

                <div class="col">
                  <div class="card border border-0">
                    <div class="card-body border-bottom shadow p-3">
                      <h5 class="card-title">Registered</h5>
                      <p class="card-text ">{formatTimestamp(user?.data?.user?.createdAt)}</p>
                    </div>
                  </div>
                </div> 


              </div>
            </div>

      
          </div>
        
        </div>
        {/* <div class="card rounded-4 shadow  p-3  bg-body-tertiary mt-4">
            
            <Row className="g-3">
            <Col xl="8">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Department Cyber Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="g-4">
                    <Col md="12">
                      <ReactApexChart
                        series={seriesEight}
                        options={optionEight}
                        height={210}
                        className="apex-chart-three"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Total Devices</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="position-relative d-flex justify-content-center">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionDonut}
                    height="auto"
                    type="donut"
                    className="apex-donut-two"
                  />

                  <div className="finance-donut-value">
                    <h1>ssdgsdgsd</h1>
                    <p>86.24%</p>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            </Row>
         
          </div> */}
      </div>
    </React.Fragment>
  );
}

export default Maindashboard;
