// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Offcanvas from "react-bootstrap/Offcanvas"; // Import Offcanvas component
// // import Excellogo from '../../assets/img/Excellogo.png';

// // function Test() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [selectAll, setSelectAll] = useState(false);
// //   const [showOffcanvas, setShowOffcanvas] = useState(false); // State to control Offcanvas visibility
// //   const [currentPage, setCurrentPage] = useState(1);
// //   const itemsPerPage = 10;

// //   const { user } = useSelector((state) => state.authSlice);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //           {
// //             headers: {
// //               Authorization: `Bearer ${user?.data?.accessToken}`,
// //             },
// //           }
// //         );
        
// //         // Extracting devices data
// //         const devices = response.data.devices;
// //         console.log(devices, "Devices"); // Debug devices

// //         setColumnData(devices); // Setting devices data to state
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data: ", error.response); // Debug error response
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [user]);

// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   switchSkin(skin);
// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const handleRowSelection = (device) => {
// //     const isSelected = selectedRows.some(row => row.id === device.id);
// //     const newSelectedRows = isSelected
// //       ? selectedRows.filter(row => row.id !== device.id)
// //       : [...selectedRows, device];

// //     setSelectedRows(newSelectedRows);
// //     if (selectAll) {
// //       setSelectAll(false);
// //     }
// //   };

// //   const handleSelectAll = () => {
// //     if (selectAll) {
// //       setSelectedRows([]);
// //     } else {
// //       setSelectedRows(columnData);
// //     }
// //     setSelectAll(!selectAll);
// //   };

// //   const handleControlClick = () => {
// //     setShowOffcanvas(true);
// //   };

// //   // Get current items
// //   const indexOfLastItem = currentPage * itemsPerPage;
// //   const indexOfFirstItem = indexOfLastItem - itemsPerPage;
// //   const currentItems = columnData.slice(indexOfFirstItem, indexOfLastItem);

// //   const pageNumbers = [];
// //   for (let i = 1; i <= Math.ceil(columnData.length / itemsPerPage); i++) {
// //     pageNumbers.push(i);
// //   }

// //   const paginate = (pageNumber) => setCurrentPage(pageNumber);

// //   const allSelected = selectAll || (selectedRows.length > 0 && selectedRows.length === columnData.length);

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               <i className="fa-solid fa-file-pdf"></i> &nbsp;
// //               Export 
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //               <img src={Excellogo} height={20} width={15} alt="Excel logo"/>&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               Manage label
// //             </button>
// //             <button 
// //               type="button" 
// //               className="btn btn-info text-white" 
// //               onClick={handleControlClick}
// //               disabled={selectedRows.length === 0} // Disable button if no rows selected
// //             >
// //               Control  
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //             <i className="fa-solid fa-eye"></i> Table View 
// //           </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <>
// //               <table className="table table-striped table-bordered">
// //                 <thead>
// //                   <tr>
// //                     <th>
// //                       <input
// //                         type="checkbox"
// //                         checked={allSelected}
// //                         onChange={handleSelectAll}
// //                       />
// //                     </th>
// //                     <th>Hostname</th>
// //                     <th>Serial Number</th>
// //                     <th>IP Address</th>
// //                     <th>Client Score</th>
// //                     <th>Sync Time</th>
// //                     <th>Action</th>
// //                   </tr>
// //                 </thead>
// //                 <tbody>
// //                   {currentItems.map((device) => (
// //                     <tr key={device.id}>
// //                       <td>
// //                         <input
// //                           type="checkbox"
// //                           checked={selectedRows.some(row => row.id === device.id)}
// //                           onChange={() => handleRowSelection(device)}
// //                         />
// //                       </td>
// //                       <td>{device.hostname}</td>
// //                       <td>{device.serialNumber}</td>
// //                       <td>{device.ipAddress || "NA"}</td>
// //                       <td>{device.auditScore || "NA"}</td>
// //                       <td>{formatTimestamp(device.updatedAt) || "NA"}</td>
// //                       <td>
// //                         <Button
// //                           // onClick={() => handleActionClick(device)}
// //                           variant="primary"
// //                         >
// //                           Action
// //                         </Button>
// //                       </td>
// //                     </tr>
// //                   ))}
// //                 </tbody>
// //               </table>
// //               <nav>
// //                 <ul className="pagination">
// //                   {pageNumbers.map((number) => (
// //                     <li key={number} className="page-item">
// //                       <a onClick={() => paginate(number)} href="#!" className="page-link">
// //                         {number}
// //                       </a>
// //                     </li>
// //                   ))}
// //                 </ul>
// //               </nav>
// //             </>
// //           )}
// //         </div>

// //         {/* Offcanvas for displaying selected rows */}
// //         <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
// //           <Offcanvas.Header closeButton>
// //             <Offcanvas.Title>Selected Devices</Offcanvas.Title>
// //           </Offcanvas.Header>
// //           <Offcanvas.Body>
// //             <p>Selected Count: {selectedRows.length}</p>
// //             <ul>
// //               {selectedRows.map((row) => (
// //                 <li key={row.id}>{row.hostname}</li>
// //               ))}
// //             </ul>
// //           </Offcanvas.Body>
// //         </Offcanvas>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default Test



// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import DataTable from "react-data-table-component";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Excellogo from '../../assets/img/Excellogo.png'
// // function Test() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [newValidToDate, setNewValidToDate] = useState("");
// //   const [successMessage, setSuccessMessage] = useState("");

// //   const { user } = useSelector((state) => state.authSlice);

// //   const handleDateChange = (e) => {
// //     const date = e.target.value;
// //     const [year, month, day] = date.split("-");
// //     const formattedDate = `${day}-${month}-${year}`;
// //     setNewValidToDate(formattedDate);
// //   };

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //           {
// //             headers: {
// //               Authorization: `Bearer ${user?.data?.accessToken}`,
// //             },
// //           }
// //         );

// //         // Extracting devices data
// //         const devices = response.data.devices;
// //         console.log(devices, "Devices"); // Debug devices

// //         setColumnData(devices); // Setting devices data to state
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data: ", error.response); // Debug error response
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [user]);

// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   switchSkin(skin);
// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };
// //   const columns = [
// //     {
// //       name: "Hostname",
// //       selector: (row) => row.hostname,
// //       sortable: true,
// //     },
// //     {
// //       name: "Serial Number",
// //       selector: (row) => row.serialNumber,
// //       sortable: true,
// //     },
// //     {
// //       name: "IP Address",
// //       selector: (row) => row.ipAddress || "NA", // Handle empty IP addresses
// //       sortable: true,
// //     },
// //     {
// //       name: "Client Score",
// //       selector: (row) => row.auditScore || "NA", // Handle missing audit scores
// //       sortable: true,
// //     },
// //     {
// //       name: "Sync Time",
// //       selector: (row) => formatTimestamp(row.updatedAt) || "NA", // Handle missing audit scores
// //       sortable: true,
// //     },
// //     {
// //       name: "Action",
// //       cell: (row) => (
// //         <Button
// //           onClick={() => handleActionClick(row)}
// //           variant="primary"
// //         >
// //           Action
// //         </Button>
// //       ),
// //       sortable: false,
// //     },
// //   ];

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };

// //   const handleSelectedRowsChange = ({ selectedRows }) => {
// //     console.log(selectedRows, "selectedRows");
// //     setSelectedRows(selectedRows);
// //   };

// //   const handleActionClick = (row) => {
// //     // Handle action click logic
// //     console.log("Action clicked for row:", row);
// //   };

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               {/* <i className="fa-solid fa-plus me-2" style={{ color: "white" }} /> */}

// //               <i class="fa-solid fa-file-pdf" ></i> &nbsp;
// //               Export
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //             <img src={Excellogo} height={20} width={15} />&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //         <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //              Manage label
// //             </button>
// //             <button type="button" className="btn btn-info text-white">
// //              Control
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //           <i class="fa-solid fa-eye"></i>   Table View
// //             </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <DataTable
// //               columns={columns}
// //               data={columnData}
// //               pagination
// //               customStyles={customStyles}
// //               selectableRows
// //               onSelectedRowsChange={handleSelectedRowsChange}
// //             />
// //           )}
// //         </div>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default Test;

// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import DataTable from "react-data-table-component";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Offcanvas from "react-bootstrap/Offcanvas";
// // import Excellogo from '../../assets/img/Excellogo.png';

// // function AllEndPoint() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [showOffcanvas, setShowOffcanvas] = useState(false);

// //   const { user } = useSelector((state) => state.authSlice);

// //   const handleDateChange = (e) => {
// //     const date = e.target.value;
// //     const [year, month, day] = date.split("-");
// //     const formattedDate = `${day}-${month}-${year}`;
// //     // setNewValidToDate(formattedDate);
// //   };

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //           {
// //             headers: {
// //               Authorization: `Bearer ${user?.data?.accessToken}`,
// //             },
// //           }
// //         );

// //         const devices = response.data.devices;
// //         setColumnData(devices);
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data: ", error.response);
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [user]);

// //   useEffect(() => {
// //     const fetchOrganizationData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `https://dev.cyberauditor.in/api/v1/organization/all-department/${user?.data?.user?.organizationId}`
// //         );
// //         // setOrganization(response.data);

// //         console.log(response.data,'department')
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching organization data: ", error);
// //         setLoading(false);
// //       }
// //     };

// //     fetchOrganizationData();
// //   }, [user?.data?.user?.organizationId]);



// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");

// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const columns = [
// //     {
// //       name: "Hostname",
// //       selector: (row) => row.hostname,
// //       sortable: true,
// //     },
// //     {
// //       name: "Serial Number",
// //       selector: (row) => row.serialNumber,
// //       sortable: true,
// //     },
// //     {
// //       name: "IP Address",
// //       selector: (row) => row.ipAddress || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Client Score",
// //       selector: (row) => row.auditScore || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Sync Time",
// //       selector: (row) => formatTimestamp(row.updatedAt) || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Action",
// //       cell: (row) => (
// //         <Button
// //           onClick={() => handleActionClick(row)}
// //           variant="primary"
// //         >
// //           Action
// //         </Button>
// //       ),
// //       sortable: false,
// //     },
// //   ];

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };

// //   const handleSelectedRowsChange = ({ selectedRows }) => {
// //     setSelectedRows(selectedRows);
// //   };

// //   const handleActionClick = (row) => {
// //     console.log("Action clicked for row:", row);
// //   };

// //   const handleControlClick = () => {
// //     setShowOffcanvas(true);
// //   };

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //               <img src={Excellogo} height={20} width={15} />&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               Manage label
// //             </button>
// //             <button 
// //               type="button" 
// //               className="btn btn-info text-white" 
// //               onClick={handleControlClick}
// //               disabled={selectedRows.length === 0}
// //             >
// //               Control
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //             <i className="fa-solid fa-eye"></i> Table View
// //           </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <DataTable
// //               columns={columns}
// //               data={columnData}
// //               pagination
// //               customStyles={customStyles}
// //               selectableRows
// //               onSelectedRowsChange={handleSelectedRowsChange}
// //               selectableRowsHighlight
// //               highlightOnHover
// //               paginationPerPage={10} // Number of rows per page
// //               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
// //             />
// //           )}
// //         </div>

// //         {/* Offcanvas for displaying selected rows */}
// //         <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
// //           <Offcanvas.Header closeButton>
// //             <Offcanvas.Title>Selected Devices: <span class="badge rounded-pill text-bg-secondary text-white">{selectedRows.length}</span> 
// //             {/* <span class="badge text-bg-secondary">{selectedRows.length}</span>  */}
// //             </Offcanvas.Title>
// //           </Offcanvas.Header>
// //           <Offcanvas.Body>
  
// //             <ul>
// //               {selectedRows.map((row) => (
// //                 <li key={row.id}>{row.hostname}</li>
// //               ))}
// //             </ul>

// //             <form>
// //   <div class="mb-3">
// //     <label for="exampleInputEmail1" class="form-label">Departments</label>
// //     {/* <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/> */} 
// //     {/* drop downdown will come for Departments */}
// //   </div>
// //   <div class="mb-3">
// //     <label for="exampleInputEmail1" class="form-label">Sections</label>
// //     {/* <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/> */}
// //         {/* drop downdown will come for Sections */}

// //   </div>
// //   <div class="mb-3">
// //     <label for="exampleInputEmail1" class="form-label">Subsections</label>
// //     {/* <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"/> */}
// //      {/* drop downdown will come for Subsections */}

// //   </div>
// //   <button type="submit" class="btn btn-primary">Submit</button>
// // </form>
// //           </Offcanvas.Body>
// //         </Offcanvas>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default AllEndPoint;



// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import DataTable from "react-data-table-component";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Offcanvas from "react-bootstrap/Offcanvas";
// // import Excellogo from '../../assets/img/Excellogo.png';
// // import Select from "react-select";
// // import AsyncSelect from "react-select/async";

// // function AllEndPoint() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [showOffcanvas, setShowOffcanvas] = useState(false);
// //   const [departments, setDepartments] = useState([]);
// //   const [sections, setSections] = useState([]);
// //   const [subsections, setSubsections] = useState([]);

// //   const { user } = useSelector((state) => state.authSlice);

// //   const handleDateChange = (e) => {
// //     const date = e.target.value;
// //     const [year, month, day] = date.split("-");
// //     const formattedDate = `${day}-${month}-${year}`;
// //     // setNewValidToDate(formattedDate);
// //   };

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //           {
// //             headers: {
// //               Authorization: `Bearer ${user?.data?.accessToken}`,
// //             },
// //           }
// //         );

// //         const devices = response.data.devices;
// //         setColumnData(devices);
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data: ", error.response);
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [user]);

// //   useEffect(() => {
// //     const fetchOrganizationData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `https://dev.cyberauditor.in/api/v1/organization/all-department/${user?.data?.user?.organizationId}`
// //         );
// //         setDepartments(response.data.departments);
// //         console.log(response.data.departments,'qqqqqqqqqqqq')
// //       } catch (error) {
// //         console.error("Error fetching organization data: ", error);
// //       }
// //     };

// //     fetchOrganizationData();
// //   }, [user?.data?.user?.organizationId]);

// //   const fetchSections = async (departmentId) => {
// //     try {
// //       const response = await axios.get(
// //         `https://dev.cyberauditor.in/api/v1/organization/sections/${departmentId}`
// //       );
// //       setSections(response.data.sections);
// //     } catch (error) {
// //       console.error("Error fetching sections: ", error);
// //     }
// //   };

// //   const fetchSubsections = async (sectionId) => {
// //     try {
// //       const response = await axios.get(
// //         `https://dev.cyberauditor.in/api/v1/organization/subsections/${sectionId}`
// //       );
// //       setSubsections(response.data.subsections);
// //     } catch (error) {
// //       console.error("Error fetching subsections: ", error);
// //     }
// //   };

// //   const loadDepartments = async () => {
// //     return departments.map(dept => ({
// //       label: dept.departmentName,
// //       value: dept._id
// //     }));
// //   };

// //   const loadSections = async (departmentId) => {
// //     return sections.filter(section => section.departmentId === departmentId).map(section => ({
// //       label: section.name,
// //       value: section.id
// //     }));
// //   };

// //   const loadSubsections = async (sectionId) => {
// //     return subsections.filter(subsection => subsection.sectionId === sectionId).map(subsection => ({
// //       label: subsection.name,
// //       value: subsection.id
// //     }));
// //   };

// //   const switchSkin = (skin) => {
// //     if (skin === "dark") {
// //       const btnWhite = document.getElementsByClassName("btn-white");
// //       for (const btn of btnWhite) {
// //         btn.classList.add("btn-outline-primary");
// //         btn.classList.remove("btn-white");
// //       }
// //     } else {
// //       const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");
// //       for (const btn of btnOutlinePrimary) {
// //         btn.classList.remove("btn-outline-primary");
// //         btn.classList.add("btn-white");
// //       }
// //     }
// //   };

// //   useEffect(() => {
// //     switchSkin(skin);
// //   }, [skin]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const columns = [
// //     {
// //       name: "Hostname",
// //       selector: (row) => row.hostname,
// //       sortable: true,
// //     },
// //     {
// //       name: "Serial Number",
// //       selector: (row) => row.serialNumber,
// //       sortable: true,
// //     },
// //     {
// //       name: "IP Address",
// //       selector: (row) => row.ipAddress || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Client Score",
// //       selector: (row) => row.auditScore || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Sync Time",
// //       selector: (row) => formatTimestamp(row.updatedAt) || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Action",
// //       cell: (row) => (
// //         <Button
// //           onClick={() => handleActionClick(row)}
// //           variant="primary"
// //         >
// //           Action
// //         </Button>
// //       ),
// //       sortable: false,
// //     },
// //   ];

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };

// //   const handleSelectedRowsChange = ({ selectedRows }) => {
// //     setSelectedRows(selectedRows);
// //   };

// //   const handleActionClick = (row) => {
// //     console.log("Action clicked for row:", row);
// //   };

// //   const handleControlClick = () => {
// //     setShowOffcanvas(true);
// //   };

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //               <img src={Excellogo} height={20} width={15} />&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               Manage label
// //             </button>
// //             <button 
// //               type="button" 
// //               className="btn btn-info text-white" 
// //               onClick={handleControlClick}
// //               disabled={selectedRows.length === 0}
// //             >
// //               Control
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //             <i className="fa-solid fa-eye"></i> Table View
// //           </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <DataTable
// //               columns={columns}
// //               data={columnData}
// //               pagination
// //               customStyles={customStyles}
// //               selectableRows
// //               onSelectedRowsChange={handleSelectedRowsChange}
// //               selectableRowsHighlight
// //               highlightOnHover
// //               paginationPerPage={10} // Number of rows per page
// //               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
// //             />
// //           )}
// //         </div>

// //         {/* Offcanvas for displaying selected rows */}
// //         <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
// //           <Offcanvas.Header closeButton>
// //             <Offcanvas.Title>Selected Devices: <span className="badge rounded-pill text-bg-secondary text-white">{selectedRows.length}</span> 
// //             {/* <span className="badge text-bg-secondary">{selectedRows.length}</span>  */}
// //             </Offcanvas.Title>
// //           </Offcanvas.Header>
// //           <Offcanvas.Body>
// //             <ul>
// //               {selectedRows.map((row) => (
// //                 <li key={row.id}>{row.hostname}</li>
// //               ))}
// //             </ul>

// //             <form>
// //               <div className="mb-3">
// //                 <label htmlFor="departments" className="form-label">Departments</label>
// //                 <AsyncSelect
// //                   loadOptions={loadDepartments}
// //                   onChange={(selectedOption) => fetchSections(selectedOption.value)}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="sections" className="form-label">Sections</label>
// //                 <AsyncSelect
// //                   loadOptions={(inputValue) => loadSections(inputValue)}
// //                   onChange={(selectedOption) => fetchSubsections(selectedOption.value)}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="subsections" className="form-label">Subsections</label>
// //                 <AsyncSelect
// //                   loadOptions={(inputValue) => loadSubsections(inputValue)}
// //                 />
// //               </div>
// //               <button type="submit" className="btn btn-primary">Submit</button>
// //             </form>
// //           </Offcanvas.Body>
// //         </Offcanvas>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default AllEndPoint;

// // working code down once
// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import DataTable from "react-data-table-component";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Offcanvas from "react-bootstrap/Offcanvas";
// // import Excellogo from '../../assets/img/Excellogo.png';
// // import Select from "react-select";
// // import AsyncSelect from "react-select/async";

// // function AllEndPoint() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [showOffcanvas, setShowOffcanvas] = useState(false);
// //   const [departments, setDepartments] = useState([]);
// //   const [selectedDepartment, setSelectedDepartment] = useState(null);
// //   const [selectedSection, setSelectedSection] = useState(null);
// //   const [selectedSubsection, setSelectedSubsection] = useState(null);

// //   const { user } = useSelector((state) => state.authSlice);

// //   useEffect(() => {
// //     const fetchData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //           {
// //             headers: {
// //               Authorization: `Bearer ${user?.data?.accessToken}`,
// //             },
// //           }
// //         );

// //         const devices = response.data.devices;
// //         setColumnData(devices);
// //         setLoading(false);
// //       } catch (error) {
// //         console.error("Error fetching data: ", error.response);
// //         setLoading(false);
// //       }
// //     };

// //     fetchData();
// //   }, [user]);

// //   useEffect(() => {
// //     const fetchOrganizationData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `https://dev.cyberauditor.in/api/v1/organization/all-department/${user?.data?.user?.organizationId}`
// //         );
// //         setDepartments(response.data.departments);
// //       } catch (error) {
// //         console.error("Error fetching organization data: ", error);
// //       }
// //     };

// //     fetchOrganizationData();
// //   }, [user?.data?.user?.organizationId]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const columns = [
// //     {
// //       name: "Hostname",
// //       selector: (row) => row.hostname,
// //       sortable: true,
// //     },
// //     {
// //       name: "Serial Number",
// //       selector: (row) => row.serialNumber,
// //       sortable: true,
// //     },
// //     {
// //       name: "IP Address",
// //       selector: (row) => row.ipAddress || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Client Score",
// //       selector: (row) => row.auditScore || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Sync Time",
// //       selector: (row) => formatTimestamp(row.updatedAt) || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Action",
// //       cell: (row) => (
// //         <Button onClick={() => handleActionClick(row)} variant="primary">
// //           Action
// //         </Button>
// //       ),
// //       sortable: false,
// //     },
// //   ];

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };

// //   const handleSelectedRowsChange = ({ selectedRows }) => {
// //     setSelectedRows(selectedRows);
// //   };

// //   const handleActionClick = (row) => {
// //     console.log("Action clicked for row:", row);
// //   };

// //   const handleControlClick = () => {
// //     setShowOffcanvas(true);
// //   };

// //   const loadDepartments = async (inputValue) => {
// //     return departments.map((dept) => ({
// //       label: dept.departmentName,
// //       value: dept._id,
// //     }));
// //   };

// //   const loadSections = async (inputValue) => {
// //     if (!selectedDepartment) return [];
// //     const department = departments.find((dept) => dept._id === selectedDepartment.value);
// //     return department.sections.map((section) => ({
// //       label: section.sectionName,
// //       value: section._id,
// //     }));
// //   };

// //   const loadSubsections = async (inputValue) => {
// //     if (!selectedSection) return [];
// //     const department = departments.find((dept) => dept._id === selectedDepartment.value);
// //     const section = department.sections.find((sec) => sec._id === selectedSection.value);
// //     return section.subSections.map((subSection) => ({
// //       label: subSection.subSectionName,
// //       value: subSection._id,
// //     }));
// //   };

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //               <img src={Excellogo} height={20} width={15} />&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               Manage label
// //             </button>
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               onClick={handleControlClick}
// //               disabled={selectedRows.length === 0}
// //             >
// //               Control
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //             <i className="fa-solid fa-eye"></i> Table View
// //           </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <DataTable
// //               columns={columns}
// //               data={columnData}
// //               pagination
// //               customStyles={customStyles}
// //               selectableRows
// //               onSelectedRowsChange={handleSelectedRowsChange}
// //               selectableRowsHighlight
// //               highlightOnHover
// //               paginationPerPage={10} // Number of rows per page
// //               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
// //             />
// //           )}
// //         </div>

// //         {/* Offcanvas for displaying selected rows */}
// //         <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
// //           <Offcanvas.Header closeButton>
// //             <Offcanvas.Title>
// //               Selected Devices: <span className="badge rounded-pill text-bg-secondary text-white">{selectedRows.length}</span>
// //             </Offcanvas.Title>
// //           </Offcanvas.Header>
// //           <Offcanvas.Body>
// //             <ul>
// //               {selectedRows.map((row) => (
// //                 <li key={row.id}>{row.hostname}</li>
// //               ))}
// //             </ul>

// //             <form>
// //               <div className="mb-3">
// //                 <label htmlFor="departments" className="form-label">Departments</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadDepartments}
// //                   defaultOptions
// //                   onChange={(selectedOption) => {
// //                     setSelectedDepartment(selectedOption);
// //                     setSelectedSection(null);
// //                     setSelectedSubsection(null);
// //                   }}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="sections" className="form-label">Sections</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadSections}
// //                   defaultOptions
// //                   isDisabled={!selectedDepartment}
// //                   onChange={(selectedOption) => {
// //                     setSelectedSection(selectedOption);
// //                     setSelectedSubsection(null);
// //                   }}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="subsections" className="form-label">Subsections</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadSubsections}
// //                   defaultOptions
// //                   isDisabled={!selectedSection}
// //                   onChange={(selectedOption) => setSelectedSubsection(selectedOption)}
// //                 />
// //               </div>
// //               <button type="submit" className="btn btn-primary">Submit</button>
// //             </form>
// //           </Offcanvas.Body>
// //         </Offcanvas>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default AllEndPoint;


// // import React, { useState, useEffect } from "react";
// // import { useLocation } from "react-router-dom";
// // import Header from "../../layouts/Header";
// // import DataTable from "react-data-table-component";
// // import { useNavigate } from "react-router-dom";
// // import axios from "axios";
// // import { useSelector } from "react-redux";
// // import { Button } from "antd";
// // import Offcanvas from "react-bootstrap/Offcanvas";
// // import Excellogo from '../../assets/img/Excellogo.png';
// // import Select from "react-select";
// // import AsyncSelect from "react-select/async";
// // import { auto } from "@popperjs/core";

// // function AllEndPoint() {
// //   const navigate = useNavigate();
// //   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
// //   const location = useLocation();

// //   const [skin, setSkin] = useState(currentSkin);
// //   const [columnData, setColumnData] = useState([]);
// //   const [loading, setLoading] = useState(true);
// //   const [selectedRows, setSelectedRows] = useState([]);
// //   const [showOffcanvas, setShowOffcanvas] = useState(false);
// //   const [departments, setDepartments] = useState([]);
// //   const [selectedDepartment, setSelectedDepartment] = useState(null);
// //   const [selectedSection, setSelectedSection] = useState(null);
// //   const [selectedSubsection, setSelectedSubsection] = useState(null);

// //   const { user } = useSelector((state) => state.authSlice);


// //   const fetchData = async () => {
// //     try {
// //       const response = await axios.get(
// //         `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${user?.data?.accessToken}`,
// //           },
// //         }
// //       );

// //       const devices = response.data.devices;
// //       setColumnData(devices);
// //       setLoading(false);
// //     } catch (error) {
// //       console.error("Error fetching data: ", error.response);
// //       setLoading(false);
// //     }
// //   };

// //   useEffect(() => {
// //     fetchData();
// //   }, [user]);
// //   useEffect(() => {
// //     const fetchOrganizationData = async () => {
// //       try {
// //         const response = await axios.get(
// //           `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
// //         );
// //       //  setDepartments(response.data);
// //       setDepartments(response.data.departments);
// //       } catch (error) {
// //         console.error("Error fetching organization data: ", error);
// //       }
// //     };

// //     fetchOrganizationData();
// //   }, [user?.data?.user?.organizationId]);

// //   const formatTimestamp = (timestamp) => {
// //     const date = new Date(timestamp);
// //     const day = String(date.getDate()).padStart(2, "0");
// //     const month = String(date.getMonth() + 1).padStart(2, "0");
// //     const year = date.getFullYear();
// //     const hours = String(date.getHours()).padStart(2, "0");
// //     const minutes = String(date.getMinutes()).padStart(2, "0");
// //     const seconds = String(date.getSeconds()).padStart(2, "0");
// //     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
// //   };

// //   const columns = [
// //     {
// //       name: "Hostname",
// //       selector: (row) => row.hostname,
// //       sortable: true,
// //     },
// //     {
// //       name: "Serial Number",
// //       selector: (row) => row.serialNumber,
// //       sortable: true,
// //     },
// //     {
// //       name: "IP Address",
// //       selector: (row) => row.ipAddress || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Client Score",
// //       selector: (row) => row.auditScore || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Sync Time",
// //       selector: (row) => formatTimestamp(row.updatedAt) || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Associated With",
// //       selector: (row) => row.departmentName || "NA",
// //       sortable: true,
// //     },
// //     {
// //       name: "Action",
// //       cell: (row) => (
// //         <Button onClick={() => handleActionClick(row)} variant="primary">
// //           Action
// //         </Button>
// //       ),
// //       sortable: false,
// //     },
// //   ];

// //   const customStyles = {
// //     headCells: {
// //       style: {
// //         fontWeight: "bold",
// //         fontSize: "14px",
// //         backgroundColor: "#cccccc",
// //       },
// //     },
// //   };

// //   const handleSelectedRowsChange = ({ selectedRows }) => {
// //     setSelectedRows(selectedRows);
// //   };

// //   const handleActionClick = (row) => {
// //     console.log("Action clicked for row:", row);
// //   };

// //   const handleControlClick = () => {
// //     setShowOffcanvas(true);
// //   };

// //   const loadDepartments = async (inputValue) => {
// //     return departments.map((dept) => ({
// //       label: dept.departmentName,
// //       value: dept._id,
// //     }));
// //   };

// //   const loadSections = async (inputValue) => {
// //     if (!selectedDepartment) return [];
// //     const department = departments.find((dept) => dept._id === selectedDepartment.value);
// //     return department.sections.map((section) => ({
// //       label: section.sectionName,
// //       value: section._id,
// //     }));
// //   };

// //   const loadSubsections = async (inputValue) => {
// //     if (!selectedSection) return [];
// //     const department = departments.find((dept) => dept._id === selectedDepartment.value);
// //     const section = department.sections.find((sec) => sec._id === selectedSection.value);
// //     return section.subSections.map((subSection) => ({
// //       label: subSection.subSectionName,
// //       value: subSection._id,
// //     }));
// //   };

// //   const handleSubmit = async (e) => {
// //     e.preventDefault();

// //     if (!selectedDepartment || !selectedSection || !selectedSubsection) {
// //       alert("Please select a department, section, and subsection.");
// //       return;
// //     }

// //     const deviceIds = selectedRows.map((row) => row.pcId);
// //     const data = {
// //       deviceIds,
// //       departmentId: selectedDepartment.value,
// //       sectionId: selectedSection.value,
// //       subSectionId: selectedSubsection.value,
// //     };

// //     try {
// //       const response = await axios.post(
// //         `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
// //         data,
// //         {
// //           headers: {
// //             Authorization: `Bearer ${user?.data?.accessToken}`,
// //           },
// //         }
// //       );
// //       console.log("API response:", response.data);
// //       // Handle success response

// //          // Close Offcanvas and update DataTable data
// //          setShowOffcanvas(false);
// //          fetchData();
// //          setSelectedRows([]);
    
// //     } catch (error) {
// //       console.error("Error submitting form:", error);
// //       // Handle error response
// //     }
// //   };

// //   return (
// //     <React.Fragment>
// //       <Header onSkin={setSkin} />
// //       <div className="main main-app p-3 p-lg-4" style={{ backgroundColor: "#ECF9FF" }}>
// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
// //             <p className="fs-18 fw-bolder p-0 m-0">All Endpoint</p>
// //           </span>
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-danger text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
// //             </button>
// //             <button type="button" className="btn btn-success text-white">
// //               <img src={Excellogo} height={20} width={15} />&nbsp; Export
// //             </button>
// //           </div>
// //         </div>

// //         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
// //           <div className="d-flex align-items-center justify-content-between gap-2">
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               data-bs-toggle="modal"
// //               data-bs-target="#exampleModal"
// //             >
// //               Manage label
// //             </button>
// //             <button
// //               type="button"
// //               className="btn btn-info text-white"
// //               onClick={handleControlClick}
// //               disabled={selectedRows.length === 0}
// //             >
// //               Control
// //             </button>
// //           </div>

// //           <button type="button" className="btn btn-info text-white">
// //             <i className="fa-solid fa-eye"></i> Table View
// //           </button>
// //         </div>
// //         <div className="card rounded cardStyle">
// //           {loading ? (
// //             <p>Loading...</p>
// //           ) : (
// //             <DataTable
// //               columns={columns}
// //               data={columnData}
// //               pagination
// //               customStyles={customStyles}
// //               selectableRows
// //               onSelectedRowsChange={handleSelectedRowsChange}
// //               selectableRowsHighlight
// //               highlightOnHover
// //               paginationPerPage={10} // Number of rows per page
// //               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
// //             />
// //           )}
// //         </div>

// //         {/* Offcanvas for displaying selected rows */}
// //         <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)} placement="end">
// //           <Offcanvas.Header closeButton>
// //             <Offcanvas.Title>
// //               Selected Devices: <span className="badge rounded-pill text-bg-secondary text-white">{selectedRows.length}</span>
// //             </Offcanvas.Title>
// //           </Offcanvas.Header>
// //           <Offcanvas.Body>
// //             <ul>
// //               {selectedRows.map((row) => (
// //                 <>
// //                 <li key={row.id}>{row.hostname}</li>
// //                 <li key={row.pcId}>{row.pcId}</li>
// //                 </>
             
                
// //               ))}
// //             </ul>

// //             <form onSubmit={handleSubmit}>
// //               <div className="mb-3">
// //                 <label htmlFor="departments" className="form-label">Departments</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadDepartments}
// //                   defaultOptions
// //                   onChange={(selectedOption) => {
// //                     setSelectedDepartment(selectedOption);
// //                     setSelectedSection(null);
// //                     setSelectedSubsection(null);
// //                   }}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="sections" className="form-label">Sections</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadSections}
// //                   defaultOptions
// //                   isDisabled={!selectedDepartment}
// //                   onChange={(selectedOption) => {
// //                     setSelectedSection(selectedOption);
// //                     setSelectedSubsection(null);
// //                   }}
// //                 />
// //               </div>
// //               <div className="mb-3">
// //                 <label htmlFor="subsections" className="form-label">Subsections</label>
// //                 <AsyncSelect
// //                   cacheOptions
// //                   loadOptions={loadSubsections}
// //                   defaultOptions
// //                   isDisabled={!selectedSection}
// //                   onChange={(selectedOption) => setSelectedSubsection(selectedOption)}
// //                 />
// //               </div>
// //               <button type="submit" className="btn btn-primary">Submit</button>
// //             </form>
// //           </Offcanvas.Body>
// //         </Offcanvas>
// //       </div>
// //     </React.Fragment>
// //   );
// // }

// // export default AllEndPoint;


// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';
// import axios from 'axios';
// import { useSelector } from 'react-redux';

// const Checkbox = ({ children, ...props }) => (
//   <label style={{ marginRight: '1em' }}>
//     <input type="checkbox" {...props} />
//     {children}
//   </label>
// );

// const Test = () => {
//   const [isClearable, setIsClearable] = useState(true);
//   const [isSearchable, setIsSearchable] = useState(true);
//   const [isDisabled, setIsDisabled] = useState(false);
//   const [isLoading, setIsLoading] = useState(false);
//   const [isRtl, setIsRtl] = useState(false);
//   const [departments, setDepartments] = useState([]);
//   const { user } = useSelector((state) => state.authSlice);

//   useEffect(() => {
//     const fetchDepartments = async () => {
//       setIsLoading(true);
//       try {
//         const response = await axios.get(
//           `https://dev.cyberauditor.in/api/v1/organization/all-department/${user?.data?.user?.organizationId}`
//         );
//         console.log('API Response:', response.data.departments);
//         const departmentOptions = response.data.departments.map((dept) => ({
//           value: dept._id,
//           label: dept.departmentName,
//         }));

//         console.log(response,'departmentOptions')
//         console.log(departmentOptions,'departmentOptions')
//         setDepartments(departmentOptions);
//       } catch (error) {
//         console.error('Error fetching departments:', error);
//       } finally {
//         setIsLoading(false);
//       }
//     };

//     fetchDepartments();
//   }, [user?.data?.user?.organizationId]);

//   return (
//     <>
//       <Select
//         className="basic-single"
//         classNamePrefix="select"
//         isDisabled={isDisabled}
//         isLoading={isLoading}
//         isClearable={isClearable}
//         isRtl={isRtl}
//         isSearchable={isSearchable}
//         name="departments"
//         options={departments}
//       />

//       <div
//         style={{
//           color: 'hsl(0, 0%, 40%)',
//           display: 'inline-block',
//           fontSize: 12,
//           fontStyle: 'italic',
//           marginTop: '1em',
//         }}
//       >
//         <Checkbox
//           checked={isClearable}
//           onChange={() => setIsClearable((state) => !state)}
//         >
//           Clearable
//         </Checkbox>
//         <Checkbox
//           checked={isSearchable}
//           onChange={() => setIsSearchable((state) => !state)}
//         >
//           Searchable
//         </Checkbox>
//         <Checkbox
//           checked={isDisabled}
//           onChange={() => setIsDisabled((state) => !state)}
//         >
//           Disabled
//         </Checkbox>
//         <Checkbox
//           checked={isLoading}
//           onChange={() => setIsLoading((state) => !state)}
//         >
//           Loading
//         </Checkbox>
//         <Checkbox checked={isRtl} onChange={() => setIsRtl((state) => !state)}>
//           RTL
//         </Checkbox>
//       </div>
//     </>
//   );
// };

// export default Test;


// import React, { useState, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import Header from "../../layouts/Header";
// import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";
// import { useSelector } from "react-redux";
// import { Button } from "antd";
// import Offcanvas from "react-bootstrap/Offcanvas";
// import Excellogo from "../../assets/img/Excellogo.png";
// import AsyncSelect from "react-select/async";

// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import Tippy from "@tippyjs/react";
// import "tippy.js/dist/tippy.css"; // optional
// function Test() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [showOffcanvas, setShowOffcanvas] = useState(false);
//   const [departments, setDepartments] = useState([]);
//   const [selectedDepartment, setSelectedDepartment] = useState(null);
//   const [selectedSection, setSelectedSection] = useState(null);
//   const [selectedSubsection, setSelectedSubsection] = useState(null);

//   const [newUserName, setNewUserName] = useState("");
//   const [newUserEmail, setNewUserEmail] = useState("");

//   const { user } = useSelector((state) => state.authSlice);


//   const fetchData = async () => {
//     try {
//       const response = await axios.get(
//         `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
   
//     const devices = response.data.data;
//      setColumnData(devices);
//       setLoading(false);
//     } catch (error) {
//       console.error("Error fetching data: ", error.response);
//       setLoading(false);
//     }
//   };

//   useEffect(() => {
//     fetchData();
//   }, [user]);
//   useEffect(() => {
//     const fetchOrganizationData = async () => {
//       try {
//         const response = await axios.get(
//           `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
//         );
//         setDepartments(response.data.departments);
//       } catch (error) {
//         console.error("Error fetching organization data: ", error);
//       }
//     };

//     fetchOrganizationData();
//   }, [user?.data?.user?.organizationId]);

//   const formatTimestamp = (timestamp) => {
//     const date = new Date(timestamp);
//     const day = String(date.getDate()).padStart(2, "0");
//     const month = String(date.getMonth() + 1).padStart(2, "0");
//     const year = date.getFullYear();
//     const hours = String(date.getHours()).padStart(2, "0");
//     const minutes = String(date.getMinutes()).padStart(2, "0");
//     const seconds = String(date.getSeconds()).padStart(2, "0");
//     return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
//   };
//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };
//   const columns = [
//     {
//       name: "Name",
//       selector: (row) => row.firstName,
//       sortable: true,
//     },
//     {
//       name: "Email",
//       selector: (row) => row.email,
//       sortable: true,
//     },
//     {
//       name: "Status",
//       selector: (row) => (row.status ? "Active" : "Inactive"),
//       sortable: true,
//       cell: (row) => (
//         <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>{(row.status ? "Active" : "Inactive")}</span>
//       ),
//     },
   
//     {
//       name: "Added",
//       selector: (row) => formatTimestamp(row.createdAt) || "NA",
//       sortable: true,
//     },
//     {
//       name: "Associated With",
//       sortable: true,
//       selector: (row) => (
//         <Tippy content={row.organizationName || row.sectionName || row.subSectionName || "NA"}>
//           <div>{row.organizationName || row.sectionName || row.subSectionName || "NA"}</div>
//         </Tippy>
//       ),
//     },
//     {
//       name: "Action",
//       cell: (row) => (
//         <Button onClick={() => handleActionClick(row)} variant="primary">
//           Action
//         </Button>
//       ),
//       sortable: false,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const handleActionClick = (row) => {
//     console.log("Action clicked for row:", row);
//   };

//   const handleControlClick = () => {
//     setShowOffcanvas(true);
//   };

//   const loadDepartments = async (inputValue) => {
//     return departments.map((dept) => ({
//       label: dept.departmentName,
//       value: dept._id,
//     }));
//   };

//   const loadSections = async (inputValue) => {
//     if (!selectedDepartment) return [];
//     const department = departments.find(
//       (dept) => dept._id === selectedDepartment.value
//     );
//     return department.sections.map((section) => ({
//       label: section.sectionName,
//       value: section._id,
//     }));
//   };

//   const loadSubsections = async (inputValue) => {
//     if (!selectedSection) return [];
//     const department = departments.find(
//       (dept) => dept._id === selectedDepartment.value
//     );
//     const section = department.sections.find(
//       (sec) => sec._id === selectedSection.value
//     );
//     return section.subSections.map((subSection) => ({
//       label: subSection.subSectionName,
//       value: subSection._id,
//     }));
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!selectedDepartment || !selectedSection || !selectedSubsection) {
//       alert("Please select a department, section, and subsection.");
//       return;
//     }

//     const deviceIds = selectedRows.map((row) => row.pcId);
//     const data = {
//       deviceIds,
//       departmentId: selectedDepartment.value,
//       sectionId: selectedSection.value,
//       subSectionId: selectedSubsection.value,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log("API response:", response.data);
//       // Handle success response

//       // Close Offcanvas and update DataTable data
//       setShowOffcanvas(false);
//       fetchData();
//       setSelectedRows([]);
//     } catch (error) {
//       console.error("Error submitting form:", error);
//       // Handle error response
//     }
//   };

//   const handleAddUser = async (e) => {
//     e.preventDefault();

//     if (!newUserName || !newUserEmail) {
//       alert("Please enter a name and email.");
//       return;
//     }

//     const data = {
//         fname: newUserName,
//         email: newUserEmail,
//     };

//     try {
//       const response = await axios.post(
//         `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
//         data,
//         {
//           headers: {
//             Authorization: `Bearer ${user?.data?.accessToken}`,
//           },
//         }
//       );
//       console.log("API response:", response.data.message);
//       // Handle success response
//       toast.success(response.data.message, {
//         position: "top-center",
//         autoClose: 2000,
//         hideProgressBar: true,
//         closeOnClick: true,
//         pauseOnHover: true,
//         draggable: true,
//         progress: false,
//         theme: "light",
//       });
//       // Reset form fields
//       setNewUserName("");
//       setNewUserEmail("");
//       // Close the modal
//       document.getElementById("closeModal").click();
//     } catch (error) {
//       toast.error(error);
//       console.error("Error adding user:", error);
//       // Handle error response
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">Manage Users</p>
//           </span>
//           {/* <div className="d-flex align-items-center justify-content-between gap-2">
//             <button
//               type="button"
//               className="btn btn-danger text-white"
//               data-bs-toggle="modal"
//               data-bs-target="#exampleModal"
//             >
//               <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
//             </button>
//             <button type="button" className="btn btn-success text-white">
//               <img src={Excellogo} height={20} width={15} />
//               &nbsp; Export
//             </button>
//           </div> */}
//         </div>

//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <div class="col-xl">
//             <p class="fs-20 text-dark fw-bolder p-0 m-0">User List</p>
//             <p class="text-danger p-0 m-0 fw-semibold">{columnData.length} Users</p>
//           </div>
//           <button
//             type="button"
//             className="btn btn-info text-white"
//             onClick={handleControlClick}
//             disabled={selectedRows.length === 0}
//           >
//             Control
//           </button>
//         &nbsp; &nbsp;
//           <button
//             type="button"
//             className="btn btn-dark text-white"
//             data-bs-toggle="modal"
//             data-bs-target="#exampleModal"
//           >
//           <i class="fa-solid fa-user-plus"></i> User
//           </button>
//         </div>
//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData}
//               pagination
//               customStyles={customStyles}
//               selectableRows
//               onSelectedRowsChange={handleSelectedRowsChange}
//               selectableRowsHighlight
//               highlightOnHover
//               paginationPerPage={10} // Number of rows per page
//               paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
//             />
//           )}
//         </div>

//         {/* Offcanvas for displaying selected rows */}
//         <Offcanvas
//           show={showOffcanvas}
//           onHide={() => setShowOffcanvas(false)}
//           placement="end"
//         >
//           <Offcanvas.Header closeButton>
//             <Offcanvas.Title>
//               Selected Devices:{" "}
//               <span className="badge rounded-pill text-bg-secondary text-white">
//                 {selectedRows.length}
//               </span>
//             </Offcanvas.Title>
//           </Offcanvas.Header>
//           <Offcanvas.Body>
//             <ul>
//               {selectedRows.map((row) => (
//                 <>
//                   <li key={row.user_id}>{row.firstName}</li>
                  
//                 </>
//               ))}
//             </ul>

//             <form onSubmit={handleSubmit}>
//               <div className="mb-3">
//                 <label htmlFor="departments" className="form-label">
//                   Departments
//                 </label>
//                 <AsyncSelect
//                   cacheOptions
//                   loadOptions={loadDepartments}
//                   defaultOptions
//                   onChange={(selectedOption) => {
//                     setSelectedDepartment(selectedOption);
//                     setSelectedSection(null);
//                     setSelectedSubsection(null);
//                   }}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="sections" className="form-label">
//                   Sections
//                 </label>
//                 <AsyncSelect
//                   cacheOptions
//                   loadOptions={loadSections}
//                   defaultOptions
//                   isDisabled={!selectedDepartment}
//                   onChange={(selectedOption) => {
//                     setSelectedSection(selectedOption);
//                     setSelectedSubsection(null);
//                   }}
//                 />
//               </div>
//               <div className="mb-3">
//                 <label htmlFor="subsections" className="form-label">
//                   Subsections
//                 </label>
//                 <AsyncSelect
//                   cacheOptions
//                   loadOptions={loadSubsections}
//                   defaultOptions
//                   isDisabled={!selectedSection}
//                   onChange={(selectedOption) =>
//                     setSelectedSubsection(selectedOption)
//                   }
//                 />
//               </div>
//               <button type="submit" className="btn btn-primary">
//                 Submit
//               </button>
//             </form>
//           </Offcanvas.Body>
//         </Offcanvas>

//         <div
//           class="modal fade"
//           id="exampleModal"
//           tabindex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div class="modal-dialog">
//             <div class="modal-content">
//               <div class="modal-header">
//                 <h1 class="modal-title fs-5" id="exampleModalLabel">
//                   Add User
//                 </h1>
//                 <button
//                   type="button"
//                   class="btn-close"
//                   data-bs-dismiss="modal"
//                   aria-label="Close"
//                 ></button>
//               </div>
//               <div class="modal-body">
//                 <form onSubmit={handleAddUser}>
//                   <div class="mb-3">
//                     <label for="exampleInputName" class="form-label">
//                       Name
//                     </label>
//                     <input
//                       type="text"
//                       class="form-control"
//                       id="exampleInputName"
//                       aria-describedby="nameHelp"
//                       value={newUserName}
//                       onChange={(e) => setNewUserName(e.target.value)}
//                     />
//                   </div>
//                   <div class="mb-3">
//                     <label for="exampleInputEmail1" class="form-label">
//                       Email address
//                     </label>
//                     <input
//                       type="email"
//                       class="form-control"
//                       id="exampleInputEmail1"
//                       aria-describedby="emailHelp"
//                       value={newUserEmail}
//                       onChange={(e) => setNewUserEmail(e.target.value)}
//                     />
//                     <div id="emailHelp" class="form-text">
//                       We'll never share your email with anyone else.
//                     </div>
//                   </div>

//                   <button type="submit" class="btn btn-primary">
//                     Submit
//                   </button>
//                 </form>
//               </div>
//               {/* <div class="modal-footer">
//                 <button
//                   type="button"
//                   class="btn btn-secondary"
//                   data-bs-dismiss="modal"
//                   id = "closeModal"
//                 >
//                   Close
//                 </button>
//                 <button type="button" class="btn btn-primary">
//                   Save changes
//                 </button>
//               </div> */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default Test;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../assets/img/Excellogo.png";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

function Test() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasT, setShowOffcanvasT] = useState(false);

  const [departments, setDepartments] = useState([]);
  const [sections, setSections] = useState([]);
  const [subsections, setSubsections] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);
  const [toggleClearRows, setToggleClearRows] = useState(false);
  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");
  const [visibleColumns, setVisibleColumns] = useState({
    hostname: true,
    serialNumber: true,
    ipAddress: true,
    auditScore: true,
    updatedAt: true,
    departmentName: true,
  });

  const { user } = useSelector((state) => state.authSlice);

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/organization/users/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.data;
      setColumnData(devices);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.firstName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row.status ? "Active" : "Inactive"),
      sortable: true,
      cell: (row) => (
        <span style={getStatusStyle(row.status ? "Active" : "Inactive")}>
          {row.status ? "Active" : "Inactive"}
        </span>
      ),
    },

    {
      name: "Added",
      selector: (row) => formatTimestamp(row.createdAt) || "NA",
      sortable: true,
    },
    {
      name: "Associated With",
      sortable: true,
      selector: (row) => (
        <Tippy
          content={
            row.departmentInfo ||
            "NA"
          }
        >
          <div>
            {row.departmentInfo ||
              "NA"}
          </div>
        </Tippy>
      ),
      width:'280px'
    },
    {
      name: "Action",
      cell: (row) => (
        <button onClick={() => handleActionClick(row)} className="btn btn-outline-danger bg-btn-danger" variant="primary">
          <i className="fa-solid fa-trash"></i>
        </button>
      ),
      sortable: false,
    },
  ];

  const exportToPDF = (data) => {
    const doc = new jsPDF();
    doc.text("Exported Data", 14, 16);

    const tableColumn = [
      "Hostname",
      "Serial Number",
      "IP Address",
      "Client Score",
      "Sync Time",
      "Associated With",
    ];
    const tableRows = data.map((item) => [
      item.hostname,
      item.serialNumber,
      item.ipAddress || "NA",
      item.auditScore || "NA",
      formatTimestamp(item.updatedAt) || "NA",
      item.departmentName || "NA",
    ]);

    doc.autoTable(tableColumn, tableRows, { startY: 20 });
    doc.save("data.pdf");
  };

  const exportToExcel = (data) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map((item) => ({
        Hostname: item.hostname,
        SerialNumber: item.serialNumber,
        IPAddress: item.ipAddress || "NA",
        ClientScore: item.auditScore || "NA",
        SyncTime: formatTimestamp(item.updatedAt) || "NA",
        AssociatedWith: item.departmentName || "NA",
      }))
    );

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Data");
    XLSX.writeFile(wb, "data.xlsx");
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleActionClick = async (row) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/delete/by/${user?.data?.user?.organizationId}/${row.id}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
          data: { userId: row.id },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Update DataTable data
      fetchData();
    } catch (error) {
      toast.error(error);
      console.error("Error deleting user:", error);
      // Handle error response
    }
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };
  const handleTableClick = () => {
    setShowOffcanvasT(true);
  };
  const handleCheckboxChange = (columnName) => {
    setVisibleColumns((prevState) => ({
      ...prevState,
      [columnName]: !prevState[columnName],
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setSelectedSection(null);
    setSelectedSubsection(null);

    const department = departments.find(
      (dept) => dept._id === selectedOption.value
    );
    if (department) {
      setSections(department.sections);
    }
  };

  const handleSectionChange = (selectedOption) => {
    setSelectedSection(selectedOption);
    setSelectedSubsection(null);

    const section = sections.find((sect) => sect._id === selectedOption.value);
    if (section) {
      setSubsections(section.subSections);
    }
  };

  const handleSubsectionChange = (selectedOption) => {
    setSelectedSubsection(selectedOption);
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if (!selectedDepartment) {
      toast.error("Please select a department.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!newUserName || !newUserEmail) {
      toast.error("Please enter both name and email.", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    try {
      const payload = {
        userName: newUserName,
        email: newUserEmail,
        department: selectedDepartment.value,
        section: selectedSection ? selectedSection.value : "",
        subsection: selectedSubsection ? selectedSubsection.value : "",
      };

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/user/invite/${user?.data?.user?.organizationId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });

      // Close Offcanvas and update DataTable data
      setShowOffcanvas(false);
      fetchData();

      setSelectedDepartment(null);
      setSelectedSection(null);
      setSelectedSubsection(null);
      setNewUserName("");
      setNewUserEmail("");
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error sending form data:", error);
    }
  };

  return (
    <div id="appCapsule" className={`skin-${skin}`}>
      <Header title="Users" />
      <div className="appContent">
        <div className="section" style={{ marginBottom: "1rem" }}>
          <div className="card">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6 col-sm-6">
                  <Button onClick={handleControlClick} className="btn btn-outline-primary">
                    Add User
                  </Button>
                </div>
                <div className="col-md-6 col-sm-6 text-right">
                  <Button
                    onClick={handleTableClick}
                    className="btn btn-outline-primary"
                  >
                    Export Data
                  </Button>
                </div>
              </div>
              <DataTable
                columns={columns}
                data={columnData}
                progressPending={loading}
                pagination
                highlightOnHover
                customStyles={customStyles}
                selectableRows
                onSelectedRowsChange={handleSelectedRowsChange}
                clearSelectedRows={toggleClearRows}
              />
            </div>
          </div>
        </div>
      </div>
      <Offcanvas show={showOffcanvas} onHide={() => setShowOffcanvas(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add User</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleFormSubmit}>
            <div className="form-group">
              <label htmlFor="newUserName">Name</label>
              <input
                type="text"
                className="form-control"
                id="newUserName"
                value={newUserName}
                onChange={(e) => setNewUserName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newUserEmail">Email</label>
              <input
                type="email"
                className="form-control"
                id="newUserEmail"
                value={newUserEmail}
                onChange={(e) => setNewUserEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="department">Department</label>
              <Select
                id="department"
                options={departments.map((dept) => ({
                  value: dept._id,
                  label: dept.name,
                }))}
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                isClearable
              />
            </div>
            <div className="form-group">
              <label htmlFor="section">Section</label>
              <Select
                id="section"
                options={sections.map((sect) => ({
                  value: sect._id,
                  label: sect.name,
                }))}
                value={selectedSection}
                onChange={handleSectionChange}
                isClearable
              />
            </div>
            <div className="form-group">
              <label htmlFor="subsection">Subsection</label>
              <Select
                id="subsection"
                options={subsections.map((subsect) => ({
                  value: subsect._id,
                  label: subsect.name,
                }))}
                value={selectedSubsection}
                onChange={handleSubsectionChange}
                isClearable
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Add User
            </button>
          </form>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas show={showOffcanvasT} onHide={() => setShowOffcanvasT(false)} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Export Data</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            <Button onClick={() => exportToPDF(columnData)} className="btn btn-outline-primary">
              Export to PDF
            </Button>
            <Button onClick={() => exportToExcel(columnData)} className="btn btn-outline-primary">
              Export to Excel
            </Button>
          </div>
          <div className="mt-3">
            <h5>Customize Table</h5>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showHostname"
                checked={visibleColumns.hostname}
                onChange={() => handleCheckboxChange("hostname")}
              />
              <label className="form-check-label" htmlFor="showHostname">
                Show Hostname
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showSerialNumber"
                checked={visibleColumns.serialNumber}
                onChange={() => handleCheckboxChange("serialNumber")}
              />
              <label className="form-check-label" htmlFor="showSerialNumber">
                Show Serial Number
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showIpAddress"
                checked={visibleColumns.ipAddress}
                onChange={() => handleCheckboxChange("ipAddress")}
              />
              <label className="form-check-label" htmlFor="showIpAddress">
                Show IP Address
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showAuditScore"
                checked={visibleColumns.auditScore}
                onChange={() => handleCheckboxChange("auditScore")}
              />
              <label className="form-check-label" htmlFor="showAuditScore">
                Show Client Score
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showUpdatedAt"
                checked={visibleColumns.updatedAt}
                onChange={() => handleCheckboxChange("updatedAt")}
              />
              <label className="form-check-label" htmlFor="showUpdatedAt">
                Show Sync Time
              </label>
            </div>
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                id="showDepartmentName"
                checked={visibleColumns.departmentName}
                onChange={() => handleCheckboxChange("departmentName")}
              />
              <label className="form-check-label" htmlFor="showDepartmentName">
                Show Associated With
              </label>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer />
    </div>
  );
}

export default Test;
