// import React, { useEffect, useState } from "react";
// import { Button } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import Header from "../../../layouts/Header";
// import DataTable from "react-data-table-component";
// import { useNavigate } from "react-router-dom";
// import axios from "axios";

// function LicenseView() {
//   const navigate = useNavigate();
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const location = useLocation();

//   const [skin, setSkin] = useState(currentSkin);
//   const [columnData, setColumnData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [validFrom, setValidFrom] = useState("");
//   const [validTo, setValidTo] = useState("");
//   const [licenseDate, setLicenseDate] = useState("");
//   const [id, setId] = useState("");
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [newValidToDate, setNewValidToDate] = useState("");
//   const [successMessage, setSuccessMessage] = useState("");



//   const handleDateChange = (e) => {
//     const date = e.target.value;
//     const [year, month, day] = date.split('-');
//     const formattedDate = `${day}-${month}-${year}`;
//     setNewValidToDate(formattedDate);
//   };
//   console.log(newValidToDate, "newValidToDate");
//   useEffect(() => {
//     const url = new URL(window.location.href);
//     const pathParts = url.pathname.split("/");

//     if (pathParts.length >= 6) {
//       const id = pathParts[2];
//       const licenseDate = pathParts[4];
//       const validtofrom = pathParts[5];
//       console.log(id, licenseDate, validtofrom, "rrrrrrrrrrrrrr");
//       const [from, to] = validtofrom.split("to");
//       setId(id);
//       setLicenseDate(licenseDate);
//       setValidFrom(from);
//       setValidTo(to);
//     } else {
//       console.error("URL does not match the expected format.");
//     }
//   }, [location]);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");

//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );

//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   useEffect(() => {
//     const fetchData = async () => {
//       if (id && validFrom && validTo) {
//         try {
//           const response = await axios.get(
//             `${process.env.REACT_APP_NOTIFY_URL}/${id}/license-details?validFrom=${validFrom}&validTo=${validTo}`
//           );
//           console.log([response?.data?.licenses], "ttttttttttttttttttt");
//           setColumnData([response?.data?.licenses]);
//           setLoading(false);
//         } catch (error) {
//           console.error("Error fetching data: ", error);
//           setLoading(false);
//         }
//       }
//     };

//     fetchData();
//   }, [id, validFrom, validTo]);

//   const getStatusStyle = (status) => {
//     if (status === "Active") return { color: "green" };
//     if (status === "Inactive") return { color: "red" };
//     return { color: "orange" };
//   };

//   const exportRowToCSV = (rowData, filename) => {
//     const csvContent = [
//       ["Date", "Quantity", "Validity", "Device ID", "Status"],
//       [
//         rowData.date,
//         rowData.quantity,
//         rowData.validity,
//         rowData.deviceid,
//         rowData.status,
//       ],
//     ]
//       .map((e) => e.join(","))
//       .join("\n");

//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");

//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute("href", url);
//       link.setAttribute("download", filename);
//       link.style.visibility = "hidden";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   const exportToCSV = (rows, filename) => {
//     console.log(rows, "eeeeeeee");
//     const csvContent = [
//       ["License No", "Valid From", "Valid Upto", "Device ID", "Status"],
//       ...rows.map((row) => [
//         row.licKey,
//         row.validFrom,
//         row.validTo,
//         row.pcIdAssociated,
//         row.lkeyStatus,
//       ]),
//     ]
//       .map((e) => e.join(","))
//       .join("\n");

//     const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
//     const link = document.createElement("a");

//     if (link.download !== undefined) {
//       const url = URL.createObjectURL(blob);
//       link.setAttribute("href", url);
//       link.setAttribute("download", filename);
//       link.style.visibility = "hidden";
//       document.body.appendChild(link);
//       link.click();
//       document.body.removeChild(link);
//     }
//   };

//   const columns = [
//     {
//       name: "License No",
//       selector: (row) => row.licKey,
//       sortable: false,
//       width: "320px",
//     },
//     {
//       name: "Valid From",
//       selector: (row) => row.validFrom,
//       sortable: true,
//     },
//     {
//       name: "Valid Upto",
//       selector: (row) => row.validTo,
//       sortable: true,
//     },
//     {
//       name: "Device ID",
//       selector: (row) => (row.pcIdAssociated ? row.pcIdAssociated : "NA"),
//       sortable: true,
//     },
//     {
//       name: "Status",
//       selector: (row) => (row?.lkeyStatus ? row?.lkeyStatus : "NA"),
//       sortable: true,
//     },
//   ];

//   const customStyles = {
//     headCells: {
//       style: {
//         fontWeight: "bold",
//         fontSize: "14px",
//         backgroundColor: "#cccccc",
//       },
//     },
//   };

//   const handleSelectedRowsChange = ({ selectedRows }) => {
//     console.log(selectedRows, "selectedRows");
//     setSelectedRows(selectedRows);
//   };

//   const handleUpdateClick = async () => {
//     const licenseKeys = selectedRows.map((row) => row.licKey);
//     const newValidTo = newValidToDate; // You might want to format this date to match the required format "09-09-2026"
//     const requestBody = {
//       licenseKeys,
//       newValidTo,
//       organizationId: id,
//     };

//     try {
//       const response = await axios.put(
//         `${process.env.REACT_APP_NOTIFY_URL}/extend-license-expiry`,
//         requestBody
//       );
//       console.log("Response:", response.status == true);
//       setSuccessMessage("License generated successfully!");
//       setTimeout(() => {
//         setSuccessMessage("");
//         setSelectedRows([]);

//         // Refresh data or update state to reflect new license
//         // Example: fetch updated data or update state
//       }, 2000);
//     } catch (error) {
//       console.error("Error updating license expiry: ", error);
//     }
//   };

//   return (
//     <React.Fragment>
//       <Header onSkin={setSkin} />
//       <div
//         className="main main-app p-3 p-lg-4"
//         style={{ backgroundColor: "#ECF9FF" }}
//       >
//         <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
//           <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
//             <p className="fs-18 fw-bolder p-0 m-0">
//               License Date: {licenseDate}
//             </p>
//             <p className="fs-14 p-0 m-0">Quantity: {columnData?.length}</p>
//           </span>

//           <div className="d-flex align-items-center justify-content-between gap-2">
//             <button
//               type="button"
//               className="btn btn-success text-white"
//               onClick={() => exportToCSV(columnData[0], "license-data.csv")}
//             >
//               Export All
//             </button>
//             <button
//               type="button"
//               className="btn btn-primary text-white"
//               data-bs-toggle="modal"
//               data-bs-target="#exampleModal"
//             >
//               Update validity
//             </button>
//           </div>
//         </div>

//         {/* <!-- Modal --> */}
//         <div
//           class="modal fade"
//           id="exampleModal"
//           tabindex="-1"
//           aria-labelledby="exampleModalLabel"
//           aria-hidden="true"
//         >
//           <div class="modal-dialog">
//             <div class="modal-content">
//               <div class="modal-header">
//                 <h1 class="modal-title fs-5" id="exampleModalLabel">
//                   Upgrade license validity
//                 </h1>
//                 {successMessage && (
//                   <div className="alert alert-success" role="alert">
//                     {successMessage}
//                   </div>
//                 )}
//               </div>
//               <div className="card-body m-1 p-3">
//                 <div class="row g-3 align-items-center">
//                   <div class="col-auto">
//                     <label
//                       for="data"
//                       class="col-form-label"
//                       style={{ color: "black" }}
//                     >
//                       Selected license:
//                     </label>
//                   </div>
//                   <div class="col-auto">
//                     <span class="badge rounded-pill text-bg-secondary text-white">
//                       Selected: {selectedRows.length}
//                     </span>
//                   </div>
//                 </div>
//                 <div class="row g-3 align-items-center">
//                   <div class="col-auto">
//                     <label
//                       for="data"
//                       class="col-form-label"
//                       style={{ color: "black" }}
//                     >
//                       Valid Upto:
//                     </label>
//                   </div>
//                   <div class="col-auto">
//                     <input
//                       type="date"
//                       className="form-control"
//                       value={newValidToDate.split('-').reverse().join('-')} // Reformat back to yyyy-mm-dd for the input field
//                       onChange={handleDateChange}
//                       required
//                     />
//                   </div>
//                 </div>
//                 {/* <button
//                   className="btn btn-primary mt-2"
//                   onClick={handleUpdateClick}
//                 >
//                   Update
//                 </button> */}
//               </div>
//               <div class="modal-footer">
//                 <button
//                   type="button"
//                   class="btn btn-secondary"
//                   data-bs-dismiss="modal"
//                 >
//                   Close
//                 </button>
//                 <button
//                   type="button"
//                   class="btn btn-primary"
//                   onClick={handleUpdateClick}
//                 >
//                   Save changes
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="card rounded cardStyle">
//           {loading ? (
//             <p>Loading...</p>
//           ) : (
//             <DataTable
//               columns={columns}
//               data={columnData[0] || []}
//               pagination
//               customStyles={customStyles}
//               selectableRows
//               onSelectedRowsChange={handleSelectedRowsChange}
//             />
//           )}
//         </div>
//       </div>
//     </React.Fragment>
//   );
// }

// export default LicenseView;


import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function LicenseView() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [validFrom, setValidFrom] = useState("");
  const [validTo, setValidTo] = useState("");
  const [licenseDate, setLicenseDate] = useState("");
  const [id, setId] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [newValidToDate, setNewValidToDate] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
console.log(columnData[0],'')
  const handleDateChange = (e) => {
    const date = e.target.value;
    const [year, month, day] = date.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    setNewValidToDate(formattedDate);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const pathParts = url.pathname.split("/");

    if (pathParts.length >= 6) {
      const id = pathParts[2];
      const licenseDate = pathParts[4];
      const validtofrom = pathParts[5];
      const [from, to] = validtofrom.split("to");
      setId(id);
      setLicenseDate(licenseDate);
      setValidFrom(from);
      setValidTo(to);
    } else {
      console.error("URL does not match the expected format.");
    }
  }, [location]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const fetchData = async () => {
    if (id && validFrom && validTo) {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/${id}/license-details?validFrom=${validFrom}&validTo=${validTo}`
        );
        setColumnData([response?.data?.licenses]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data: ", error);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [id, validFrom, validTo]);

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const exportRowToCSV = (rowData, filename) => {
    const csvContent = [
      ["Date", "Quantity", "Validity", "Device ID", "Status"],
      [
        rowData.date,
        rowData.quantity,
        rowData.validity,
        rowData.deviceid,
        rowData.status,
      ],
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const exportToCSV = (rows, filename) => {
    const csvContent = [
      ["License No", "Valid From", "Valid Upto", "Device ID", "Status"],
      ...rows.map((row) => [
        row.licKey,
        row.validFrom,
        row.validTo,
        row.pcIdAssociated,
        row.lkeyStatus,
      ]),
    ]
      .map((e) => e.join(","))
      .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");

    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const columns = [
    {
      name: "License No",
      selector: (row) => row.licKey,
      sortable: false,
      width: "320px",
    },
    {
      name: "Valid From",
      selector: (row) => row.validFrom,
      sortable: true,
    },
    {
      name: "Valid Upto",
      selector: (row) => row.validTo,
      sortable: true,
    },
    {
      name: "Device ID",
      selector: (row) => (row.pcIdAssociated ? row.pcIdAssociated : "NA"),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => (row?.lkeyStatus ? row?.lkeyStatus : "NA"),
      sortable: true,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleUpdateClick = async () => {
    const licenseKeys = selectedRows.map((row) => row.licKey);
    const newValidTo = newValidToDate; // You might want to format this date to match the required format "09-09-2026"
    const requestBody = {
      licenseKeys,
      newValidTo,
      organizationId: id,
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_NOTIFY_URL}/extend-license-expiry`,
        requestBody
      );
      console.log("Response:", response.status == true);
      setSuccessMessage("License generated successfully!");
      setTimeout(() => {
        setSuccessMessage("");
        window.location.reload();
        setSelectedRows([]);
        document.getElementById("closeModalButton").click(); // Close the modal

        fetchData(); // Refresh data
      }, 2000);
    } catch (error) {
      console.error("Error updating license expiry: ", error);
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">
              License Date: {licenseDate}
            </p>
            <p className="fs-14 p-0 m-0">Quantity: {columnData[0]?.length}</p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
          <button
              type="button"
              className="btn btn-primary text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              disabled={selectedRows.length === 0} // Disable until a checkbox is checked
              style={{
                backgroundColor: "#03A9FC",
                borderRadius: "10px",
                border: "none",
              }}
            >
              Update validity
            </button>
            <button
              type="button"
              className="btn btn-success text-white"
              onClick={() => exportToCSV(columnData[0], "license-data.csv")}
            >
              Export All
            </button>
          
          </div>
        </div>

        {/* <!-- Modal --> */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Upgrade license validity
                </h1>
                {successMessage && (
                  <div className="alert alert-success" role="alert">
                    {successMessage}
                  </div>
                )}
              </div>
              <div className="card-body m-1 p-3">
                <div class="row g-3 align-items-center">
                  <div class="col-auto">
                    <label
                      for="data"
                      class="col-form-label"
                      style={{ color: "black" }}
                    >
                      Selected license:
                    </label>
                  </div>
                  <div class="col-auto">
                    <span class="badge rounded-pill text-bg-secondary text-white" style={{ fontSize: "15px" }}>
                     {selectedRows.length}
                    </span>
                  </div>
                </div>
                <div class="row g-3 align-items-center">
                  <div class="col-auto">
                    <label
                      for="data"
                      class="col-form-label"
                      style={{ color: "black" }}
                    >
                      Valid Upto:
                    </label>
                  </div>
                  <div class="col-auto">
                    <input
                      type="date"
                      className="form-control"
                      value={newValidToDate.split("-").reverse().join("-")} // Reformat back to yyyy-mm-dd for the input field
                      onChange={handleDateChange}
                      required
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id="closeModalButton"
                >
                  Close
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                
                  onClick={handleUpdateClick}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData[0] || []}
              pagination
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default LicenseView;

