import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../layouts/Header";
import axios from "axios";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function ManageOrg() {
  const [skin, setSkin] = useState(
    localStorage.getItem("skin-mode") ? "dark" : ""
  );
  const [loading, setLoading] = useState(true);
  const [organization, setOrganization] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const [newSectionName, setNewSectionName] = useState("");
  const [newSubSectionName, setNewSubSectionName] = useState("");
  const [updatedDepartmentName, setUpdatedDepartmentName] = useState("");
  const [updatedSectionName, setUpdatedSectionName] = useState("");
  const [updatedSubSectionName, setUpdatedSubSectionName] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [editSubSectionId, setEditSubSectionId] = useState(null);

  const { user } = useSelector((state) => state.authSlice);
  const location = useLocation();
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setOrganization(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
        setLoading(false);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const switchSkin = (skin) => {
    const btnWhite = document.getElementsByClassName("btn-white");
    const btnOutlinePrimary = document.getElementsByClassName(
      "btn-outline-primary"
    );

    if (skin === "dark") {
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleAddDepartment = async () => {
    if (!newDepartmentName) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/add-departments/${user?.data?.user?.userId}`,
        { departmentName: newDepartmentName }
      );
      console.log(response,'responseresponse')
      // setSuccessMessage("Department added successfully!");
      toast.success("Department added successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated organization data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
      );

      setOrganization(updatedResponse.data);
      setNewDepartmentName("");
    } catch (error) {
      setErrorMessage(error.response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
      }, 2000);
    }
  };

  const handleSelectDepartment = async (department) => {
    setSelectedDepartment();
    setSelectedSection();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${department._id}/sections`
      );
      setSelectedDepartment({ ...department, sections: response.data });
    } catch (error) {
      console.error("Error fetching sections: ", error);
    }
  };

  const handleSelectSection = async (section) => {
    setSelectedSection(section);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${section._id}/subSections`
      );
      setSelectedSection({ ...section, subSections: response.data });
    } catch (error) {
      console.error("Error fetching subsections: ", error);
    }
  };

  const handleAddSection = async () => {
    if (!newSectionName || !selectedDepartment) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/departments/${selectedDepartment._id}/add-sections/${user?.data?.user?.userId}`,
        { sectionName: newSectionName }
      );
      // setSuccessMessage("Section added successfully!");

      toast.success("Section added successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated department data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedDepartment._id}/sections`
      );
      setSelectedDepartment({
        ...selectedDepartment,
        sections: updatedResponse.data,
      });
      setNewSectionName("");
    } catch (error) {
      setErrorMessage(error.response?.data?.message);
      setTimeout(() => {
        setErrorMessage("");
    }, 2000);
    }
  };

  const handleDeleteSection = async (sectionId) => {
    if (!selectedDepartment) return;
    
    try {
      await axios.delete(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/section/${sectionId}`
      );
      // setSuccessMessage("Section deleted successfully!");
      toast.error("Section deleted successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated department data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedDepartment._id}/sections`
      );
      setSelectedDepartment({
        ...selectedDepartment,
        sections: updatedResponse.data,
      });
      setSelectedSection();
    } catch (error) {
      console.error("Error deleting section: ", error);
    }
  };

  const handleUpdateDepartment = async (department) => {
    if (!updatedDepartmentName) return;
    try {
      await axios.put(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/departments/${department._id}`,
        { departmentName: updatedDepartmentName }
      );
      // setSuccessMessage("Department updated successfully!");
      toast.success("Department updated successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated organization data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
      );

      setOrganization(updatedResponse.data);
      setUpdatedDepartmentName("");
    } catch (error) {
      console.error("Error updating department: ", error);
    }
  };

  // const handleDeleteDepartment = async (departmentId) => {
  //   setSelectedDepartment();
  //   setSelectedSection();
  //   try {
  //     await axios.delete(
  //       `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/${departmentId}`
  //     );
  //     setSuccessMessage("Department deleted successfully!");
  //     setTimeout(() => {
  //       setSuccessMessage("");
  //     }, 2000);

  //     // Fetch updated organization data
  //     const updatedResponse = await axios.get(
  //       `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
  //     );
  //     setOrganization(updatedResponse.data);
      
  //   } catch (error) {
  //     console.error("Error deleting department: ", error);
  //   }
  // };
  const handleDeleteDepartment = async (departmentId) => {
    try {
      await axios.delete(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/${departmentId}`
      );
      // setSuccessMessage("Department deleted successfully!");
      toast.error("Department deleted successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);
  
      // Fetch updated organization data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
      );
      setOrganization(updatedResponse.data);
  
      // Reset selected department and section
      setSelectedDepartment(null);
      setSelectedSection(null);
    } catch (error) {
      console.error("Error deleting department: ", error);
    }
  };
  
  const handleUpdateSection = async (section) => {
    if (!updatedSectionName) return;

    try {
      await axios.put(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/sections/${section._id}`,
        { sectionName: updatedSectionName }
      );
      // setSuccessMessage("Section updated successfully!");
      toast.success("Section updated successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated section data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedDepartment._id}/sections`
      );
      setSelectedDepartment({
        ...selectedDepartment,
        sections: updatedResponse.data,
      });
      setUpdatedSectionName("");
    } catch (error) {
      console.error("Error updating section: ", error);
    }
  };

  const handleAddSubSection = async () => {
    if (!newSubSectionName || !selectedSection) return;

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/${selectedSection._id}/add-sub-sections/${user?.data?.user?.userId}`,
        { subSectionName: newSubSectionName }
      );
      // setSuccessMessage("Subsection added successfully!");

      toast.success("Sub-Section added successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated section data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedSection._id}/subSections`
      );
      setSelectedSection({
        ...selectedSection,
        subSections: updatedResponse.data,
      });
      setNewSubSectionName("");
    } catch (error) {
         setErrorMessage(error.response?.data?.message);
        setTimeout(() => {
          setErrorMessage("");
      }, 2000);
    }
  };

  const handleUpdateSubSection = async (subSection) => {
    console.log("subSection");
    if (!updatedSubSectionName) return;

    try {
      await axios.put(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/subSections/${subSection._id}`,
        { subSectionName: updatedSubSectionName }
      );
      // setSuccessMessage("Subsection updated successfully!");
      toast.success("Sub-Section updated successfully!", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated section data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedSection._id}/subSections`
      );
      setSelectedSection({
        ...selectedSection,
        subSections: updatedResponse.data,
      });
      setUpdatedSubSectionName("");
      setEditSubSectionId(null); // Reset editSubSectionId after update
    } catch (error) {
      console.error("Error updating subsection: ", error);
    }
  };

  const handleDeleteSubSection = async (subSectionId) => {
    if (!selectedSection) return;

    try {
      await axios.delete(
        `${process.env.REACT_APP_NOTIFY_URL}/${organization._id}/sub-section/${subSectionId}`
      );
      // setSuccessMessage("Subsection deleted successfully!");
      toast.error("Sub-Section deleted successfully!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        setSuccessMessage("");
      }, 2000);

      // Fetch updated section data
      const updatedResponse = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/${selectedSection._id}/subSections`
      );
      setSelectedSection({
        ...selectedSection,
        subSections: updatedResponse.data,
      });
    } catch (error) {
      console.error("Error deleting subsection: ", error);
    }
  };

  const [subInput, setSubInput] = useState(false);
  const handleShowupdate = () => {
    setSubInput(!subInput);
  };


  const toggleEditSubSection = (subSectionId) => {
    if (editSubSectionId === subSectionId) {
      setEditSubSectionId(null); // If already editing, close the input box
    } else {
      setEditSubSectionId(subSectionId); // Otherwise, set the current subsection for editing
    }
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Manage Organization</p>
          </span>
          <span>
            {/* {successMessage && <p className="text-success">{successMessage}</p>} */}
          </span>
          {/* {successMessage && (
            <div
              class="toast align-items-center text-bg-primary border-0 fade show"
              role="alert"
              aria-live="assertive"
              aria-atomic="true"
            >
              <div class="d-flex">
                <div class="toast-body">{successMessage}</div>
                <button
                  type="button"
                  class="btn-close btn-close-white me-2 m-auto"
                  data-bs-dismiss="toast"
                  aria-label="Close"
                ></button>
              </div>
            </div>
          )} */}
        </div>

        <div className="card rounded cardStyle">
          <div className=" m-3">
            {loading ? (
              <p>Loading...</p>
            ) : (
              <div className="row">
                <div className="col">
                  <h5 className="card-title p-2  fs-15  fw-bolder lh-3 ff-secondary">Departments</h5>
                  {organization?.departments.map((department) => (
                    <div
                      key={department._id}
                      className={`card mb-3 shadow ${
                        selectedDepartment?._id === department._id
                          ? "bg-primary text-white"
                          : ""
                      }`}
                    >
                      <div className="card-body d-flex justify-content-between align-items-center"  style={{mouse:'cursor'}}   onClick={() => handleSelectDepartment(department)}>
                        <div>
                          <p className="card-title mb-0" >
                            {department.departmentName}{" "}
                          </p>
                          {selectedDepartment &&
                            selectedDepartment._id === department._id && (
                              <div className="form-container">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Update Department Name"
                                    value={updatedDepartmentName}
                                    onChange={(e) =>
                                      setUpdatedDepartmentName(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn btn-primary bg-white ml-2 mt-2"
                                    onClick={() =>
                                      handleUpdateDepartment(department)
                                    }
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                        <div>
                          {/* <button
                            type="button"
                            className="btn btn-primary me-2"
                            onClick={() => handleSelectDepartment(department)}
                          >
                            <i
                              class="fa-solid fa-plus"
                              style={{ color: "#f0f5ff" }}
                            ></i>
                          </button> */}
                          <button
                            type="button"
                            className="btn btn-danger "
                            
                            onClick={() =>
                              handleDeleteDepartment(department._id)
                            }
                          >
                            <i
                              className="fa-solid fa-trash"
                              style={{ color: "#f0f5ff" }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    className="card text-center"
                    style={{ backgroundColor: "#E9EBEF" }}
                  >
                    <div className="card-body">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="New Department Name"
                        value={newDepartmentName}
                        onChange={(e) => setNewDepartmentName(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-secondary mt-2"
                        onClick={handleAddDepartment}
                      >
                        <i
                          className="fa-solid fa-plus"
                          style={{ color: "#949494" }}
                        ></i>{" "}
                        Add Department
                      </button>
                    </div>
                  </div>
                  {/* {successMessage && <p className="text-success">{successMessage}</p>} */}
                  <div>
    {successMessage && <p className="text-success">{successMessage}</p>}
    {errorMessage && <p className="text-danger">{errorMessage}</p>}
  </div>
                </div>
                <div className="col">
                  {selectedDepartment && (
                    <div>
                      <h5 className="card-title p-2   fs-15  fw-bolder lh-3 ff-secondary">
                        Sections in {selectedDepartment.departmentName}
                      </h5>
                      {selectedDepartment.sections.map((section) => (
                        <div
                          key={section._id}
                          className={`card mb-3 shadow ${
                            selectedSection?._id === section._id
                              ? "bg-primary text-white"
                              : ""
                          }`}
                        >
                          <div className="card-body d-flex justify-content-between align-items-center"  onClick={() => handleSelectSection(section)}>
                            <div>
                              <p className="card-title mb-0">
                                {section.sectionName}
                              </p>
                              {selectedSection &&
                                selectedSection._id === section._id && (
                                  <div className="form-container">
                                    <div className="input-group">
                                      <input
                                        type="text"
                                        className="form-control mt-2"
                                        placeholder="Update Section Name"
                                        value={updatedSectionName}
                                        onChange={(e) =>
                                          setUpdatedSectionName(e.target.value)
                                        }
                                      />
                                      <button
                                        className="btn btn-primary bg-white ml-2 mt-2"
                                        onClick={() =>
                                          handleUpdateSection(section)
                                        }
                                      >
                                        <i class="fas fa-save"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div>
                              {/* <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={() => handleUpdateSection(section)}
                              >
                                <i
                                  className="fa-solid fa-pen"
                                  style={{ color: "#f0f5ff" }}
                                ></i>
                              </button> */}
                            
                              {/* <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={() => handleSelectSection(section)}
                              >
                                <i
                                  class="fa-solid fa-plus"
                                  style={{ color: "#f0f5ff" }}
                                ></i>
                              </button> */}
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() => handleDeleteSection(section._id)}
                              >
                                <i
                                  className="fa-solid fa-trash"
                                  style={{ color: "#f0f5ff" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="card text-center"
                        style={{ backgroundColor: "#E9EBEF" }}
                      >
                        <div className="card-body">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="New Section Name"
                            value={newSectionName}
                            onChange={(e) => setNewSectionName(e.target.value)}
                          />
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={handleAddSection}
                          >
                            <i
                              className="fa-solid fa-plus"
                              style={{ color: "#949494" }}
                            ></i>{" "}
                            Add Section
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="col">
                  {selectedSection && (
                    <div>
                      <h5 className="card-title p-2  fs-15  fw-bolder lh-3 ff-secondary">
                        Sub-Sections in {selectedSection.sectionName}
                      </h5>
                      {selectedSection.subSections.map((subSection) => (
                        <div key={subSection._id} className="card mb-3 shadow">
                          <div className="card-body d-flex justify-content-between align-items-center">
                            <div>
                              <p className="card-title mb-0">
                                {subSection.subSectionName}
                              </p>
                              {editSubSectionId === subSection._id  &&  (

                              <div className="form-container">
                                <div className="input-group">
                                  <input
                                    type="text"
                                    className="form-control mt-2"
                                    placeholder="Update Subsection Name"
                                    value={updatedSubSectionName}
                                    onChange={(e) =>
                                      setUpdatedSubSectionName(e.target.value)
                                    }
                                  />
                                  <button
                                    className="btn btn-primary bg-white ml-2 mt-2"
                                    onClick={() =>
                                      handleUpdateSubSection(subSection)
                                    }
                                  >
                                    <i class="fas fa-save"></i>
                                  </button>
                                </div>
                              </div>
                              )}
                            </div>
                            <div>
                            <button
                                className="btn btn-outline-primary me-2"
                                onClick={() =>
                                  toggleEditSubSection(subSection._id)
                                }
                              >
                                {editSubSectionId === subSection._id
                                  ? "Close"
                                  : "Edit"}
                              </button>
                              {/* <button
                                type="button"
                                className="btn btn-primary me-2"
                                onClick={handleShowupdate}
                              >
                                <i
                                  className="fa-solid fa-pen"
                                  style={{ color: "#f0f5ff" }}
                                ></i>
                              </button> */}
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={() =>
                                  handleDeleteSubSection(subSection._id)
                                }
                              >
                                <i
                                  className="fa-solid fa-trash"
                                  style={{ color: "#f0f5ff" }}
                                ></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                      <div
                        className="card text-center"
                        style={{ backgroundColor: "#E9EBEF" }}
                      >
                        <div className="card-body">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="New Subsection Name"
                            value={newSubSectionName}
                            onChange={(e) =>
                              setNewSubSectionName(e.target.value)
                            }
                          />
                          <button
                            type="button"
                            className="btn btn-secondary mt-2"
                            onClick={handleAddSubSection}
                          >
                            <i
                              className="fa-solid fa-plus"
                              style={{ color: "#949494" }}
                            ></i>{" "}
                            Add Subsection
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ManageOrg;
