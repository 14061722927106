import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import { dp3 } from "../data/DashboardData";
import ReactApexChart from "react-apexcharts";
import { PolarArea } from "react-chartjs-2";
import DashCard from "../components/common/DashBoard/DashCard";

import windowIcon from "../assets/img/DashIcon/newWindowIcon.png";
import androidIcon from "../assets/img/DashIcon/newAndroidIcon.png";
import linuxIcon from "../assets/img/DashIcon/newLinuxIcon.png";
import macIcon from "../assets/img/DashIcon/newMacIcon.png";
import chromeIcon from "../assets/img/DashIcon/newChromeIcon.png";
import iosIcon from "../assets/img/DashIcon/NewIOSIcon.svg";

import PageLoader from "../components/common/Loader/PageLoader";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";
export default function DashBoard() {
  const navigate = useNavigate();

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  const seriesEight = [
    {
      type: "column",
      data: [
        [0, 2],
        [1, 3],
        [2, 5],
        [3, 7],
        [4, 12],
        [5, 17],
        [6, 10],
        [7, 14],
        [8, 15],
        [9, 12],
        [10, 8],
      ],
    },
    {
      type: "column",
      data: [
        [0, 12],
        [1, 7],
        [2, 4],
        [3, 5],
        [4, 8],
        [5, 10],
        [6, 4],
        [7, 7],
        [8, 11],
        [9, 9],
        [10, 5],
      ],
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: true },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    
    legend: { show: false },
  };



  
  const [loading, setLoading] = useState(true);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const [windowCount, setWindowCount] = useState(0);
  const [linuxCount, setLinuxCount] = useState(0);
  const [androidCount, setAndroidCount] = useState(0);
  const [macCount, setMacCount] = useState(0);
  const [chromeOsCount, setChromeOsCount] = useState(0);

  
  const [windowCountRisk,setwindowCountRisk] = useState(0);
  const [windowCountUnRisk,setwindowCountUnRisk] = useState(0);

  const [linuxCountRisk,setLinuxCountRisk] = useState(0);
  const [linuxCountUnRisk,setLinuxCountUnRisk] = useState(0);

  const [androidCountRisk,setAndroidCountRisk] = useState(0);
  const [androidCountUnRisk,setAndroidUnCountRisk] = useState(0);

  const [chromeCountRisk,setChromeCountRisk] = useState(0);
  const [chromeCountUnRisk,setChromeCountUnRisk] = useState(0);

  const [macCountRisk,setMacCountRisk] = useState(0);
  const [macCountUnRisk,setMacCountUnRisk] = useState(0);
  
  const [TotalDashboard,setTotalDashboard] =useState(0)
  
  console.log(TotalDashboard,'TotalDashboard')
 
  const [summoryCount, setSummoryCount] = useState({});
  const dispatch = useDispatch();

  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  var windows_unsafe_per = (windowCountRisk / (windowCountRisk + windowCountUnRisk)) * 100;
  var linux_unsafe_per = (linuxCountRisk / (linuxCountRisk + linuxCountUnRisk)) * 100;
  var android_unsafe_per = (androidCountRisk / (androidCountRisk + androidCountUnRisk)) * 100;
  var chrome_unsafe_per = (chromeCountRisk / (chromeCountRisk + chromeCountUnRisk)) * 100;
  var macc_unsafe_per = (macCountRisk / (macCountRisk + macCountUnRisk)) * 100;
  
console.log(windows_unsafe_per,linux_unsafe_per,android_unsafe_per,chrome_unsafe_per,macc_unsafe_per,'line------------')
console.log(chromeCountRisk, chromeCountUnRisk,'chromeCountRisk,chromeCountUnRisk')
  console.log(chrome_unsafe_per,'chrome_unsafe_per')
  useEffect(() => {
    if (!isAuthenticated) {
      // User not authenticated, redirect to login page
      navigate("/login");
    } else {
      // Check token expiration time

      const tokenExpirationTimer = setTimeout(() => {
        // Token has expired, log the user out
        dispatch(logout()); // Dispatch logout action to clear user data and token
        navigate("/signin");
      }, 24 * 60 * 60 * 1000); // Convert expiresIn to milliseconds

      return () => clearTimeout(tokenExpirationTimer);
    }
  }, [isAuthenticated, user, navigate, dispatch]);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    let isMounted = true; // Variable to track mount status

    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_SUMMARY_URL}/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )

        .then((res) => {
          if (isMounted) {
            console.log(res?.data,'rrrrrrrrrrrrrrrrrrrrrrrrr')
            setChromeCountRisk(res?.data?.chromeOsSummaryInfo?.getUnsafeDeviceCount)
            setMacCountRisk(res?.data?.mac?.UnsafemacClients)
            setAndroidCountRisk(res?.data?.android?.getUnsafeDeviceCount)
            setLinuxCountRisk(res?.data?.linux?.UnsafelinuxClients)
            setwindowCountRisk(res?.data?.windows?.UnsafeWindowsClients)

            setChromeCountUnRisk(res?.data?.chromeOsSummaryInfo?.getSafeDeviceCount)
            setMacCountUnRisk(res?.data?.mac?.SafemacClients)
            setAndroidUnCountRisk(res?.data?.android?.getSafeDeviceCount)
            setLinuxCountUnRisk(res?.data?.linux?.SafelinuxClients)
            setwindowCountUnRisk(res?.data?.windows?.SafeWindowsClients)

            setTotalDashboard(res?.data?.totalDevicesCount)
             
               
            setWindowCount(res?.data?.windows?.getTotalPCCount);
            setLinuxCount(res?.data?.linux?.getTotalPCCount);
            setAndroidCount(res?.data?.android?.androidDevicesCount);
            setMacCount(res?.data?.mac?.macPcCount);
            setChromeOsCount(res?.data?.chromeOsSummaryInfo?.chromeDevicesCount)
          }
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 404:
                console.log("Resource not found (404)");
                break;
              case 500:
                console.log("Internal server error (500)");
                break;
              case 504:
                console.log("Gateway timeout (504)");
                break;
              default:
                console.log(`Error: ${statusCode}`);
            }
          } else if (error.request) {
            console.log("No response received");
          } else {
            console.log("Error", error.message);
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoading(false);
            // Schedule the next fetchData call after 50 seconds
            setTimeout(fetchData, 50000);
          }
        });
    };

    fetchData(); // Initial fetchData call

    // Cleanup function to set isMounted to false when component unmounts
    return () => {
      isMounted = false;
    };
  }, [user]);




  return (
    
    <React.Fragment>
      <Header onSkin={setSkin} />
      {loading ? (
        <div className={`overlay ${loading ? "active" : ""}`}>
          <PageLoader className="loader" />
        </div>
      ) : (
        <div className="main main-app p-3 p-lg-4">
          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <ol className="breadcrumb fs-sm mb-1">
                <li className="breadcrumb-item">
                  <Link to="#">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Organization Overview
                </li>
              </ol>
              <h4 className="main-title mb-0 fs-18 fw-bolder p-0 m-0 ">Welcome to Dashboard</h4>
            </div>
            <div className="d-flex gap-2 mt-3 mt-md-0">
              {/* <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-share-line fs-18 lh-1"></i>Share
              </Button> */}
              <Button
                variant=""
                className="btn-white d-flex align-items-center gap-2"
              >
                <i className="ri-printer-line fs-18 lh-1"></i>Print
              </Button>
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
              >
                <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
                <span className="d-none d-sm-inline"> Report</span>
              </Button>
            </div>
          </div>
          <Row className="g-3">
            <Col xl="4">
              <DashCard
                dashIcon={windowIcon}
                dashNumber={windowCount}
                handleRoute={() => navigate("/window-dash")}
                dashTitle={"Windows Endpoints"}
                riskNumber={windowCountRisk}
                riskPerc={windows_unsafe_per ? windows_unsafe_per :'0'}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={linuxIcon}
                dashNumber={linuxCount}
                handleRoute={() => navigate("/linux-dash")}
                dashTitle={"Linux Endpoints"}
                riskNumber={linuxCountRisk}
                riskPerc={linux_unsafe_per ? linux_unsafe_per :'0'}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={macIcon}
                dashNumber={macCount}
                dashTitle={"Mac Endpoints"}
                handleRoute={() => navigate("/macOs-dash")}
                riskNumber={macCountRisk}
                riskPerc={macc_unsafe_per ? macc_unsafe_per :'0'}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={chromeIcon}
                dashNumber={chromeOsCount}
                dashTitle={"ChromeOS Endpoints"}
                handleRoute={() => navigate("/chrome-dash")}
                riskNumber={chromeCountRisk}
                riskPerc={chrome_unsafe_per ? chrome_unsafe_per :'0'}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={androidIcon}
                dashNumber={androidCount}
                handleRoute={() => navigate("/android-dash")}
                dashTitle={"Android Endpoints"}
                riskNumber={androidCountRisk}
                riskPerc={android_unsafe_per ? android_unsafe_per :'0'}
              />
            </Col>
            <Col xl="4">
              <DashCard
                dashIcon={iosIcon}
                dashNumber={summoryCount?.androidCount}
                handleRoute={() => navigate("/")}
                dashTitle={"iOS Endpoints"}
                riskNumber={236}
                riskPerc={77}
              />
            </Col>

            <Col xl="8">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Cyber Violation Analysis</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="overflow-hidden px-0 pb-3">
                  <ReactApexChart
                    series={seriesOne}
                    options={optionOne}
                    type="area"
                    height={430}
                    className="apex-chart-two"
                  />
                </Card.Body>
              </Card>
            </Col>

            <Col md="4" xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Violations Categories</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-3">
                  <div className="ht-250">
                    <PolarArea data={dataPolar} options={optionPolar} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="8">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Department Cyber Score</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="g-4">
                    <Col md="12">
                      <ReactApexChart
                        series={seriesEight}
                        options={optionEight}
                        height={210}
                        className="apex-chart-three"
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col xl="4">
              <Card className="card-one shadow p-3">
                <Card.Header>
                  <Card.Title as="h6">Total Devices</Card.Title>
                  <Nav className="nav-icon nav-icon-sm ms-auto">
                    <Nav.Link href="">
                      <i className="ri-refresh-line"></i>
                    </Nav.Link>
                    <Nav.Link href="">
                      <i className="ri-more-2-fill"></i>
                    </Nav.Link>
                  </Nav>
                </Card.Header>
                <Card.Body className="position-relative d-flex justify-content-center">
                  <ReactApexChart
                    series={[windowCount,linuxCount,macCount,androidCount,chromeOsCount]}
                    options={optionDonut}
                    height="auto"
                    type="donut"
                    className="apex-donut-two"
                  />

                  <div className="finance-donut-value">
                    <h1>{TotalDashboard}</h1>
                    {/* <p>86.24%</p> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Footer />
        </div>
      )}
    </React.Fragment>
  );
}
