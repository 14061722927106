import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu } from "antd";

function CustomTableAndroid({
  rowData,
  routeUrl,
  currentPage,
  pageSize,
  handlePageChange,
  handlePageSizeChange,
  totalDocuments,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const navigate = useNavigate();
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const handleMenuClick = (e, record) => {
    console.log("Click on menu item:", e, "for record:", record);
  };

  const menu = (record) => {
    return (
      <Menu onClick={(e) => handleMenuClick(e, record)}>
        {record?.ipAddress?.map((v, index) => (
          <Menu.Item key={index}>{v}</Menu.Item>
        ))}
      </Menu>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        name: "AndroidID",
        // selector: (_, index) => (currentPage - 1) * pageSize + index + 1,
        selector: (row) => row.androidId,
        
      },
      {
        name: "Android Version",
        selector: (row) => row.androidVersion,
        sortable: true,
      },
      {
        name: "Brand Name",
        selector: (row) => row.brandName,
        sortable: true,
      },
      {
        name: "Device IP",
        selector: (row) => row.deviceIp,
        sortable: true,
      },
    //   {
    //     name: "IP Address",
    //     selector: (row) => row.ipAddress,
    //     cell: (row) => (
    //       <Dropdown overlay={menu(row)} trigger={["click"]}>
    //         <Button className="bg-transparent border-0 p-0 m-0">
    //           {row.ipAddress[0] || "NA"} <DownOutlined />
    //         </Button>
    //       </Dropdown>
    //     ),
    //     sortable: true,
    //   },
      {
        name: "Action",
        cell: (row) => (
          <Button
            className="btn rounded-md w-100 border-0 d-flex align-items-center fs10 justify-content-center gap-2 mx-auto px-2 text-light bg-dark"
            onClick={() =>
              navigate(routeUrl, {
                state: {
                  basicInfo: row,
                  pcInfoId: row?.androidId,
                },
              })
            }
          >
            <p className="m-0 p-0 fs-12">View Details</p>
            <i className="fas fa-long-arrow-alt-right ps-4"></i>
          </Button>
        ),
        ignoreRowClick: true,
        allowoverflow: false,
      },
    ],
    [currentPage, pageSize, navigate, routeUrl]
  );

//   const customStyles = {
//     headCells: {
//       style: {
//         padding: '10px', // Adjust padding as needed
//         marginRight: '60px', // Add margin to create a gap
//       },
//     },
//     cells: {
//       style: {
//         padding: '10px',
//         marginRight: '60px' // Ensure cells have the same padding
//       },
//     },
//   };


  
  return (
    <DataTable
      columns={columns}
      data={rowData || []}
      pagination
      paginationServer
      paginationTotalRows={totalDocuments}
      paginationDefaultPage={currentPage}
      paginationPerPage={pageSize}
      onChangePage={(page) => {
        console.log("Page", page);
        handlePageChange(page);
      }}
      onChangeRowsPerPage={(newPageSize) => {
        console.log("Page Size", newPageSize);
        handlePageSizeChange(newPageSize);
      }}
    //   customStyles={customStyles} // Add custom styles here
      noDataComponent={<p className="p-0 my-2 me-auto">No Data Found</p>}
    />
  );
}

export default CustomTableAndroid;
