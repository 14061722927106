import React, { useEffect, useState } from "react";
import { Button, Card, Col, Nav, ProgressBar, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../layouts/Footer";
import Header from "../../layouts/Header";
import { dp3 } from "../../data/DashboardData";
import windowIcon from "../../assets/img/DashIcon/windowIcon.png";
import linuxIcon from "../../assets/img/DashIcon/newLinuxIcon.png";
import ReactApexChart from "react-apexcharts";
import Alerts from "../../components/common/Alerts";
import avBlueIcon from "../../assets/img/Alerts/shieldBlue.png";
import avRedIcon from "../../assets/img/Alerts/shieldRed.png";
import avBlackIcon from "../../assets/img/Alerts/shieldBlack.png";
import ServerityOkIcon from "../../assets/img/ServerityIcon/allOk.png";
import ServerityGreenIcon from "../../assets/img/ServerityIcon/greenOk.png";
import ServerityRedIcon from "../../assets/img/ServerityIcon/redOk.png";
import ServerityYellowIcon from "../../assets/img/ServerityIcon/yellowOk.png";
import windowOkIcon from "../../assets/img/ServerityIcon/blueRec.png";
import windowGreenIcon from "../../assets/img/ServerityIcon/greenRec.png";
import windowRedIcon from "../../assets/img/ServerityIcon/redRec.png";
import windowOYellowIcon from "../../assets/img/ServerityIcon/yellowRec.png";
import DetailCard from "../../components/common/DashBoard/detailCard";

import edrIcon from "../../assets/img/Alerts//edrIcon.png";

import napIcon from "../../assets/img/Alerts/NapIcon.png";

import malwareIcon from "../../assets/img/Alerts/malwareIcon.png";

import fireIcon from "../../assets/img/Alerts/fireIcon.png";

import usbIcon from "../../assets/img/Alerts/usbIcon.png";

import closeAlertIcon from "../../assets/img/Alerts/closeAlertIcon.png";
import IssueAlert from "../../components/common/IssueAlert";
import useFetchWindowSummaryData from "../../hooks/windowsHooks/useFetchWindowSummary";
import PageLoader from "../../components/common/Loader/PageLoader";
import {
  ANTIVIRUS_NOT_INSTALLED_ENDPOINT,
  ANTI_VIRUS_MALFUNCTION_ENDPOINT,
  ANTI_VIRUS_NOT_UPDATED_ENDPOINT,
  EDR_MALFUNCTION_ENDPOINT,
  EDR_NOT_INSTALLED_ENDPOINT,
  FIRWALL_OFF,
  MALWARE_DETECTION_ENDPOINT,
  NAC_NAP,
  OS_PATCHED,
  USB_VOILATION,
  WINDOW_NOT_ACTIVATED,
} from "../../api/endPoints/windowAlertEndPoint";
import LinuxClients from "../../components/common/clients/linuxClients/LinuxClients";
import useFetchLinuxSummary from "../../hooks/linuxhooks/useFetchLinuxSummary";
import {
  BIOS_BATTERY,
  BLUETOOTH_ENABLED,
  CD_DRIVE_ENABLED,
  IS_HIGH_CPU_USAGE,
  IS_HIGH_RAM_USAGE,
  LINUX_BLACKLIST,
  LINUX_FIREWALL_OFF,
  LINUX_NIC,
  LINUX_NOT_ACTIVATED,
  LINUX_OS_NOT_PATCH,
  MULTIPLE_PASSWORD,
  NAC_DISABLED,
  OLD_PASSWORD,
  OPEN_NETWORK_CONNECTION,
  OUT_OF_DOMAIN,
  RDP_ENABLED,
  SCANNER_INSTALLED,
  SHARED_FOLDER,
  TOTAL_USB_VOILATION,
  UNKNOWN_SERVICE,
  WIFI_ENABLED,
  TOTAL_LINUX_CLIENT,
  TOTAL_SAFE_LINUX,
  TOTAL_UNRESPONSIVE_LINUX,
  TOTAL_UNSAFE_LINUX,
} from "../../api/endPoints/linuxAlertEndPoint";
import CustomToaster from "../../components/common/CustomToaster";
import { Input } from "antd";
import { useDispatch } from "react-redux";
import { showToaster } from "../../feature/slices/toastSlice";

export default function LinuxDetail() {
  const dispatch = useDispatch();

  const {
    loading,
    linuxSummary,
    linuxAlertSummary,
    handleReq = "",
    totalDashboard
  } = useFetchLinuxSummary();

  const dataPolar = {
    datasets: [
      {
        data: [50, 25, 60, 80, 20],
        backgroundColor: [
          "#506fd9",
          "#85b6ff",
          "#d3dbf6",
          "#6e7985",
          "#dbdde1",
        ],
      },
    ],
  };

  const optionPolar = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true,
    },
  };

  const seriesOne = [
    {
      name: "series1",
      data: dp3,
    },
    {
      name: "series2",
      data: dp3,
    },
  ];

  const optionOne = {
    chart: {
      parentHeightOffset: 0,
      type: "area",
      toolbar: { show: false },
      stacked: true,
    },
    colors: ["#4f6fd9", "#506fd9"],
    grid: {
      borderColor: "rgba(72,94,144, 0.08)",
      padding: { top: -20 },
      yaxis: {
        lines: { show: false },
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 0],
    },
    xaxis: {
      type: "numeric",
      tickAmount: 13,
      axisBorder: { show: false },
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      show: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0.5,
        opacityTo: 0,
      },
    },
    dataLabels: { enabled: false },
    legend: { show: false },
    tooltip: { enabled: false },
  };

  const seriesEight = [
    {
      type: "column",
      data: [
        [0, 2],
        [1, 3],
        [2, 5],
        [3, 7],
        [4, 12],
        [5, 17],
        [6, 10],
        [7, 14],
        [8, 15],
        [9, 12],
        [10, 8],
      ],
    },
    {
      type: "column",
      data: [
        [0, 12],
        [1, 7],
        [2, 4],
        [3, 5],
        [4, 8],
        [5, 10],
        [6, 4],
        [7, 7],
        [8, 11],
        [9, 9],
        [10, 5],
      ],
    },
  ];

  const optionEight = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      type: "line",
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
        bottom: -15,
      },
    },
    states: {
      hover: {
        filter: { type: "none" },
      },
      active: {
        filter: { type: "none" },
      },
    },
    colors: ["#506fd9", "#e5e9f2"],
    plotOptions: {
      bar: {
        columnWidth: "40%",
        endingShape: "rounded",
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    yaxis: {
      min: 0,
      max: 30,
      tickAmount: 5,
    },
    xaxis: {
      labels: {
        style: {
          colors: "#6e7985",
          fontSize: "10px",
          fontWeight: "500",
        },
      },
    },
    tooltip: { enabled: false },
    legend: { show: false },
  };

  const optionDonut = {
    chart: {
      parentHeightOffset: 0,
    },
    colors: ["#506fd9", "#85b6ff", "#51596d", "#eff1f5"],
    dataLabels: { enabled: false },
    grid: {
      padding: {
        top: 0,
        bottom: 0,
      },
    },
    legend: { show: false },
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  if (handleReq === "LINUX_USB_TRIGGER") {
    dispatch(
      showToaster({
        title: "USB",
        message: "USB VIOLATION",
        color: "warning",
      })
    );
  }

  if (handleReq === "LINUX_INSTALLATION_TRIGGER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "New Device Register",
        color: "success",
      })
    );
  }

  if (handleReq === "NO_DEVICE_REGISTER") {
    dispatch(
      showToaster({
        title: "Device",
        message: "No Device Register",
        color: "danger",
      })
    );
  }

  console.log(handleReq, "REQUESTHANDLEREQ");


  var TotalLinuxEndpoint = (linuxSummary?.totalClients / totalDashboard) * 100;

var totalLinuxCount = linuxSummary?.totalSafeClients + linuxSummary?.totalUnsafeClients + linuxSummary?.totalUnresponsivelients;
 
var linux_Safe_Per = (linuxSummary?.totalSafeClients /linuxSummary?.totalClients ) * 100;
var linux_UnSafe_Per = (linuxSummary?.totalUnsafeClients / linuxSummary?.totalClients) * 100;
var linux_UnResponsive_Per = (linuxSummary?.totalUnresponsivelients / linuxSummary?.totalClients) * 100;

linux_Safe_Per = Math.round(linux_Safe_Per);
linux_UnSafe_Per = Math.round(linux_UnSafe_Per);
linux_UnResponsive_Per = Math.round(linux_UnResponsive_Per);
TotalLinuxEndpoint = Math.round(TotalLinuxEndpoint);

console.log(linux_Safe_Per,linux_UnSafe_Per,linux_UnResponsive_Per,TotalLinuxEndpoint,'qqqqqqqqqqqqqqqqqqq')
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <span className="d-flex align-items-center justify-content-center fs-sm-normal mb-1 ps-2">
            <Link to="/linux-dash">
              <img src={linuxIcon} alt="Window-logo" width={55} height={55} />
            </Link>
            <p className="mx-2 fs-18 m-0 p-0">|</p>
            <p className="fs-18 fw-bolder p-0 m-0 ">Linux DashBoard</p>
          </span>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-share-line fs-18 lh-1"></i>Share
            </Button>
            <Button
              variant=""
              className="btn-white d-flex align-items-center gap-2"
            >
              <i className="ri-printer-line fs-18 lh-1"></i>Print
            </Button>

            {/* <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
            >
              <i className="ri-bar-chart-2-line fs-18 lh-1"></i>Generate
              <span className="d-none d-sm-inline"> Report</span>
            </Button> */}
          </div>
        </div>
        {!loading ? (
          <>
            <Row className="g-3">
              <Col xl="3">
                <DetailCard
                  severityName={"Total Linux Endpoints"}
                  severityIcon={ServerityOkIcon}
                  severityNumber={linuxSummary?.totalClients || 0}
                  cardIcon={windowOkIcon}
                  severityColor={"primary"}
                  apiUrl={TOTAL_LINUX_CLIENT}
                  routeUrl={"/linux-serverity"}
                  cardIndex={0}
                  riskAt={TotalLinuxEndpoint}
                  footerTitle={"endpoints are Linux"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Safe Linux Endpoints"}
                  severityIcon={ServerityGreenIcon}
                  severityNumber={linuxSummary?.totalSafeClients || 0}
                  cardIcon={windowGreenIcon}
                  severityColor={"success"}
                  apiUrl={TOTAL_SAFE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={1}
                  riskAt={linux_Safe_Per ? linux_Safe_Per :'0'}
                  footerTitle={"endpoints are secure"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unsafe Linux Endpoints"}
                  severityIcon={ServerityRedIcon}
                  severityNumber={linuxSummary?.totalUnsafeClients || 0}
                  cardIcon={windowRedIcon}
                  severityColor={"danger"}
                  apiUrl={TOTAL_UNSAFE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={2}
                  riskAt={linux_UnSafe_Per ? linux_UnSafe_Per : '0'}
                  footerTitle={"endpoints at risk"}
                />
              </Col>
              <Col xl="3">
                <DetailCard
                  severityName={"Unresponsive Linux Endpoints"}
                  severityIcon={ServerityYellowIcon}
                  severityNumber={
                    linuxSummary?.totalUnresponsivelients || 0
                  }
                  cardIcon={windowOYellowIcon}
                  severityColor={"warning"}
                  apiUrl={TOTAL_UNRESPONSIVE_LINUX}
                  routeUrl={"/linux-serverity"}
                  cardIndex={3}
                  riskAt={linux_UnResponsive_Per ? linux_UnResponsive_Per : '0'}
                  footerTitle={"endpoints Unresponsive"}
                />
              </Col>
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlueIcon}
                          alertTitle={"AV Not Installed"}
                          alertNumber={linuxAlertSummary?.avNotInstalled}
                          apiUrl={ANTIVIRUS_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avRedIcon}
                          alertTitle={"AV Malfunction"}
                          alertNumber={linuxAlertSummary?.avMalfunction}
                          apiUrl={ANTI_VIRUS_MALFUNCTION_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={avBlackIcon}
                          alertTitle={"AV Not Updated"}
                          alertNumber={linuxAlertSummary?.avNotUpdated}
                          apiUrl={ANTI_VIRUS_NOT_UPDATED_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Not Installed"}
                          alertNumber={linuxAlertSummary?.edrNotInstalled}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={edrIcon}
                          alertTitle={"EDR Malfunction"}
                          alertNumber={linuxAlertSummary?.edrMalfunction}
                          apiUrl={EDR_MALFUNCTION_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={windowIcon}
                          alertTitle={"OS Not Patched"}
                          alertNumber={linuxAlertSummary?.osNotPatched}
                          apiUrl={LINUX_OS_NOT_PATCH}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={napIcon}
                          alertTitle={"NAP/NAC Disabled"}
                          alertNumber={linuxAlertSummary?.napnacDisabled}
                          apiUrl={NAC_DISABLED}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={malwareIcon}
                          alertTitle={"Malware detected"}
                          alertNumber={linuxAlertSummary?.malawareDetected}
                          apiUrl={MALWARE_DETECTION_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={fireIcon}
                          alertTitle={"Firewall OFF"}
                          alertNumber={linuxAlertSummary?.firwallOff}
                          apiUrl={LINUX_FIREWALL_OFF}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={usbIcon}
                          alertTitle={"USB Violations"}
                          alertNumber={linuxAlertSummary?.usbViolation}
                          apiUrl={TOTAL_USB_VOILATION}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={closeAlertIcon}
                          alertTitle={"Blacklisted Software"}
                          alertNumber={linuxAlertSummary?.blacklistedSoftware}
                          apiUrl={LINUX_BLACKLIST}
                          routeUrl={"/linux-serverity"}
                          controlApi={true}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              {/* <Col xl="3">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Critical Issues Analysis</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                      <Nav.Link href="">
                        <i className="ri-refresh-line"></i>
                      </Nav.Link>
                      <Nav.Link href="">
                        <i className="ri-more-2-fill"></i>
                      </Nav.Link>
                    </Nav>
                  </Card.Header>
                  <Card.Body className="d-flex flex-column align-items-center justify-content-around">
                    <div className="position-relative d-flex justify-content-center">
                      <ReactApexChart
                        series={[35, 20, 20, 15]}
                        options={optionDonut}
                        height="auto"
                        type="donut"
                        className="apex-donut-two"
                      />

                      <div className="finance-donut-value">
                        <h1>1.8B</h1>
                        <p>86.24%</p>
                      </div>
                    </div>
                    <Row>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#506FD9"}
                          issueName={"Critical Alerts"}
                          issueNumber={150}
                          issuePerc={50}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#85B6FF"}
                          issueName={"Critical Alerts"}
                          issueNumber={50}
                          issuePerc={10}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#50586D"}
                          issueName={"Critical Alerts"}
                          issueNumber={40}
                          issuePerc={8}
                        />
                      </Col>
                      <Col xl={6}>
                        <IssueAlert
                          issueColor={"#B9BDC9"}
                          issueName={"Critical Alerts"}
                          issueNumber={60}
                          issuePerc={12}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col> */}
            </Row>

            <Row className="g-3 my-2">
              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">High Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                    <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Unrecongnized Software"}
                          alertNumber={linuxAlertSummary?.osNotPatched}
                          apiUrl={UNKNOWN_SERVICE}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Unknown Services"}
                          alertNumber={linuxAlertSummary?.unknownServices}
                          apiUrl={UNKNOWN_SERVICE}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Old Admin Passwords"}
                          alertNumber={linuxAlertSummary?.oldAdminPasswords}
                          apiUrl={OLD_PASSWORD}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Multiple Admins"}
                          alertNumber={linuxAlertSummary?.mulitpleAdmins}
                          apiUrl={MULTIPLE_PASSWORD}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"UAC Disabled"}
                          alertNumber={0}
                          apiUrl={EDR_NOT_INSTALLED_ENDPOINT}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Shared Folders Detected"}
                          alertNumber={linuxAlertSummary?.sharedFolder}
                          apiUrl={SHARED_FOLDER}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Bluetooth Enabled"}
                          alertNumber={linuxAlertSummary?.blueToothCount}
                          apiUrl={BLUETOOTH_ENABLED}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Wi-Fi Enabled"}
                          alertNumber={linuxAlertSummary?.wifiPcCount}
                          apiUrl={WIFI_ENABLED}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Out of Domain"}
                          alertNumber={linuxAlertSummary?.outOfDomain}
                          apiUrl={OUT_OF_DOMAIN}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"RDP Enabled"}
                          alertNumber={linuxAlertSummary?.rdpEnabled}
                          apiUrl={RDP_ENABLED}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>

              <Col xl="12">
                <Card className="card-one shadow p-3">
                  <Card.Header>
                    <Card.Title as="h6">Minor Alerts</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Row className="g-3 justify-content-start">
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"CD/DVD Drive Enabled"}
                          alertNumber={linuxAlertSummary?.cdDriveEnabled}
                          apiUrl={CD_DRIVE_ENABLED}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"BIOS Battery Health"}
                          alertNumber={linuxAlertSummary?.biosBatteryHealth}
                          apiUrl={BIOS_BATTERY}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Scanner Installed"}
                          alertNumber={linuxAlertSummary?.scannerInstalled}
                          apiUrl={SCANNER_INSTALLED}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"High RAM Usage"}
                          alertNumber={linuxAlertSummary?.highRamUsage}
                          apiUrl={IS_HIGH_RAM_USAGE}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"High CPU Usage"}
                          alertNumber={linuxAlertSummary?.highCpuUsage}
                          apiUrl={IS_HIGH_CPU_USAGE}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"OS Formatted"}
                          alertNumber={0}
                          apiUrl={OS_PATCHED}
                          routeUrl={"/linux-serverity"}
                          controlApi={false}
                        />
                      </Col>

                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Hardware Changed"}
                          alertNumber={linuxAlertSummary?.napnacDisabled}
                          apiUrl={NAC_NAP}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                      <Col xl="3">
                        <Alerts
                          alertIcon={linuxIcon}
                          alertTitle={"Open Network Connection"}
                          alertNumber={linuxAlertSummary?.openNetworkConnection}
                          apiUrl={OPEN_NETWORK_CONNECTION}
                          routeUrl={"/linux-serverity"}
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Footer />
            {/* <CustomToaster /> */}
          </>
        ) : (
          <PageLoader />
        )}
      </div>
    </React.Fragment>
  );
}
