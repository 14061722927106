import React, { useEffect, useState } from "react";

import { Button, Dropdown, Table } from "react-bootstrap";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate, NavLink } from "react-router-dom";
import endpoint from "../../../assets/img/Cadmin/endpoint.png";
import lincence from "../../../assets/img/Cadmin/lincence.png";
import multUsers from "../../../assets/img/Cadmin/multUsers.png";
import orgId from "../../../assets/img/Cadmin/orgId.png";
import payment from "../../../assets/img/Cadmin/payment.png";
import subscription from "../../../assets/img/Cadmin/subscription.png";
import superAdmin from "../../../assets/img/Cadmin/superAdmin.png";
import joining from "../../../assets/img/Cadmin/joining.png";
import superAphone from "../../../assets/img/Cadmin/superAphone.png";
import SuperEmail from "../../../assets/img/Cadmin/SuperEmail.png";
import controls from "../../../assets/img/Cadmin/controls.png";

function OrgDetail() {
  const navigate = useNavigate();
  const [orgDetails, setOrgDetails] = useState(null);
  const [orgLicence, setOrgLicence] = useState(null);
  const [endpoints, setEndpoints] = useState(null);
  const [users, setUsers] = useState(null);
  const [superDetails, setSuperDetails] = useState([]);
  const [superValid, setSuperValid] = useState(null);

  const url = new URL(window.location.href);
  const id = url.pathname.split("/").pop();
  console.log(orgDetails, "orgDetails");
  console.log(superDetails, "superDetails");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_NOTIFY_URL}/details-get-by/${id}`)
      .then((response) => response.json())
      .then((data) => {
        setOrgDetails(data?.organization);
        setOrgLicence(data?.licenseCounts);
        setEndpoints(data?.totalEndPointCount || "NA");
        setUsers(data?.users || "NA");
        setSuperValid(data?.orgValid || "NA");

        setSuperDetails({
          email: data?.users?.[0]?.email,
          fname: data?.users?.[0]?.fname,
          lname: data?.users?.[0]?.lname,
          phone: data?.users?.[0]?.phone,
        });
      })
      .catch((error) => {
        console.error("Error fetching organization details:", error);
      });
  }, [id]);

  const basicData = [
    { name: "Organization ID", value: "254", icons: endpoint },
    { name: "Joining Date", value: "11-07-2024", icons: "" },
    { name: "Valid Till", value: "11-09-2024", icons: "" },
    { name: "Super Admin Name", value: "sadanand", icons: "" },
    { name: "Super Admin phone", value: "96868686454", icons: "" },
    { name: "Super Admin Email", value: "admin@gmail.com", icons: "" },
  ];
  const licData = [
    { name: "Total Licenses", value: "2540" },
    { name: "Used Licenses", value: "1500" },
    { name: "Active Licenses", value: "1000" },
    { name: "Expired Licenses", value: "400" },
  ];

  const paymentInfo = [
    { name: "Payment Mode", value: "Credit Card" },
    { name: "Payment With", value: "4153xxxxxxxxxx90" },
    { name: "Payment Amount", value: "10000" },
    { name: "Payment Date", value: "14-Jan-24 11:23:16 AM" },
  ];

  const paymentHistory = [
    {
      refrenceId: "#4521458752",
      dates: "14/05/2024",
      mode: "UPI",
      amout: "₹36,000",
    },
    {
      refrenceId: "#4521458752",
      dates: "14/05/2024",
      mode: "UPI",
      amout: "₹36,000",
    },
    {
      refrenceId: "#4521458752",
      dates: "14/05/2024",
      mode: "UPI",
      amout: "₹36,000",
    },
    {
      refrenceId: "#4521458752",
      dates: "14/05/2024",
      mode: "UPI",
      amout: "₹36,000",
    },
  ];

  const columns = [
    {
      name: "Reference Id",
      selector: (row) => row.refrenceId,
      sortable: true,
    },
    {
      name: "Dates",
      selector: (row) => row.dates,
      sortable: true,
    },
    {
      name: "Mode",
      selector: (row) => row.mode,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amout,
      sortable: true,
    },
    {
      name: "",
      cell: (row) => (
        <Button className="bg-dark text-light rounded-circle border-0">
          <i className="ri-arrow-right-line"></i>
        </Button>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const getStatusStyle = (status) => {
    if (status === "Active") return { color: "green" };
    if (status === "Inactive") return { color: "red" };
    return { color: "orange" };
  };

  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const { state } = useLocation();

  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year}`;
  };
  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <ol className="breadcrumb fs-sm mb-1">
          <li className="breadcrumb-item">
            <NavLink exact to="/" activeClassName="active">
              Admin
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to="/organizations" activeClassName="active">
              Organizations
            </NavLink>
          </li>
          <li className="breadcrumb-item">
            <NavLink exact to={`/org-detail/${id}`} activeClassName="active">
              {id}
            </NavLink>
          </li>
        </ol>
        <div className=" d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded      cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column ">
            <Link to="/linux-dash">
              {/* <img src='' alt="Window-logo" width={55} height={55} /> */}
            </Link>
            <p className="fs-18 fw-bolder p-0 m-0 ">
              {orgDetails?.organizationName || "NA"}
            </p>
          </span>

          <div className="d-flex align-items-center justify-content-between gap-2">
            <Button variant="contained bg-success text-light">
              {orgDetails?.status || "NA"}
            </Button>
          </div>
        </div>

        {/* <div class="mb-4 card card-one py-4 rounded  border-2 cardStyle">
          <div className="card-header col-12 text-center d-flex align-items-center justify-content-center">
            <p className="fs-18 fw-bolder p-0 m-0 text-center ">
              Basic Details
            </p>
          </div>

          <Container className="my-4 px-4">
            <Row>
              {basicData.map((item, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <Card className="p-3 rounded-md text-center">
                    <div className="fw-bold">{item.name}</div>
                    <div
                      style={
                        item.name === "Status" ? getStatusStyle(item.value) : {}
                      }
                    >
                      {item.value}
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div> */}

        <div class="mb-4 card card-one py-4 rounded  border-2 cardStyle">
          <div className="card-header col-12 text-center d-flex align-items-center justify-content-center">
            <p className="fs-18 fw-bolder p-0 m-0 text-center ">
              Basic Information
            </p>
          </div>

          <div class="container text-center">
            <div class="row">
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={orgId}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Organization ID</h5>
                        <hr></hr>
                        <p class="card-text">{orgDetails?.organizationId}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={joining}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Joining Date</h5>
                        <hr></hr>
                        <p class="card-text">
                          {formatTimestamp(orgDetails?.createdAt)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={joining}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Valid Till</h5>
                        <hr></hr>
                        <p class="card-text">{superValid}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={superAdmin}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Super Admin Name</h5>
                        <hr></hr>
                        <p class="card-text">
                          {superDetails?.fname} {superDetails?.lname}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={superAphone}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Super Admin Phone</h5>
                        <hr></hr>
                        <p class="card-text">{superDetails?.phone}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="card mb-3">
                  <div class="row g-0">
                    <div class="col-md-4 d-flex justify-content-center align-items-center">
                      <img
                        src={SuperEmail}
                        width={70}
                        class="img-fluid rounded-start"
                        alt="Organization Icon"
                      />
                    </div>
                    <div class="col-md-8">
                      <div class="card-body">
                        <h5 class="card-title">Super Admin Email</h5>
                        <hr></hr>
                        <p class="card-text">{superDetails?.email}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <a href={`/org-detail/${id}/licenses`} class="text-decoration-none">
              <div class="card mb-3 shadow rounded-4">
                <div class="row g-0">
                  <div class="col-md-4 d-flex justify-content-center align-items-center">
                    <img
                      src={lincence}
                      width={70}
                      class="img-fluid rounded-start"
                      alt="Organization Icon"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title fw-bolder ">Licenses</h5>
                      <hr></hr>
                      <p class="card-text">{orgLicence?.total}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <div class="card mb-3 shadow rounded-4">
              <div class="row g-0">
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={endpoint}
                    width={70}
                    class="img-fluid rounded-start"
                    alt="Organization Icon"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title fw-bolder">Endpoints</h5>
                    <hr></hr>
                    <p class="card-text">{endpoints}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <div class="card mb-3 shadow rounded-4">
              <div class="row g-0">
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={subscription}
                    width={70}
                    class="img-fluid rounded-start"
                    alt="Organization Icon"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title fw-bolder ">Subscription</h5>
                    <hr></hr>
                    <p class="card-text">This is a wider</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <div class="card mb-3 shadow rounded-4">
              <div class="row g-0">
                {/* <div class="col-md-4">
                <img src={orgId} width={80} class="img-fluid rounded-start" alt="..." />
              </div> */}
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={payment}
                    width={70}
                    class="img-fluid rounded-start"
                    alt="Organization Icon"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title fw-bolder">Payment</h5>
                    <hr></hr>
                    <p class="card-text">This is a wider</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col">
            <a href={`/org-detail/${id}/users`} class="text-decoration-none">
              <div class="card mb-3 shadow rounded-4">
                <div class="row g-0">
                  <div class="col-md-4 d-flex justify-content-center align-items-center">
                    <img
                      src={multUsers}
                      width={70}
                      class="img-fluid rounded-start"
                      alt="Organization Icon"
                    />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title fw-bolder">Users</h5>
                      <hr></hr>
                      <p class="card-text">{users?.length || null}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col">
            <div class="card mb-3 shadow rounded-4">
              <div class="row g-0">
                {/* <div class="col-md-4">
                <img src={orgId} width={80} class="img-fluid rounded-start" alt="..." />
              </div> */}
                <div class="col-md-4 d-flex justify-content-center align-items-center">
                  <img
                    src={controls}
                    width={70}
                    class="img-fluid rounded-start"
                    alt="Organization Icon"
                  />
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h5 class="card-title">
                      <Button
                        className="btn btn-secondary text-light"
                        style={{
                          backgroundColor: "#03A9FC",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      >
                        Deactivate
                      </Button>
                    </h5>

                    <p class="card-text">
                      <Button
                        className="btn btn-secondary text-light"
                        style={{
                          backgroundColor: "#F32121",
                          borderRadius: "10px",
                          border: "none",
                        }}
                      >
                        Delete
                      </Button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <div className=" mb-4 card card-one py-4 rounded border-2 cardStyle">
          <div className="card-header col-12 text-center d-flex align-items-center justify-content-between">
            <div className="col-7  d-flex align-items-center justify-content-between ms-auto">
              <p className="fs-18 fw-bolder p-0 m-0 text-end ">
                License Details
              </p>
              <Button
                className="btn btn-dark text-light "
                onClick={() => navigate(`/org-detail/${id}/licenses`)}
              >
                <i class="fa-solid fa-arrow-right"></i>
              </Button>
            </div>
          </div>

          <Container className="my-4 px-4">
            <Row>
              {licData.map((item, index) => (
                <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-3">
                  <Card className="p-3 rounded-md text-center">
                    <div className="fw-bold">{item.name}</div>
                    <div>{item.value}</div>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </div> */}

        {/* <div className=" mb-4 card card-one py-4  d-flex flex-row px-4  rounded border-2 cardStyle">
          <div className="d-flex align-items-start justify-content-center col-12 mx-auto gap-3">
            <div className="col-5">
              <p className="text-center fw-bold fs-18 card-header d-flex align-items-center justify-content-center">
                Last Payment Details
              </p>
              <Container className="my-4 px-4">
                <Row>
                  {paymentInfo.map((item, index) => (
                    <Col
                      key={index}
                      xs={12}
                      sm={6}
                      md={6}
                      lg={6}
                      className="mb-3"
                    >
                      <Card className="p-3 rounded-md text-center">
                        <div className="fw-bold">{item.name}</div>
                        <div>{item.value}</div>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </div>

            <div className="col-7">
              <p className="text-center fw-bold fs-18 card-header d-flex justify-content-center align-items-center">
                Payment History
              </p>
              <div className="card-body mx-auto">
                <DataTable
                  columns={columns}
                  data={paymentHistory}
                  pagination
                  responsive
                />
              </div>
            </div>
          </div>
        </div> */}

        {/* <div className=" mb-4 card card-one py-4 rounded border-2 cardStyle">
        <div className="card-header col-12 ">
            <p className="fs-18 fw-bolder p-0 m-0 mx-auto ">Controls</p>
          </div> 

          <div className="card-body col-10 mx-auto px-5 my-2 col-10 mx-auto d-flex align-items-center justify-content-between">
             <Button
              className="btn btn-secondary text-light"
              style={{
                backgroundColor: "#03A9FC",
                borderRadius: "10px",
                border: "none",
              }}
            >
              Deactivate
            </Button>

           <Button
              className="btn btn-primary text-light "
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
              style={{ backgroundColor: "#035782", borderRadius: "10px" }}
            >
              Add Sub-Admin
            </Button> 

           <Button
              className="btn text-light"
              style={{
                backgroundColor: "#F37020",
                border: "None",
                borderRadius: "10px",
              }}
            >
              Reset Password
            </Button>

             <Button
              className="btn btn-secondary text-light "
              style={{ backgroundColor: " #737373", borderRadius: "10px" }}
            >
              Deactivate
            </Button> 

            <Button className="btn btn-dark text-light ">
              <i class="fa-regular fa-pen-to-square"></i>
            </Button>

            <Button className="btn btn-danger text-light ">Delete</Button>
          </div>
        </div> */}
        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content  p-1">
              <div class="modal-header">
                <h1
                  class="modal-title fs-5 mx-auto SubTitleCustomStyle"
                  id="exampleModalLabel"
                >
                  Add Sub-Admin
                </h1>
                {/* <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button> */}
              </div>
              <div class="modal-body">
                <form>
                  <div class="mb-3">
                    <label
                      for="exampleInputEmail1"
                      class="form-label  "
                      style={{ color: "black" }}
                    >
                      Sub-admin name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Enter Sub-admin  name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      required
                    />
                  </div>
                  <div class="mb-3">
                    <label
                      for="exampleInputEmail1"
                      class="form-label  "
                      style={{ color: "black" }}
                    >
                      Sub-admin email
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      placeholder="Enter super admin name"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                    />
                  </div>

                  <div class="mb-3">
                    <button
                      class="form-control"
                      style={{ backgroundColor: "#03A9FC" }}
                    >
                      {" "}
                      Invite{" "}
                    </button>
                  </div>

                  <div class="mb-3">
                    <button
                      class="form-control"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      style={{ border: "none" }}
                    >
                      {" "}
                      Cancel{" "}
                    </button>
                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> */}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <LinuxClients data={linuxSummary} /> */}
      </div>
    </React.Fragment>
  );
}

export default OrgDetail;
