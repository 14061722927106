import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../../layouts/Header";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "antd";
import Offcanvas from "react-bootstrap/Offcanvas";
import Excellogo from "../../../assets/img/Excellogo.png";
import AsyncSelect from "react-select/async";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function WhitelistPorts() {
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const location = useLocation();

  const [skin, setSkin] = useState(currentSkin);
  const [columnData, setColumnData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSubsection, setSelectedSubsection] = useState(null);

  const [newUserName, setNewUserName] = useState("");
  const [newUserEmail, setNewUserEmail] = useState("");

  const { user } = useSelector((state) => state.authSlice);
  


  const portsSet = 
  [
    {
      id: "66b0c22dd58cbafa0f85cacd",
      portno: "20",
      service:"ftp-data",
      protocol:"tcp",
      describe:"File transfer",

      }
  ]

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NOTIFY_URL}/devices/list/by/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );

      const devices = response.data.devices;
      setColumnData(portsSet);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error.response);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [user]);
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_NOTIFY_URL}/all-department/${user?.data?.user?.organizationId}`
        );
        setDepartments(response.data.departments);
      } catch (error) {
        console.error("Error fetching organization data: ", error);
      }
    };

    fetchOrganizationData();
  }, [user?.data?.user?.organizationId]);

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };


  const columns = [
    {
      name: "Port No",
      selector: (row) => row.portno,
      sortable: true,
    },
    {
      name: "Service",
      selector: (row) => row.service,
      sortable: true,
    },
    {
      name: "Protool",
      selector: (row) => row.protocol || "NA",
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.describe || "NA",
      sortable: true,
    },
 
    {
      name: "Action",
      selector: (row) => row.pcid || "NA",
      // cell: (row) => (
      //   <Button onClick={() => handleActionClick(row)} variant="primary">
          
      //   </Button>
      // ),
      sortable: false,
    },
  ];

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold",
        fontSize: "14px",
        backgroundColor: "#cccccc",
      },
    },
  };

  const handleSelectedRowsChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };

  const handleActionClick = (row) => {
    console.log("Action clicked for row:", row);
  };

  const handleControlClick = () => {
    setShowOffcanvas(true);
  };

  const loadDepartments = async (inputValue) => {
    return departments.map((dept) => ({
      label: dept.departmentName,
      value: dept._id,
    }));
  };

  const loadSections = async (inputValue) => {
    if (!selectedDepartment) return [];
    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    return department.sections.map((section) => ({
      label: section.sectionName,
      value: section._id,
    }));
  };

  const loadSubsections = async (inputValue) => {
    if (!selectedSection) return [];
    const department = departments.find(
      (dept) => dept._id === selectedDepartment.value
    );
    const section = department.sections.find(
      (sec) => sec._id === selectedSection.value
    );
    return section.subSections.map((subSection) => ({
      label: subSection.subSectionName,
      value: subSection._id,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedDepartment || !selectedSection || !selectedSubsection) {
      alert("Please select a department, section, and subsection.");
      return;
    }

    const deviceIds = selectedRows.map((row) => row.pcId);
    const data = {
      deviceIds,
      departmentId: selectedDepartment.value,
      sectionId: selectedSection.value,
      subSectionId: selectedSubsection.value,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_NOTIFY_URL}/link/pc/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data);
      // Handle success response

      // Close Offcanvas and update DataTable data
      setShowOffcanvas(false);
      fetchData();
      setSelectedRows([]);
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle error response
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    if (!newUserName || !newUserEmail) {
      alert("Please enter a name and email.");
      return;
    }

    const data = {
        fname: newUserName,
        email: newUserEmail,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/add-sub-admin/${user?.data?.user?.organizationId}/${user?.data?.user?.userId}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${user?.data?.accessToken}`,
          },
        }
      );
      console.log("API response:", response.data.message);
      // Handle success response
      toast.success(response.data.message, {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: false,
        theme: "light",
      });
      // Reset form fields
      setNewUserName("");
      setNewUserEmail("");
      // Close the modal
      document.getElementById("closeModal").click();
    } catch (error) {
      toast.error(error);
      console.error("Error adding user:", error);
      // Handle error response
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div
        className="main main-app p-3 p-lg-4"
        style={{ backgroundColor: "#ECF9FF" }}
      >
        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <span className="d-flex align-items-start justify-content-between fs-sm-normal mb-1 ps-2 d-flex flex-column">
            <p className="fs-18 fw-bolder p-0 m-0">Whitelist Portsdsada</p>
          </span>
          {/* <div className="d-flex align-items-center justify-content-between gap-2">
            <button
              type="button"
              className="btn btn-danger text-white"
              data-bs-toggle="modal"
              data-bs-target="#exampleModal"
            >
              <i className="fa-solid fa-file-pdf"></i> &nbsp; Export
            </button>
            <button type="button" className="btn btn-success text-white">
              <img src={Excellogo} height={20} width={15} />
              &nbsp; Export
            </button>
          </div> */}
        </div>

        <div className="d-flex align-items-center justify-content-between mb-4 card card-one p-4 flex-row rounded cardStyle">
          <div class="col-xl">
            <p class="fs-20 text-dark fw-bolder p-0 m-0">Ports List</p>
            <p class="text-danger p-0 m-0 fw-semibold">Totoal Ports:0 </p>
          </div>
        

          <button
            type="button"
            className="btn btn-info text-white"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"     
          >
            <i class="fa-solid fa-user-plus"></i> Devices
          </button>
        </div>
        <div className="card rounded cardStyle">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <DataTable
              columns={columns}
              data={columnData}
              pagination
              customStyles={customStyles}
              selectableRows
              onSelectedRowsChange={handleSelectedRowsChange}
              selectableRowsHighlight
              highlightOnHover
              paginationPerPage={10} // Number of rows per page
              paginationRowsPerPageOptions={[10, 25, 50]} // Pagination options
            />
          )}
        </div>

        {/* Offcanvas for displaying selected rows */}
        <Offcanvas
          show={showOffcanvas}
          onHide={() => setShowOffcanvas(false)}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>
              Selected Devices:{" "}
              <span className="badge rounded-pill text-bg-secondary text-white">
                {selectedRows.length}
              </span>
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <ul>
              {selectedRows.map((row) => (
                <>
                  <li key={row.id}>{row.hostname}</li>
                  <li key={row.pcId}>{row.pcId}</li>
                </>
              ))}
            </ul>

            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="departments" className="form-label">
                  Departments
                </label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadDepartments}
                  defaultOptions
                  onChange={(selectedOption) => {
                    setSelectedDepartment(selectedOption);
                    setSelectedSection(null);
                    setSelectedSubsection(null);
                  }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="sections" className="form-label">
                  Sections
                </label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadSections}
                  defaultOptions
                  isDisabled={!selectedDepartment}
                  onChange={(selectedOption) => {
                    setSelectedSection(selectedOption);
                    setSelectedSubsection(null);
                  }}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="subsections" className="form-label">
                  Subsections
                </label>
                <AsyncSelect
                  cacheOptions
                  loadOptions={loadSubsections}
                  defaultOptions
                  isDisabled={!selectedSection}
                  onChange={(selectedOption) =>
                    setSelectedSubsection(selectedOption)
                  }
                />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </Offcanvas.Body>
        </Offcanvas>

        <div
          class="modal fade"
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">
                  Add User
                </h1>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <form onSubmit={handleAddUser}>
                  <div class="mb-3">
                    <label for="exampleInputName" class="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      id="exampleInputName"
                      aria-describedby="nameHelp"
                      value={newUserName}
                      onChange={(e) => setNewUserName(e.target.value)}
                    />
                  </div>
                  <div class="mb-3">
                    <label for="exampleInputEmail1" class="form-label">
                      Email address
                    </label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      value={newUserEmail}
                      onChange={(e) => setNewUserEmail(e.target.value)}
                    />
                    <div id="emailHelp" class="form-text">
                      We'll never share your email with anyone else.
                    </div>
                  </div>

                  <button type="submit" class="btn btn-primary">
                    Submit
                  </button>
                </form>
              </div>
              {/* <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                  id = "closeModal"
                >
                  Close
                </button>
                <button type="button" class="btn btn-primary">
                  Save changes
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default WhitelistPorts;
