// import React, { useEffect, useRef, useState } from "react";
// import { Link, NavLink, useNavigate } from "react-router-dom";
// import PerfectScrollbar from "react-perfect-scrollbar";
// import userAvatar from "../assets/img/img1.jpg";
// import WhiteLogo from "../assets/img/whiteCyberLogo.png";
// import DefaultLogo from "../assets/img/onlyLogo.png";
// import "../assets/css/SideBar.css";
// import { adminMenu, dashboardMenu,UserAdminMenu } from "../data/Menu";
// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "../feature/slices/AuthSlice";

// const Sidebar = () => {
//   const [isFooterMenuShow, setIsFooterMenuShow] = useState(false);
//   const scrollBarRef = useRef(null);
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const { user, isAuthenticated } = useSelector((state) => state.authSlice);

//   const handleLogOut = async () => {
//     console.log("HANDLELOGOUT");
//     await dispatch(logout());
//     navigate("/");
//   };

//   const toggleFooterMenu = (e) => {
//     e.preventDefault();
//     setIsFooterMenuShow(!isFooterMenuShow);
//   };
//   const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
//   const [skin, setSkin] = useState(currentSkin);
//   const [imageSrc, setImageSrc] = useState(DefaultLogo);

//   const switchSkin = (skin) => {
//     if (skin === "dark") {
//       const btnWhite = document.getElementsByClassName("btn-white");
//       for (const btn of btnWhite) {
//         btn.classList.add("btn-outline-primary");
//         btn.classList.remove("btn-white");
//       }
//     } else {
//       const btnOutlinePrimary = document.getElementsByClassName(
//         "btn-outline-primary"
//       );
//       for (const btn of btnOutlinePrimary) {
//         btn.classList.remove("btn-outline-primary");
//         btn.classList.add("btn-white");
//       }
//     }
//   };

//   switchSkin(skin);
//   useEffect(() => {
//     switchSkin(skin);
//   }, [skin]);

//   return (
//     <div className={`sidebar ${isFooterMenuShow ? "footer-menu-show" : ""}`}>
//       <div className="sidebar-header">
//         <Link to="/" className="sidebar-logo d-flex">
//           <img
//             src={imageSrc}
//             id="logo"
//             alt="Cyber Auditor"
//             className="cyBerLogo"
//           />
//         </Link>
//         <p className="m-0 p-0 fw-bolder fs-5 fs15em" style={{cursor:"pointer"}} onClick={()=>navigate("/")}>CYBER AUDITOR</p>
//       </div>
//       <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
//         <SidebarMenu onUpdateSize={() => scrollBarRef.current.updateScroll()} />
//       </PerfectScrollbar>
//       <div className="sidebar-footer">
//         <div className="sidebar-footer-top">
//           <div className="sidebar-footer-thumb">
//             <img src={userAvatar} alt="" />
//           </div>
//           <div className="sidebar-footer-body">
//             <h5 className="mb-1 text-dark fw-semibold">
//               {user?.data?.user?.firstName || "Dummy"}
//             </h5>
//             <p className="fs-sm text-secondary">
//               {user?.data?.user?.role || "NA"}
//             </p>
//           </div>
//           <Link onClick={toggleFooterMenu} to="" className="dropdown-link">
//             <i className="ri-arrow-down-s-line"></i>
//           </Link>
//         </div>
//         {/* <div className="sidebar-footer-menu">
//           <nav className="nav">
//             <Link to="">
//               <i className="ri-edit-2-line"></i> Edit Profile
//             </Link>
//             <Link to="">
//               <i className="ri-profile-line"></i> View Profile
//             </Link>
//           </nav>
//           <hr />
//           <nav className="nav">
//             <Link to="">
//               <i className="ri-question-line"></i> Help Center
//             </Link>
//             <Link to="">
//               <i className="ri-lock-line"></i> Privacy Settings
//             </Link>
//             <Link to="">
//               <i className="ri-user-settings-line"></i> Account Settings
//             </Link>
//             <Link onClick={handleLogOut}>
//               <i className="ri-logout-box-r-line"></i> Log Out
//             </Link>
//           </nav>
//         </div> */}
//       </div>
//     </div>
//   );
// };

// const SidebarMenu = ({ onUpdateSize }) => {
//   const populateMenu = (m) => {
//     const menu = m.map((m, key) => {
//       let sm;
//       if (m.submenu) {
//         sm = m.submenu.map((sm, key) => {
//           return (
//             <NavLink to={sm.link} className="nav-sub-link" key={key}>
//               {sm.label}
//             </NavLink>
//           );
//         });
//       }

//       return (
//         <li key={key} className="nav-item">
//           {!sm ? (
//             <NavLink to={m.link} className="nav-link">
//               <i className={m.icon}></i> <span>{m.label}</span>
//             </NavLink>
//           ) : (
//             <div onClick={toggleSubMenu} className="nav-link has-sub">
//               <i className={m.icon}></i> <span>{m.label}</span>
//             </div>
//           )}
//           {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
//         </li>
//       );
//     });

//     return <ul className="nav nav-sidebar">{menu}</ul>;
//   };

//   // Toggle menu group
//   const toggleMenu = (e) => {
//     e.preventDefault();

//     let parent = e.target.closest(".nav-group");
//     parent.classList.toggle("show");

//     onUpdateSize();
//   };

//   // Toggle submenu while closing siblings' submenu
//   const toggleSubMenu = (e) => {
//     e.preventDefault();

//     let parent = e.target.closest(".nav-item");
//     let node = parent.parentNode.firstChild;

//     while (node) {
//       if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
//         node.classList.remove("show");
//       node = node.nextElementSibling || node.nextSibling;
//     }

//     parent.classList.toggle("show");

//     onUpdateSize();
//   };

//   return (
//     <>
//       <div className="nav-group show">
//         <div className="nav-label" onClick={toggleMenu}>
//           Dashboard
//         </div>
//         {populateMenu(dashboardMenu)}
//         {populateMenu(adminMenu)}
//         {populateMenu(UserAdminMenu)}
//       </div>
//     </>
//   );
// };

// export default Sidebar;
// usertype set here 
import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import userAvatar from "../assets/img/img1.jpg";
import WhiteLogo from "../assets/img/whiteCyberLogo.png";
import DefaultLogo from "../assets/img/onlyLogo.png";
import "../assets/css/SideBar.css";
import { adminMenu, dashboardMenu, UserAdminMenu,OrgAdmin } from "../data/Menu";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../feature/slices/AuthSlice";

const Sidebar = () => {
  const [isFooterMenuShow, setIsFooterMenuShow] = useState(false);
  const scrollBarRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated } = useSelector((state) => state.authSlice);

  const handleLogOut = async () => {
    console.log("HANDLELOGOUT");
    await dispatch(logout());
    navigate("/");
  };

  const toggleFooterMenu = (e) => {
    e.preventDefault();
    setIsFooterMenuShow(!isFooterMenuShow);
  };
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const [imageSrc, setImageSrc] = useState(DefaultLogo);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <div className={`sidebar ${isFooterMenuShow ? "footer-menu-show" : ""}`}>
      <div className="sidebar-header">
        <Link to="/" className="sidebar-logo d-flex">
          <img
            src={imageSrc}
            id="logo"
            alt="Cyber Auditor"
            className="cyBerLogo"
          />
        </Link>
        <p className="m-0 p-0 fw-bolder fs-5 fs15em" style={{cursor:"pointer"}} onClick={()=>navigate("/")}>CYBER AUDITOR</p>
      </div>
      <PerfectScrollbar className="sidebar-body" ref={scrollBarRef}>
        <SidebarMenu 
          onUpdateSize={() => scrollBarRef.current.updateScroll()} 
          userType={user?.data?.user?.roleName} 
        />
      </PerfectScrollbar>
      <div className="sidebar-footer">
        <div className="sidebar-footer-top">
          <div className="sidebar-footer-thumb">
            <img src={userAvatar} alt="" />
          </div>
          <div className="sidebar-footer-body">
            <h5 className="mb-1 text-dark fw-semibold">
              {user?.data?.user?.firstName || "Dummy"}
            </h5>
            <p className="fs-sm text-secondary">
              {user?.data?.user?.role || "NA"}
            </p>
          </div>
          <Link onClick={toggleFooterMenu} to="" className="dropdown-link">
            <i className="ri-arrow-down-s-line"></i>
          </Link>
        </div>
        {/* <div className="sidebar-footer-menu">
          <nav className="nav">
            <Link to="">
              <i className="ri-edit-2-line"></i> Edit Profile
            </Link>
            <Link to="">
              <i className="ri-profile-line"></i> View Profile
            </Link>
          </nav>
          <hr />
          <nav className="nav">
            <Link to="">
              <i className="ri-question-line"></i> Help Center
            </Link>
            <Link to="">
              <i className="ri-lock-line"></i> Privacy Settings
            </Link>
            <Link to="">
              <i className="ri-user-settings-line"></i> Account Settings
            </Link>
            <Link onClick={handleLogOut}>
              <i className="ri-logout-box-r-line"></i> Log Out
            </Link>
          </nav>
        </div> */}
      </div>
    </div>
  );
};

const SidebarMenu = ({ onUpdateSize, userType }) => {
  const populateMenu = (menuData) => {
    const menu = menuData.map((m, key) => {
      let sm;
      if (m.submenu) {
        sm = m.submenu.map((sm, key) => {
          return (
            <NavLink to={sm.link} className="nav-sub-link" key={key}>
              {sm.label}
            </NavLink>
          );
        });
      }

      return (
        <li key={key} className="nav-item">
          {!sm ? (
            <NavLink to={m.link} className="nav-link">
              <i className={m.icon}></i> <span>{m.label}</span>
            </NavLink>
          ) : (
            <div onClick={toggleSubMenu} className="nav-link has-sub">
              <i className={m.icon}></i> <span>{m.label}</span>
            </div>
          )}
          {m.submenu && <nav className="nav nav-sub">{sm}</nav>}
        </li>
      );
    });

    return <ul className="nav nav-sidebar">{menu}</ul>;
  };

  // Toggle menu group
  const toggleMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-group");
    parent.classList.toggle("show");

    onUpdateSize();
  };

  // Toggle submenu while closing siblings' submenu
  const toggleSubMenu = (e) => {
    e.preventDefault();

    let parent = e.target.closest(".nav-item");
    let node = parent.parentNode.firstChild;

    while (node) {
      if (node !== parent && node.nodeType === Node.ELEMENT_NODE)
        node.classList.remove("show");
      node = node.nextElementSibling || node.nextSibling;
    }

    parent.classList.toggle("show");

    onUpdateSize();
  };

  return (
    <>
      <div className="nav-group show">
        <div className="nav-label" onClick={toggleMenu}>
          Dashboard
        </div>
        {userType === "Super Admin" && populateMenu(dashboardMenu)}
        {userType === "subSection Admin" && populateMenu(dashboardMenu)}
        {userType === "Section Admin" && populateMenu(dashboardMenu)}
        {userType === "Ca Admin" && populateMenu(adminMenu)}
        {userType === "Super Admin" && populateMenu(UserAdminMenu)}
        {userType === "Organization Admin" && populateMenu(OrgAdmin)}

      </div>
    </>
  );
};

export default Sidebar;
