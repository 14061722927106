
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { showToaster } from "../feature/slices/toastSlice";

function WatchLogNotification() {
  const [totalNotificationCount, setTotalNotificationCount] = useState(-1);
  const { user } = useSelector((state) => state.authSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    const fetchData = () => {
      axios
        .get(
          `${process.env.REACT_APP_NOTIFY_URL}/get-all-alert/by/${user?.data?.user?.userId}`,
          {
            headers: {
              Authorization: `Bearer ${user?.data?.accessToken}`,
            },
          }
        )
        .then((response) => {
          if (mounted) {
            console.log(
              "WatchDog Notification count changed from",
              totalNotificationCount,
              "to",
              response?.data?.notifications?.length
            );

            if (
              totalNotificationCount !== -1 &&
              totalNotificationCount !== response?.data?.notifications?.length
            ) {
              let title = "WatchDog Notification";
              let message = "New watch log notification received.";
              let color = "success";

              console.log("Dispatching toaster:", title, message, color);
              dispatch(
                showToaster({
                  title: title || "NA",
                  message: message || "NA",
                  color: color || "success",
                })
              );
            }
            setTotalNotificationCount(response?.data?.notifications?.length);
          }
        })
        .catch((error) => {
          if (error.response) {
            const statusCode = error.response.status;
            switch (statusCode) {
              case 404:
                console.log("Resource not found (404)");
                break;
              case 500:
                console.log("Internal server error (500)");
                break;
              case 504:
                console.log("Gateway timeout (504)");
                break;
              default:
                console.log(`Error: ${statusCode}`);
            }
          } else if (error.request) {
            console.log("No response received");
          } else {
            console.log("Error", error.message);
          }
        })
        .finally(() => {
          if (mounted) {
            setTimeout(fetchData, 12000); // Retry polling after 12 seconds
          }
        });
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [user?.data?.accessToken, user?.data?.user?.userId, totalNotificationCount, dispatch]);

  return null; // No UI rendering
}

export default WatchLogNotification;
