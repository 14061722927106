import React, { Children, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

function HistoryCard({
  headerName,
  reloadFunction = () => {},
  infoButton,
  paddingBottom = false,
  children,
}) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);

  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  return (
    <div
      className={`card-one  my-2 rounded-md py-3 ${paddingBottom && "pb-0"}`}
    >
      <div className="d-flex border-bottom border-1 px-3 py-2 align-items-center justify-content-between">
        <span className="m-0 p-0 fs-16 text-primary-dark fw-bolder">
          {headerName}
        </span>
        <span className="d-flex align-items-center justify-content-center gap-3">
          {/* <Button className="p-0 m-0 text-secondary bg-transparent border-0">
        <i className="fas fa-sync"></i>
        </Button> */}
          {infoButton && (
            <Button className="p-0 m-0 bg-transparent text-secondary border-0">
              <i className="fas fa-info-circle"></i>
            </Button>
          )}
        </span>
      </div>
      <div className="px-3">{children}</div>
    </div>
  );
}

export default HistoryCard;
